import Box from '@mui/material/Box';
import { ReactNode } from 'react';

export type UnauthorizedLayoutProps = {
  children: ReactNode;
};

function UnauthorizedLayout({ children }: UnauthorizedLayoutProps) {
  return (
    <Box height="100vh" display="flex" justifyContent="space-between" flexDirection="column">
      {children}
    </Box>
  );
}

export default UnauthorizedLayout;
