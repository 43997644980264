import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }: { email: string; password: string }) => ({
        url: 'authn/login-v2',
        method: 'POST',
        body: {
          email,
          password,
        },
      }),
    }),
    registerEmail: builder.mutation({
      query: ({ email, locale }: { email: string; locale: string }) => ({
        url: 'authn/register-email',
        method: 'POST',
        body: {
          email,
          language: locale,
        },
      }),
    }),
    googleLogin: builder.mutation({
      query: ({ idToken, language }: { idToken: string; language: string }) => ({
        url: 'authn/google',
        method: 'POST',
        body: {
          idToken,
          language,
        },
      }),
    }),
    appleLogin: builder.mutation({
      query: ({ idToken, language }: { idToken: string; language: string }) => ({
        url: 'authn/apple',
        method: 'POST',
        body: {
          idToken,
          language,
        },
      }),
    }),
    completeRegistration: builder.mutation({
      query: ({ id, verificationCode }: { id: string; verificationCode: string }) => ({
        url: `authn/register-session/${id}/complete`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${verificationCode}`,
        },
      }),
    }),
    updateRegistration: builder.mutation({
      query: ({
        id,
        ...updateData
      }: {
        id: string;
        verificationCode: string;
        firstName?: string;
        lastName?: string;
        password?: string;
        marketingOptIn?: boolean;
        companyName?: string;
        companySubdomain?: string;
        initialInvitations?: string[];
        companyLogoId?: string;
        avatarId?: string;
      }) => ({
        url: `authn/register-session/${id}`,
        method: 'PATCH',
        body: updateData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('verificationCode') as string}`,
        },
      }),
    }),
    verifyCode: builder.mutation({
      query: ({ id, verificationCode }: { id: string; verificationCode: string }) => ({
        url: `authn/register-email/${id}/verify-code`,
        method: 'POST',
        body: {
          verificationCode,
        },
      }),
    }),
    getRegistrationSessionData: builder.query({
      query: ({ id }: { id: string }) => ({
        url: `authn/register-session/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('verificationCode') as string}`,
        },
      }),
    }),
    resendCode: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: `authn/register-email/${id}/resend-code`,
        method: 'POST',
      }),
    }),
    refreshToken: builder.mutation({
      query: (refreshToken: string) => ({
        url: 'authn/token/company/refresh',
        method: 'POST',
        body: {
          refreshToken,
        },
      }),
    }),
    exchangeToken: builder.mutation({
      query: (exchangeToken: string) => ({
        url: 'authn/login/company/exchange',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${exchangeToken}`,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useExchangeTokenMutation,
  useRegisterEmailMutation,
  useUpdateRegistrationMutation,
  useVerifyCodeMutation,
  useResendCodeMutation,
  useGetRegistrationSessionDataQuery,
  useLazyGetRegistrationSessionDataQuery,
  useGoogleLoginMutation,
  useAppleLoginMutation,
  useCompleteRegistrationMutation,
} = authApi;
