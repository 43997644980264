import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Lesson from 'src/features/lesson/view/Lesson';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { ShareLevel } from 'src/lib/types/share-level';
import ModuleOverview from 'src/features/module-overview/view';
import SplitPaneLayout from 'src/layouts/SplitPaneLayout';
import { Box, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export default function LessonView() {
  const param = useParams();
  const theme = useTheme();
  const { data: shareLevelData, isSuccess } = useShareLevelQuery({
    resourceType: 'training',
    resourceId: param.trainingId as string,
  });
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [moduleOverviewVisible, setModuleOverviewVisible] = useState(true);

  useResizeObserver(isSuccess ? containerRef : null, (entry) => {
    setModuleOverviewVisible(entry.contentRect.width > theme.breakpoints.values.lg);
  });

  const canEdit =
    shareLevelData?.shareLevel === ShareLevel.edit ||
    shareLevelData?.shareLevel === ShareLevel.manage;

  const canView = shareLevelData?.shareLevel !== ShareLevel.none;

  if (!isSuccess) return null;

  if (!canView) return <Navigate to="/" replace />;

  return (
    <Box height="100%" ref={containerRef}>
      {moduleOverviewVisible ? (
        <SplitPaneLayout
          leftContentWidth={{
            xl: 400,
            lg: 350,
          }}
          leftContent={
            <ModuleOverview
              editMode={canEdit}
              onAddLessonCallback={(id) =>
                navigate(
                  `/trainings/${param.trainingId}/${param.moduleId}/${param.sectionId}/${id}/edit`
                )
              }
              hideBreadcrumbs
            />
          }
          rightContent={<Lesson hasEditAccess={canEdit} />}
        />
      ) : (
        <Lesson hasEditAccess={canEdit} />
      )}{' '}
    </Box>
  );
}
