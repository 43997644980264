import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { sidebarApi } from 'src/store/api/sidebarApi';
import useIsCustomerPortal from './useIsCustomerPortal';

export default function useUpdateAppSidebarItemTitle(id: string) {
  const dispatch = useDispatch<any>();
  const isCustomerPortal = useIsCustomerPortal();
  const updateAppSidebarItemTitle = useCallback(
    (val: string) => {
      dispatch(
        sidebarApi.util.updateQueryData('sidebar', isCustomerPortal, (draft) => ({
          ...draft,
          sidebarItems: draft.sidebarItems.map((item) =>
            id === item.id ? { ...item, name: val } : item
          ),
        }))
      );
    },
    [id, dispatch, isCustomerPortal]
  );
  return updateAppSidebarItemTitle;
}
