import { EmbedCardStyle } from '@blocksuite/blocks';
import { defineBlockSchema, SchemaToModel } from '@blocksuite/store';

export type VideoBlockRequiredData = {
  required: boolean;
};

export type VideoBlockFastForwardData = {
  disabledFastForward: boolean;
};

export type VideoBlockUrlData = {
  videoId: string | null;
  posterSrc: string | null;
};

export const VideoBlockStyles = ['video'] as const satisfies EmbedCardStyle[];

export type VideoBlockProps = {
  style: any;
  videoName: string | null;
} & VideoBlockUrlData &
  VideoBlockRequiredData &
  VideoBlockFastForwardData;

export const defaultVideoBlockProps: VideoBlockProps = {
  style: 'video',
  videoId: null,
  posterSrc: null,
  videoName: null,
  disabledFastForward: false,
  required: false,
};

export const VideoBlockSchema = defineBlockSchema({
  flavour: 'affine:embed-workbase-video',
  metadata: {
    version: 1,
    role: 'content',
    children: [],
  },
  props: (): VideoBlockProps => defaultVideoBlockProps,
});

export type VideoBlockModel = SchemaToModel<typeof VideoBlockSchema>;

declare global {
  namespace BlockSuite {
    interface EdgelessBlockModelMap {
      'affine:embed-workbase-video': VideoBlockModel;
    }
    interface BlockModels {
      'affine:embed-workbase-video': VideoBlockModel;
    }
  }
}
