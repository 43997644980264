import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShareModal from 'src/core/share-modal/ShareModal';
import { useEditor } from 'src/features/editor2/context';
import { ShareLevel } from 'src/lib/types/share-level';

interface Props {
  pageId: string;
  shareLevel?: ShareLevel;
}

export default function ShareModalButton({ pageId, shareLevel }: Props) {
  const [name, setName] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { doc } = useEditor()!;
  const { t } = useTranslation();

  useEffect(() => {
    const title = doc?.getBlocksByFlavour('affine:page')[0];
    if (title) {
      const onObserveTitle = () => {
        setName(doc.meta?.title || '');
      };
      title?.yBlock.observeDeep(onObserveTitle);
      return () => title?.yBlock.unobserve(onObserveTitle);
    }
    return undefined;
  }, [doc]);

  return (
    <ShareModal
      dataCy="custom-page"
      name={name || t('customSites.unnamedPage')}
      resourceId={pageId}
      resourceType="page"
      shareLevel={shareLevel}
    />
  );
}
