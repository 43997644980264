import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import 'simplebar-react/dist/simplebar.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { DndProvider } from 'react-dnd';
import { PersistGate } from 'redux-persist/integration/react';
import { getBackendOptions, MultiBackend } from '@minoru/react-dnd-treeview';
import { store, persistor } from './store';
import i18n from './locales/i18n';
import CustomThemeProvider from './providers/CustomThemeProvider';
import { SENTRY_DSN, NODE_ENV, MODE } from './global-config';
import { router } from './routes/router';
import NotificationsSnackbarProvider from './ui-components/custom/notifications-snackbar/NotificationsSnackbarProvider';
import SiteInfoProvider from './providers/SiteInfoProvider';
import { DatePickerLocalizationProvider } from './locales';
import ConfirmProvider from './providers/ConfirmProvider';
import ManifestProvider from './providers/ManifestProvider';
import RegisterProvider from './providers/RegisterProvider';

if (NODE_ENV === 'production' || NODE_ENV === 'development') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracePropagationTargets: ['https://*.workbase.dev/', 'https://*.workbase.io/'],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
}

if (MODE !== 'production') {
  const meta = document.createElement('meta');
  meta.name = 'robots';
  meta.content = 'noindex,nofollow';
  document.head.appendChild(meta);
}
const root = document.getElementById('root') as HTMLDivElement;

createRoot(root).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <DatePickerLocalizationProvider>
          <DndProvider backend={MultiBackend} options={getBackendOptions()}>
            <CustomThemeProvider>
              <RegisterProvider>
                <SiteInfoProvider>
                  <ManifestProvider>
                    <ConfirmProvider>
                      <RouterProvider router={router} />
                      <NotificationsSnackbarProvider />
                    </ConfirmProvider>
                  </ManifestProvider>
                </SiteInfoProvider>
              </RegisterProvider>
            </CustomThemeProvider>
          </DndProvider>
        </DatePickerLocalizationProvider>
      </I18nextProvider>
    </PersistGate>
  </Provider>
);
