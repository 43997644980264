import type { Props as AuthLayoutProps } from '@framer/auth-layout';

// Extract variants from the type definition
export type OnboardingSteps = NonNullable<AuthLayoutProps['variant']>;
export const ONBOARDING_STEPS = [
  'Create Account',
  'Create Account - Email Verification',
  'Create Account - Profile Data',
  'Create Company - Name',
  'Create Company - Use-cases',
  'Create Company - Use-Case: Work',
  'Create Compoany - About yourself',
  'Create Company - Invite',
  'Login - Existing',
  'Logged in - No Company',
] as const satisfies readonly OnboardingSteps[];

type FormButtonVariantType = NonNullable<AuthLayoutProps['formButtonVariant']>;
export enum FormButtonVariant {
  DEFAULT = 'Default',
  LOADING = 'Loading',
  DISABLED = 'Disabled',
  SUCCESS = 'Success',
  ERROR = 'Error',
}
export const _typeCheck: Record<FormButtonVariant, FormButtonVariantType> = {
  [FormButtonVariant.DEFAULT]: FormButtonVariant.DEFAULT,
  [FormButtonVariant.LOADING]: FormButtonVariant.LOADING,
  [FormButtonVariant.DISABLED]: FormButtonVariant.DISABLED,
  [FormButtonVariant.SUCCESS]: FormButtonVariant.SUCCESS,
  [FormButtonVariant.ERROR]: FormButtonVariant.ERROR,
};

export type OverlayType = NonNullable<AuthLayoutProps['fullpageOverlay']>;
export enum Overlay {
  NONE = 'None',
  LOADING = 'Loading',
  DRAG_AND_DROP = 'Drag and Drop',
}

export const _overlayTypeCheck: Record<Overlay, OverlayType> = {
  [Overlay.NONE]: Overlay.NONE,
  [Overlay.LOADING]: Overlay.LOADING,
  [Overlay.DRAG_AND_DROP]: Overlay.DRAG_AND_DROP,
};

export interface OnboardingFormData {
  email?: string;
  name?: string;
  marketing?: 'on' | 'off';
  useCases?: string[];
  verificationCode?: string;
  inviteEmail1?: string;
  inviteEmail2?: string;
  inviteEmail3?: string;
  inviteEmail4?: string;
  inviteEmail5?: string;
  inviteEmail4Hidden?: boolean;
  inviteEmail5Hidden?: boolean;
  password?: string;
  step: OnboardingSteps;
  workDetails?: {
    company?: string;
    role?: string;
    teamSize?: number;
  };
  workspaceName?: string;
  workspaceDomain?: string;
  companyName?: string;
  teamEmails?: string[];
  profilePhoto?: {
    id?: string;
    url?: string;
  };
  companyLogo?: {
    id?: string;
    url?: string;
  };
}
