import { QuickSearchExtension } from '@blocksuite/blocks';
import { TextField } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { t } from 'i18next';
import { confirm } from 'material-ui-confirm';

export const WorkbaseQuickSearchExtension = QuickSearchExtension({
  openQuickSearch: async () => {
    try {
      let link = '';
      await confirm({
        title: t('links.addEditLinkModal.addTitle'),
        content: (
          <TextField
            label={t('links.addEditLinkModal.linkLabel')}
            sx={{ mt: 1 }}
            size="small"
            fullWidth
            onChange={(e) => (link = e.target.value)}
          />
        ),
        confirmationText: t('add'),
        confirmationButtonProps: {
          color: 'primary',
        },
      });
      return Promise.resolve({ externalUrl: link });
    } catch (_) {
      return Promise.resolve(null);
    }
  },
});
