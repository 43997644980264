import { ResultData, ResourceType } from 'src/store/api/searchApi';

export function getNavigationRoute(data: ResultData): string {
  const routeMap: Record<ResourceType, (item: ResultData) => string> = {
    page: (item) => `/page/${item.id}`,
    training: (item) => `/trainings/${item.id}`,
    trainingModule: (item) => `/trainings/${item.trainingId}/${item.id}`,
    trainingLesson: (item) =>
      `/trainings/${item.trainingId}/${item.trainingModuleId}/${item.trainingSectionId}/${item.id}`,
    companyUser: (item) => item.id,
    embeddedApp: (item) => `/embeddedApp/${item.id}`,
    wikiCard: (item) => `/wiki/${item.id}`,
    whiteboard: (item) => `/whiteboard/${item.id}`,
    assistantThread: (item) => `/ai/${item.id}`,
  };

  const getRoute = routeMap[data.type];
  return getRoute(data);
}

export function removeHtmlTags(str: string) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = str;
  return tempDiv.textContent || tempDiv.innerText || '';
}
