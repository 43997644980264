// @ts-nocheck
/* eslint-disable */
/* This file was generated by Unframer for Framer project 71fd72204da79e7e "Workbase App", do not edit manually */
import { Icon, } from './chunk-CYEWUSOC.js';

// /:https://framerusercontent.com/modules/ZG19wdfh2YqQOJhsRLiU/3ZpQn3KSeNlHNnJncoIz/qDfyn2AS0.js
import { jsx as _jsx, jsxs as _jsxs, } from 'react/jsx-runtime';
import {
  addFonts,
  addPropertyControls,
  ComponentViewportProvider,
  ControlType,
  cx,
  getFonts,
  getPropertyControls,
  RichText,
  useActiveVariantCallback,
  useComponentViewport,
  useLocaleInfo,
  useVariantState,
  withCSS,
} from 'unframer';
import { LayoutGroup, motion, MotionConfigContext, } from 'unframer';
import * as React from 'react';
var IconFonts = getFonts(Icon,);
var IconControls = getPropertyControls(Icon,);
var enabledGestures = { z6hwlkXWt: { hover: true, }, };
var serializationHash = 'framer-BpqaY';
var variantClassNames = { z6hwlkXWt: 'framer-v-1gvv1ev', };
function addPropertyOverrides(overrides, ...variants) {
  const nextOverrides = {};
  variants === null || variants === void 0
    ? void 0
    : variants.forEach((variant,) => variant && Object.assign(nextOverrides, overrides[variant],));
  return nextOverrides;
}
var transition1 = { bounce: 0.2, delay: 0, duration: 0.4, type: 'spring', };
var Transition = ({ value, children, },) => {
  const config = React.useContext(MotionConfigContext,);
  const transition = value !== null && value !== void 0 ? value : config.transition;
  const contextValue = React.useMemo(() => ({ ...config, transition, }), [JSON.stringify(transition,),],);
  return /* @__PURE__ */ _jsx(MotionConfigContext.Provider, { value: contextValue, children, },);
};
var Variants = motion.create(React.Fragment,);
var humanReadableEnumMap = {
  '! Error': '! Error',
  '@': '@',
  'Add Content': 'Add Content',
  'Add Employee': 'Add Employee',
  'Add Square': 'Add Square',
  'Adjust Settings': 'Adjust Settings',
  'Align - Center': 'Align - Center',
  'Align - Left': 'Align - Left',
  'Align - Right': 'Align - Right',
  'App Tour': 'App Tour',
  'Arrow Down Right': 'Arrow Down Right',
  'Arrow Left Right': 'Arrow Left Right',
  'Arrow Left': 'Arrow Left',
  'Arrow Right - Circle': 'Arrow Right - Circle',
  'Arrow Right Top': 'Arrow Right Top',
  'Arrow Right': 'Arrow Right',
  'Arrow Up Circle': 'Arrow Up Circle',
  'Bar & Line': 'Bar & Line',
  'Basic URL': 'Basic URL',
  'Bell Badge': 'Bell Badge',
  'Book Letter': 'Book Letter',
  'Book Placeholder': 'Book Placeholder',
  'Bookmark Filled': 'Bookmark Filled',
  'Bubble Left Right': 'Bubble Left Right',
  'Building Circle': 'Building Circle',
  'Business Plus': 'Business Plus',
  'Chart Pie Filled': 'Chart Pie Filled',
  'Chart Pie': 'Chart Pie',
  'Chart Up Dots': 'Chart Up Dots',
  'Chart Up': 'Chart Up',
  'Chat New': 'Chat New',
  'Checklist Full': 'Checklist Full',
  'Checkmark 2': 'Checkmark 2',
  'Checkmark Big - Filled': 'Checkmark Big - Filled',
  'Checkmark Big': 'Checkmark Big',
  'Chevron Down': 'Chevron Down',
  'Chevron Left': 'Chevron Left',
  'Chevron Right': 'Chevron Right',
  'Chevron Up': 'Chevron Up',
  'Clock Checkmark': 'Clock Checkmark',
  'Close Circle Filled': 'Close Circle Filled',
  'Close Circle': 'Close Circle',
  'Cloud Key': 'Cloud Key',
  'Color Picker': 'Color Picker',
  'Contact person': 'Contact person',
  'Content New': 'Content New',
  'Conversation Bubble': 'Conversation Bubble',
  'Copy Duplicate': 'Copy Duplicate',
  'Customer Portal': 'Customer Portal',
  'Data 2': 'Data 2',
  'Delete Square': 'Delete Square',
  'Drag and drop': 'Drag and drop',
  'Email Badge': 'Email Badge',
  'Emoji - Airplane': 'Emoji - Airplane',
  'Emoji - Bike': 'Emoji - Bike',
  'Emoji - Bulb': 'Emoji - Bulb',
  'Emoji - Dog': 'Emoji - Dog',
  'Emoji - Flag': 'Emoji - Flag',
  'Emoji - Food': 'Emoji - Food',
  'Emoji - Laughing': 'Emoji - Laughing',
  'Emoji - Symbols': 'Emoji - Symbols',
  'Employee Cards': 'Employee Cards',
  'Employee Circle': 'Employee Circle',
  'Employee Clock': 'Employee Clock',
  'Employee File': 'Employee File',
  'Empty Content Page': 'Empty Content Page',
  'Error - Filled': 'Error - Filled',
  'Exams Tests': 'Exams Tests',
  'Exit Fullscreen': 'Exit Fullscreen',
  'Eye - Nothing': 'Eye - Nothing',
  'Eye - View': 'Eye - View',
  'Factorial HR': 'Factorial HR',
  'Fold in': 'Fold in',
  'Help - Filled': 'Help - Filled',
  'Image - Drag and Upload': 'Image - Drag and Upload',
  'Image Frame': 'Image Frame',
  'Image Generator': 'Image Generator',
  'Image New': 'Image New',
  'Image Remove': 'Image Remove',
  'Info - Filled': 'Info - Filled',
  'Inline URL': 'Inline URL',
  'Laptop Phone': 'Laptop Phone',
  'Laurel Leading': 'Laurel Leading',
  'Laurel Trailing': 'Laurel Trailing',
  'Lightbulb Max': 'Lightbulb Max',
  'Linked Doc': 'Linked Doc',
  'List 2': 'List 2',
  'List Bullet Indent': 'List Bullet Indent',
  'Live Calls': 'Live Calls',
  'Live Chat': 'Live Chat',
  'Location 2': 'Location 2',
  'Lock - Filled': 'Lock - Filled',
  'Lock Circle': 'Lock Circle',
  'Microsoft Loop': 'Microsoft Loop',
  'Money Dollar': 'Money Dollar',
  'Mouse Cursor': 'Mouse Cursor',
  'Numbered List': 'Numbered List',
  'Paperplane Filled': 'Paperplane Filled',
  'Performance Chart': 'Performance Chart',
  'Person 2 - Filled': 'Person 2 - Filled',
  'Person Checkmark': 'Person Checkmark',
  'Person Key': 'Person Key',
  'Person Rectangle': 'Person Rectangle',
  'Play Circle': 'Play Circle',
  'Plus Circle Filled': 'Plus Circle Filled',
  'Plus Circle': 'Plus Circle',
  'Press Content': 'Press Content',
  'QuestionBubble - Filled': 'QuestionBubble - Filled',
  'Questionmark Circle': 'Questionmark Circle',
  'Quote Text': 'Quote Text',
  'Resend eMail': 'Resend eMail',
  'Search In': 'Search In',
  'Server Checkmark': 'Server Checkmark',
  'Shopping cart': 'Shopping cart',
  'Sidebar Left': 'Sidebar Left',
  'Sidebar Right': 'Sidebar Right',
  'Sound Muted': 'Sound Muted',
  'Sound Vol1': 'Sound Vol1',
  'Sound Vol2': 'Sound Vol2',
  'Sound Vol3': 'Sound Vol3',
  'Square Cmd': 'Square Cmd',
  'Square Ctrl': 'Square Ctrl',
  'Square F': 'Square F',
  'Square Shift Copy': 'Square Shift Copy',
  'Square Shift': 'Square Shift',
  'Square Slash': 'Square Slash',
  'Square Stack': 'Square Stack',
  'Star Bubble': 'Star Bubble',
  'Star Filled': 'Star Filled',
  'Star Square': 'Star Square',
  'Sub Tasks': 'Sub Tasks',
  'Switch 2': 'Switch 2',
  'Technical question': 'Technical question',
  'Telefon Wave': 'Telefon Wave',
  'Text - Center': 'Text - Center',
  'Text - Left': 'Text - Left',
  'Text - Right': 'Text - Right',
  'Text Line Magnify': 'Text Line Magnify',
  'Three Employees': 'Three Employees',
  'Three Points': 'Three Points',
  'Two Employees Circle': 'Two Employees Circle',
  'Two Employees': 'Two Employees',
  'Video Cut': 'Video Cut',
  'Video Explore': 'Video Explore',
  'Video Upload': 'Video Upload',
  'Warning - Filled': 'Warning - Filled',
  'Widget Fullsize': 'Widget Fullsize',
  'Workbase Icon': 'Workbase Icon',
  Absorblms: 'Absorblms',
  AI: 'AI',
  Analytics: 'Analytics',
  Anthology: 'Anthology',
  App: 'App',
  Attachment: 'Attachment',
  Back: 'Back',
  Banner: 'Banner',
  Blink: 'Blink',
  Bold: 'Bold',
  Bookmark: 'Bookmark',
  Books: 'Books',
  Button: 'Button',
  Calendar: 'Calendar',
  Caption: 'Caption',
  Car: 'Car',
  Career: 'Career',
  Chart: 'Chart',
  Chat: 'Chat',
  Checkmark: 'Checkmark',
  Claromentis: 'Claromentis',
  Clock: 'Clock',
  Close: 'Close',
  Coachy: 'Coachy',
  Coda: 'Coda',
  Code: 'Code',
  Colors: 'Colors',
  Companies: 'Companies',
  Completed: 'Completed',
  Confluence: 'Confluence',
  Connecteam: 'Connecteam',
  Contact: 'Contact',
  Content: 'Content',
  Cornerstone: 'Cornerstone',
  Court: 'Court',
  Creditcard: 'Creditcard',
  Crop: 'Crop',
  Customers: 'Customers',
  Darkmode: 'Darkmode',
  Dashboard: 'Dashboard',
  Data: 'Data',
  Delete: 'Delete',
  Departments: 'Departments',
  Docebo: 'Docebo',
  Download: 'Download',
  Drawing: 'Drawing',
  Dynamic: 'Dynamic',
  Edit: 'Edit',
  elearnio: 'elearnio',
  Email: 'Email',
  Employee: 'Employee',
  Employeeportal: 'Employeeportal',
  Enterprise: 'Enterprise',
  Error: 'Error',
  Essential: 'Essential',
  Evernote: 'Evernote',
  Extend: 'Extend',
  Extern: 'Extern',
  Facebook: 'Facebook',
  Feedback: 'Feedback',
  Filter: 'Filter',
  Flag: 'Flag',
  Folder: 'Folder',
  Forward: 'Forward',
  Fullscreen: 'Fullscreen',
  Function: 'Function',
  Global: 'Global',
  Globe: 'Globe',
  Groups: 'Groups',
  Guru: 'Guru',
  Hand: 'Hand',
  Help: 'Help',
  History: 'History',
  Home: 'Home',
  HubIntranet: 'HubIntranet',
  Igloo: 'Igloo',
  Image: 'Image',
  Info: 'Info',
  Instagram: 'Instagram',
  Integrations: 'Integrations',
  iSpring: 'iSpring',
  Italic: 'Italic',
  Jive: 'Jive',
  Kajabi: 'Kajabi',
  Laptop: 'Laptop',
  Layout: 'Layout',
  LearnUpon: 'LearnUpon',
  Lesson: 'Lesson',
  Lightmode: 'Lightmode',
  Lightning: 'Lightning',
  LinkedIn: 'LinkedIn',
  Links: 'Links',
  List: 'List',
  Litmos: 'Litmos',
  Location: 'Location',
  Lock: 'Lock',
  Logout: 'Logout',
  Maintenance: 'Maintenance',
  Marketplace: 'Marketplace',
  Math: 'Math',
  Megaphone: 'Megaphone',
  Memberspot: 'Memberspot',
  Minus: 'Minus',
  Module: 'Module',
  Moodle: 'Moodle',
  Motivation: 'Motivation',
  News: 'News',
  Notes: 'Notes',
  Notion: 'Notion',
  Nuclino: 'Nuclino',
  Page: 'Page',
  Partner: 'Partner',
  Pause: 'Pause',
  PeopleOne: 'PeopleOne',
  Permissions: 'Permissions',
  Personio: 'Personio',
  Phone: 'Phone',
  Play: 'Play',
  Plus: 'Plus',
  Presentation: 'Presentation',
  Press: 'Press',
  Process: 'Process',
  Progressbar: 'Progressbar',
  QuestionBubble: 'QuestionBubble',
  Recent: 'Recent',
  Record: 'Record',
  Reset: 'Reset',
  Resize: 'Resize',
  Rewatch: 'Rewatch',
  Role: 'Role',
  Scissors: 'Scissors',
  Search: 'Search',
  Section: 'Section',
  Send: 'Send',
  Server: 'Server',
  Settings: 'Settings',
  Share: 'Share',
  Sharepoint: 'Sharepoint',
  Slite: 'Slite',
  Support: 'Support',
  Surveys: 'Surveys',
  Swirl: 'Swirl',
  Switch: 'Switch',
  Table: 'Table',
  TalentLMS: 'TalentLMS',
  Tasks: 'Tasks',
  Text: 'Text',
  Title: 'Title',
  Touch: 'Touch',
  Trainings: 'Trainings',
  Tram: 'Tram',
  Uberblick: 'Uberblick',
  Underline: 'Underline',
  Updates: 'Updates',
  Video: 'Video',
  Voice: 'Voice',
  VS: 'VS',
  Warning: 'Warning',
  Wave: 'Wave',
  Waveform: 'Waveform',
  Webapp: 'Webapp',
  Whiteboard: 'Whiteboard',
  Wiki: 'Wiki',
  Wordpress: 'Wordpress',
  Workramp: 'Workramp',
  Workvivo: 'Workvivo',
  X: 'X',
  Yoobic: 'Yoobic',
  YouTube: 'YouTube',
};
var getProps = ({ click, height, icon, iconVisibility, id, title, width, ...props },) => {
  var _ref, _humanReadableEnumMap_icon, _ref1, _ref2, _ref3;
  return {
    ...props,
    D4bKfxOrr: (_ref = iconVisibility !== null && iconVisibility !== void 0 ? iconVisibility : props.D4bKfxOrr) !== null && _ref !== void 0
      ? _ref
      : true,
    jtg7ulL9r:
      (_ref2 = (_ref1 = (_humanReadableEnumMap_icon = humanReadableEnumMap[icon]) !== null && _humanReadableEnumMap_icon !== void 0
                  ? _humanReadableEnumMap_icon
                  : icon) !== null && _ref1 !== void 0
            ? _ref1
            : props.jtg7ulL9r) !== null && _ref2 !== void 0
        ? _ref2
        : 'Images - Drag and Upload',
    tfJBBPQxM: click !== null && click !== void 0 ? click : props.tfJBBPQxM,
    z0GMq68oS: (_ref3 = title !== null && title !== void 0 ? title : props.z0GMq68oS) !== null && _ref3 !== void 0 ? _ref3 : 'Add a photo',
  };
};
var createLayoutDependency = (props, variants,) => {
  if (props.layoutDependency) return variants.join('-',) + props.layoutDependency;
  return variants.join('-',);
};
var Component = /* @__PURE__ */ React.forwardRef(function (props, ref,) {
  const { activeLocale, setLocale, } = useLocaleInfo();
  const { style, className, layoutId, variant, z0GMq68oS, D4bKfxOrr, jtg7ulL9r, tfJBBPQxM, ...restProps } = getProps(props,);
  const {
    baseVariant,
    classNames,
    clearLoadingGesture,
    gestureHandlers,
    gestureVariant,
    isLoading,
    setGestureState,
    setVariant,
    variants,
  } = useVariantState({ defaultVariant: 'z6hwlkXWt', enabledGestures, variant, variantClassNames, },);
  const layoutDependency = createLayoutDependency(props, variants,);
  const { activeVariantCallback, delay, } = useActiveVariantCallback(baseVariant,);
  const onTap19zr4ox = activeVariantCallback(async (...args) => {
    setGestureState({ isPressed: false, },);
    if (tfJBBPQxM) {
      const res = await tfJBBPQxM(...args,);
      if (res === false) return false;
    }
  },);
  const ref1 = React.useRef(null,);
  const defaultLayoutId = React.useId();
  const sharedStyleClassNames = [];
  const componentViewport = useComponentViewport();
  return /* @__PURE__ */ _jsx(LayoutGroup, {
    id: layoutId !== null && layoutId !== void 0 ? layoutId : defaultLayoutId,
    children: /* @__PURE__ */ _jsx(Variants, {
      animate: variants,
      initial: false,
      children: /* @__PURE__ */ _jsx(Transition, {
        value: transition1,
        children: /* @__PURE__ */ _jsxs(motion.div, {
          ...restProps,
          ...gestureHandlers,
          className: cx(serializationHash, ...sharedStyleClassNames, 'framer-1gvv1ev', className, classNames,),
          'data-framer-name': 'Variant 1',
          'data-highlight': true,
          layoutDependency,
          layoutId: 'z6hwlkXWt',
          onTap: onTap19zr4ox,
          ref: ref !== null && ref !== void 0 ? ref : ref1,
          style: {
            backgroundColor: 'rgba(245, 245, 247, 0)',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            ...style,
          },
          variants: { 'z6hwlkXWt-hover': { backgroundColor: 'rgb(245, 245, 247)', }, },
          ...addPropertyOverrides({ 'z6hwlkXWt-hover': { 'data-framer-name': void 0, }, }, baseVariant, gestureVariant,),
          children: [
            D4bKfxOrr && /* @__PURE__ */ _jsx(ComponentViewportProvider, {
              children: /* @__PURE__ */ _jsx(motion.div, {
                className: 'framer-1x7ovsz-container',
                layoutDependency,
                layoutId: 'UBnA50NQY-container',
                children: /* @__PURE__ */ _jsx(Icon, {
                  color: 'rgb(82, 82, 91)',
                  customPath: '',
                  height: '100%',
                  id: 'UBnA50NQY',
                  layoutId: 'UBnA50NQY',
                  style: { height: '100%', width: '100%', },
                  variant: jtg7ulL9r,
                  variantText: '',
                  width: '100%',
                },),
              },),
            },),
            /* @__PURE__ */ _jsx(RichText, {
              __fromCanvasComponent: true,
              children: /* @__PURE__ */ _jsx(React.Fragment, {
                children: /* @__PURE__ */ _jsx(motion.p, {
                  style: {
                    '--font-selector': 'Q1VTVE9NO0Jhc2UgUmVndWxhcg==',
                    '--framer-font-family': '"Base Regular", "Base Regular Placeholder", sans-serif',
                    '--framer-text-color': 'var(--extracted-r6o4lv, rgb(82, 82, 91))',
                  },
                  children: 'Add a photo',
                },),
              },),
              className: 'framer-fanldc',
              fonts: ['CUSTOM;Base Regular',],
              layoutDependency,
              layoutId: 'rbR7_FAXt',
              style: {
                '--extracted-r6o4lv': 'rgb(82, 82, 91)',
                '--framer-link-text-color': 'rgb(0, 153, 255)',
                '--framer-link-text-decoration': 'underline',
              },
              text: z0GMq68oS,
              verticalAlignment: 'top',
              withExternalLayout: true,
            },),
          ],
        },),
      },),
    },),
  },);
},);
var css = [
  '@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }',
  '.framer-BpqaY.framer-1yrozs6, .framer-BpqaY .framer-1yrozs6 { display: block; }',
  '.framer-BpqaY.framer-1gvv1ev { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 4px 8px 4px 8px; position: relative; width: min-content; }',
  '.framer-BpqaY .framer-1x7ovsz-container { flex: none; height: 14px; position: relative; width: 14px; }',
  '.framer-BpqaY .framer-fanldc { flex: none; height: auto; position: relative; white-space: pre; width: auto; }',
  '@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BpqaY.framer-1gvv1ev { gap: 0px; } .framer-BpqaY.framer-1gvv1ev > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-BpqaY.framer-1gvv1ev > :first-child { margin-left: 0px; } .framer-BpqaY.framer-1gvv1ev > :last-child { margin-right: 0px; } }',
];
var FramerqDfyn2AS0 = withCSS(Component, css, 'framer-BpqaY',);
var stdin_default = FramerqDfyn2AS0;
FramerqDfyn2AS0.displayName = 'Button: Add a photo';
FramerqDfyn2AS0.defaultProps = { height: 27, width: 124, };
addPropertyControls(FramerqDfyn2AS0, {
  z0GMq68oS: { defaultValue: 'Add a photo', displayTextArea: false, title: 'Title', type: ControlType.String, },
  D4bKfxOrr: { defaultValue: true, title: 'Icon Visibility', type: ControlType.Boolean, },
  jtg7ulL9r: (IconControls === null || IconControls === void 0 ? void 0 : IconControls['variant']) &&
    { ...IconControls['variant'], defaultValue: 'Images - Drag and Upload', description: void 0, hidden: void 0, title: 'Icon', },
  tfJBBPQxM: { title: 'Click', type: ControlType.EventHandler, },
},);
addFonts(FramerqDfyn2AS0, [{ explicitInter: true, fonts: [], }, ...IconFonts,], { supportsExplicitInterCodegen: true, },);

export { stdin_default, };
