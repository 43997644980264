import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  tagTypes: ['Companies'],
  baseQuery,
  endpoints: (builder) => ({
    checkDomain: builder.mutation<void, { domain: string }>({
      query: (data) => ({
        url: '/companies/check-domain',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useCheckDomainMutation } = companiesApi;
