import { GlobalStyles } from '@mui/material';
import '@toeverything/theme/style.css';

function EditorStyles() {
  return (
    <GlobalStyles
      styles={(theme) => ({
        '.affine-database-title textarea': {
          color: 'inherit',
        },
        '.database-block-detail-header-icon': {
          color: theme.palette.common.black,
        },
        'database-datasource-note-renderer div:last-child': {
          display: 'none',
        },
        '.affine-menu-input': {
          color: theme.palette.text.primary,
          '&.focused': {
            borderColor: theme.palette.primary.main,
          },
        },
        '.data-view-popup-container': {
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[20],
          '.select-selected': {
            color: theme.palette.common.black,
          },
        },
        '.close-modal': {
          right: 16,
          top: 16,
          zIndex: 15,
        },
        '.edgeless-text-block-container': {
          minWidth: '150px',
        },
        '.embed-block-container': {
          height: 'auto !important',
        },
      })}
    />
  );
}

export default EditorStyles;
