import { Box, Stack, SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { ShareLevel } from 'src/lib/types/share-level';
import {
  useDeletePageMutation,
  useDuplicatePageMutation,
  usePageQuery,
  usePatchPageMutation,
} from 'src/store/api/pagesApi';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import { WorkbasePressContentIcon } from 'src/assets/icons/workbaseIcons';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';
import Editor from 'src/features/editor2/Editor';
import EditorVerifyE2EProvider from 'src/features/editor2/providers/EditorVerifyE2EProvider';
import FloatingActionWrapper from 'src/ui-components/floating-action-wrapper/FloatingActionWrapper';
import useUpdateAppSidebarItemTitle from 'src/hooks/useUpdateAppSidebarItemTitle';
import ShareModalButton from './ShareModalButton';
import { useAppDispatch } from 'src/store';
import { switchPortal } from 'src/store/client/userPortalSlice';

// eslint-disable-next-line max-statements
export default function CustomPageView() {
  const param = useParams();
  if (!param.id) throw new Error('Param id required for this view');
  const pageId = param.id;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: shareLevelData, error } = useShareLevelQuery({
    resourceType: 'page',
    resourceId: param.id,
  });
  const dispatch = useAppDispatch();
  const hasEditingPermissions =
    shareLevelData?.shareLevel !== ShareLevel.view &&
    shareLevelData?.shareLevel !== ShareLevel.none;
  const hasAnyPermission = useHasAnyPermission();
  const hasAddPagesPermission = hasAnyPermission([PermissionCodes.ADD_PAGES]);
  const { data } = usePageQuery(pageId);
  const [deletePage] = useDeletePageMutation();
  const [duplicatePage] = useDuplicatePageMutation();
  const [patchPage] = usePatchPageMutation();
  const isCustomerPortal = useIsCustomerPortal();
  const updateAppSidebarItemTitle = useUpdateAppSidebarItemTitle(pageId);

  const hasManagePermission = shareLevelData?.shareLevel === ShareLevel.manage;
  const canView = shareLevelData?.shareLevel !== ShareLevel.none && !error;
  if (data?.createdInPortal === 'customerPortal' && !isCustomerPortal) {
    dispatch(switchPortal('customerPortal'));
  }
  if (!canView) return <Navigate to="/" replace />;

  return (
    <Stack height="100%">
      <EditorVerifyE2EProvider
        name={`page.${pageId}`}
        readOnly={!hasEditingPermissions}
        onTitleChange={updateAppSidebarItemTitle}
      >
        {hasEditingPermissions && (
          <FloatingActionWrapper>
            {data && !isCustomerPortal && (
              <Tooltip title={t('sidebarTooltip')}>
                <Box bgcolor="primary.paper" borderRadius={1}>
                  <ToggleButton
                    data-cy="custom-page-top-bar-show-in-sidebar-button"
                    size="small"
                    color={'success'}
                    selected={data.sidebar}
                    value={data.sidebar}
                    onChange={(_, state) => {
                      patchPage({ id: data.id, sidebar: !state });
                    }}
                  >
                    <SvgIcon component={WorkbasePressContentIcon} fontSize="small" />
                  </ToggleButton>
                </Box>
              </Tooltip>
            )}
            <ShareModalButton pageId={pageId} shareLevel={shareLevelData?.shareLevel} />
            <DeleteDuplicateMenu
              dataCy="custom-page"
              onDuplicate={
                hasAddPagesPermission
                  ? () => {
                      duplicatePage(pageId).then((resp: any) => {
                        if (!resp.error) {
                          navigate(`/page/${resp.data.id}`);
                          showNotification(t('notifications.duplicateSuccess'));
                        } else {
                          showNotification(t('notifications.duplicateError'), 'error');
                        }
                      });
                    }
                  : undefined
              }
              deleteDialogDescription={t(
                'editor.topBar.customQuestionsOnSettingIcon.deleteCustomSiteQuestion'
              )}
              onDelete={
                hasManagePermission
                  ? () => {
                      deletePage(pageId).then((resp: any) => {
                        if (!resp.error) {
                          showNotification(t('notifications.deleteSuccess'));
                          navigate('/edit');
                        } else {
                          showNotification(t('notifications.deleteError'), 'error');
                        }
                      });
                    }
                  : undefined
              }
            />
          </FloatingActionWrapper>
        )}
        <Editor />
      </EditorVerifyE2EProvider>
    </Stack>
  );
}
