import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import NavGroup from './nav-group/NavGroup';
import ToggleFullWidthArrow, { toggleFullWidthArrowClassName } from './ToggleFullWidthArrow';
import AppLogo from './AppLogo';
import AddButton from './add-button';
import MaskedScrollBox from './MaskedScrollBox';
import { COLLAPSED_WIDTH, FULL_WIDTH } from './consts';
import SidebarTree from './tree/Tree';

export default function AppSidebar() {
  const [isFullWidth, setIsFullWidth] = useState(true);
  return (
    <Box
      height="100%"
      position="relative"
      flexShrink={0}
      zIndex={16}
      px={2}
      pb={1.25}
      pt={1}
      width={isFullWidth ? FULL_WIDTH : COLLAPSED_WIDTH}
      sx={{
        transition: (theme) =>
          theme.transitions.create('width', {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeInOut,
          }),
        '&:hover': {
          [`.${toggleFullWidthArrowClassName}`]: {
            opacity: 1,
          },
        },
      }}
    >
      <ToggleFullWidthArrow onFullWidthChange={setIsFullWidth} isFullWidth={isFullWidth} />
      <Stack gap={0} pt={0.5} height="100%" alignItems="flex-start" overflow="hidden">
        <AppLogo fullWidth={isFullWidth} />
        <MaskedScrollBox>
          <NavGroup fullWidth={isFullWidth} />
          <SidebarTree />
        </MaskedScrollBox>
        <AddButton />
      </Stack>
    </Box>
  );
}
