import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';
import { driveApi } from './driveApi';
import { sidebarApi } from './sidebarApi';

interface Page {
  id: string;
  name: string;
  icon: string;
  sidebar: boolean;
  imageUrl: string | null;
  imageId: string | null;
  createdInPortal: 'customerPortal' | 'employeePortal';
}

export const pagesApi = createApi({
  reducerPath: 'pagesApi',
  tagTypes: ['Page'],
  baseQuery,
  endpoints: (builder) => ({
    page: builder.query<Page, string>({
      query: (id) => ({
        url: `/pages/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'Page', id }],
    }),
    postPage: builder.mutation<Page, void>({
      query: () => ({
        url: '/pages',
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
      },
    }),
    patchPage: builder.mutation<Page, { id: string } & Partial<Page>>({
      query: ({ id, ...rest }) => ({
        url: `/pages/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Page', id }],
    }),
    deletePage: builder.mutation<void, string>({
      query: (id) => ({
        url: `/pages/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
      },
    }),
    duplicatePage: builder.mutation<void, string>({
      query: (id) => ({
        url: `/pages/${id}/duplicate`,
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
      },
    }),
  }),
});

export const {
  usePostPageMutation,
  useDeletePageMutation,
  useDuplicatePageMutation,
  usePageQuery,
  usePatchPageMutation,
} = pagesApi;
