import { useEffect, useCallback } from 'react';
import { isMacOS } from 'src/constants/platform';

interface UseToggleEditorModeHotkeyOptions {
  onToggleMode: () => void;
}

export function useToggleEditorModeHotkey({ onToggleMode }: UseToggleEditorModeHotkeyOptions) {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isMacOS && event.altKey && event.code === 'KeyS') {
        event.preventDefault();
        onToggleMode();
      } else if (!isMacOS && event.ctrlKey && event.shiftKey && event.code === 'KeyS') {
        event.preventDefault();
        onToggleMode();
      }
    },
    [onToggleMode]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);
}
