import { alpha, Stack, SxProps } from '@mui/material';

export default function FloatingActionWrapper({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) {
  return (
    <Stack
      direction="row"
      p={1}
      gap={1}
      position="absolute"
      top={8}
      right={8}
      width="max-content"
      zIndex={20}
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.4),
        backdropFilter: 'blur(8px)',
        borderRadius: 1,
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
}
