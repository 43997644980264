import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './api';

export interface UploadResponse {
  id: string;
  uploadUrl: string;
  expirationTimeMs: number;
  uploadType: string;
}

export const uploadApi = createApi({
  reducerPath: 'uploadApi',
  tagTypes: ['Upload'],
  baseQuery,
  endpoints: (builder) => ({
    generateUploadUrl: builder.mutation<
      UploadResponse,
      { filename: string; registerSessionId: string }
    >({
      query: ({ filename, registerSessionId }) => ({
        url: 'media/anon/upload',
        method: 'POST',
        body: {
          filename,
          registerSessionId,
        },
      }),
    }),
    getUploadUrl: builder.query<{ url: string; expiresInMs: number }, { sessionId: string }>({
      query: ({ sessionId }) => ({
        url: `media/anon/download/${sessionId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGenerateUploadUrlMutation, useGetUploadUrlQuery, useLazyGetUploadUrlQuery } =
  uploadApi;
