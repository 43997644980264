// @ts-nocheck
/* eslint-disable */
/* This file was generated by Unframer for Framer project 71fd72204da79e7e "Workbase App", do not edit manually */
// /:https://framerusercontent.com/modules/YWSoCVlrsh88aHjvtEVO/Pfnvt2qw4Nd5pO55xIXQ/ZC1Aexe2G.js
import { fontStore, } from 'unframer';
fontStore.loadFonts(['CUSTOM;SanBase Regular',],);
var fonts = [{
  explicitInter: true,
  fonts: [{ family: 'SanBase Regular', source: 'custom', url: 'https://framerusercontent.com/assets/yst7FBAPGkl0ksj0P2rh10jmhQ.woff2', },],
},];
var css = [
  `.framer-ETxHt .framer-styles-preset-lrhlo3:not(.rich-text-wrapper), .framer-ETxHt .framer-styles-preset-lrhlo3.rich-text-wrapper p { --framer-font-family: "SanBase Regular", "SanBase Regular Placeholder", sans-serif; --framer-font-open-type-features: 'blwf' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv11' on; --framer-font-size: 12px; --framer-font-style: normal; --framer-font-variation-axes: normal; --framer-font-weight: 400; --framer-letter-spacing: 0px; --framer-line-height: 100%; --framer-paragraph-spacing: 20px; --framer-text-alignment: left; --framer-text-color: var(--token-ad22872e-8820-44b3-9dc3-0d1bf136ea27, #3f3f46); --framer-text-decoration: none; --framer-text-stroke-color: initial; --framer-text-stroke-width: initial; --framer-text-transform: none; }`,
];
var className = 'framer-ETxHt';

export { className, css, fonts, };
