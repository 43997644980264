/* eslint-disable */
import { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { uploadAnonImage } from 'src/lib/utils/imageUpload';
import { NODE_ENV } from 'src/global-config';
import {
  useGoogleLoginMutation,
  useLazyGetRegistrationSessionDataQuery,
  useLoginMutation,
  useRegisterEmailMutation,
  useResendCodeMutation,
  useUpdateRegistrationMutation,
  useVerifyCodeMutation,
  useCompleteRegistrationMutation,
  useAppleLoginMutation,
} from 'src/store/api/authApi';
import { useCheckDomainMutation } from 'src/store/api/companiesApi';
import { useAppDispatch } from 'src/store';
import { useGenerateUploadUrlMutation, useLazyGetUploadUrlQuery } from 'src/store/api/uploadSlice';
import {
  OnboardingFormData,
  Overlay,
  OverlayType,
  FormButtonVariant,
  OnboardingSteps,
  ONBOARDING_STEPS,
} from './types';
import { auth, googleProvider, appleProvider } from 'src/firebase';
import { GoogleAuthProvider, signInWithPopup, OAuthProvider } from 'firebase/auth';

export function useOnboardingLogic({ fixedScreen }: { fixedScreen?: OnboardingSteps }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const regId = useRef<string | undefined | null>(null);
  const [currentVariant, setCurrentVariant] = useState<OnboardingSteps>('Create Account');
  const currentVariantRef = useRef<OnboardingSteps>('Create Account');
  const profilePhotoRef = useRef<{ id: string; url: string } | undefined | null>(null);
  const companyLogoRef = useRef<{ id: string; url: string } | undefined | null>(null);
  const [formData, setFormData] = useState<OnboardingFormData>({ step: 'Create Account' });
  const [buttonVariant, setButtonVariant] = useState<FormButtonVariant>(FormButtonVariant.DEFAULT);
  const [dragState, setDragState] = useState<OverlayType>(Overlay.NONE);

  const [generateUploadUrl] = useGenerateUploadUrlMutation();
  const [getUploadUrl] = useLazyGetUploadUrlQuery();
  const [verifyCode] = useVerifyCodeMutation();
  const [resendCode] = useResendCodeMutation();
  const [updateRegistration] = useUpdateRegistrationMutation();
  const [checkDomain] = useCheckDomainMutation();
  const [login] = useLoginMutation();
  const [registerEmail] = useRegisterEmailMutation();
  const [getRegistrationSessionData] = useLazyGetRegistrationSessionDataQuery();
  const [googleLogin] = useGoogleLoginMutation();
  const [appleLogin] = useAppleLoginMutation();
  const [completeRegistration, { isLoading: completeRegistrationLoading }] =
    useCompleteRegistrationMutation();

  const loginWithGoogle = useCallback(async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      setFormData((prev) => ({
        ...prev,
        email: user.email || '',
        name: user.displayName?.split(' ')[0] || '',
        lastName: user.displayName?.split(' ').slice(1).join(' ') || '',
      }));

      // Get the Google Access Token
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential?.accessToken) {
        const idToken = await user.getIdToken();
        const res = await googleLogin({ idToken, language: i18n.language });
        if ('data' in res && 'companySiteNetloc' in res.data) {
          window.location.href = `https://${res.data.companySiteNetloc}/auth?exchangeToken=${res.data.exchangeToken}`;
        }
        if (fixedScreen) {
          return;
        }
        if ('data' in res && res.data.registerSessionId) {
          regId.current = res.data.registerSessionId;
          localStorage.setItem('registrationId', res.data.registerSessionId);
          localStorage.setItem('verificationCode', res.data.verificationCode);
          getRegistrationData();
        }
      }
    } catch (error) {
      showNotification(t('onboarding.googleLoginError'), 'error');
    }
  }, [dispatch, googleLogin, navigate, t]);

  const loginWithApple = useCallback(async () => {
    try {
      appleProvider.addScope('email');
      appleProvider.addScope('name');

      const result = await signInWithPopup(auth, appleProvider);
      const user = result.user;
      console.log(user);
      setFormData((prev) => ({
        ...prev,
        email: user.email || '',
        name: user.displayName?.split(' ')[0] || '',
        lastName: user.displayName?.split(' ').slice(1).join(' ') || '',
      }));

      const credential = OAuthProvider.credentialFromResult(result);
      if (credential?.accessToken) {
        const idToken = await user.getIdToken();
        const res = await appleLogin({ idToken, language: i18n.language });
        if ('data' in res && 'companySiteNetloc' in res.data) {
          window.location.href = `https://${res.data.companySiteNetloc}/auth?exchangeToken=${res.data.exchangeToken}`;
        }
        if (fixedScreen) {
          return;
        }
        if ('data' in res && res.data.registerSessionId) {
          regId.current = res.data.registerSessionId;
          localStorage.setItem('registrationId', res.data.registerSessionId);
          localStorage.setItem('verificationCode', res.data.verificationCode);
          getRegistrationData();
        }
      }
    } catch (error) {
      showNotification(t('onboarding.appleLoginError'), 'error');
    }
  }, [appleLogin, i18n.language, t, fixedScreen]);

  const getRegistrationData = useCallback(async () => {
    if (!regId.current || fixedScreen) return;
    const res = await getRegistrationSessionData({ id: regId.current });
    if ('data' in res) {
      const avatarUrl = res.data.avatarId
        ? await getUploadUrl({ sessionId: res.data.avatarId })
        : undefined;
      const companyLogoUrl = res.data.companyLogoId
        ? await getUploadUrl({ sessionId: res.data.companyLogoId })
        : undefined;
      setFormData((prev) => ({
        ...prev,
        email: res.data.email,
        name: res.data.firstName,
        lastName: res.data.lastName || '',
        password: res.data.password,
        workspaceName: res.data.companyName,
        workspaceDomain: res.data.companySubdomain,
        verificationCode: res.data.verificationCode,
        profilePhoto: avatarUrl?.data
          ? { id: res.data.avatarId, url: avatarUrl.data.url }
          : undefined,
        companyLogo: companyLogoUrl?.data
          ? { id: res.data.companyLogoId, url: companyLogoUrl.data.url }
          : undefined,
      }));
      profilePhotoRef.current = avatarUrl?.data
        ? { id: res.data.avatarId, url: avatarUrl.data.url }
        : null;
      companyLogoRef.current = companyLogoUrl?.data
        ? { id: res.data.companyLogoId, url: companyLogoUrl.data.url }
        : null;

      if (res.data.companyName && res.data.companySubdomain) {
        currentVariantRef.current = 'Create Company - Use-cases';
        setCurrentVariant('Create Company - Use-cases');
        return;
      }
      if (res.data.firstName) {
        currentVariantRef.current = 'Create Company - Name';
        setCurrentVariant('Create Company - Name');
      }
      if (res.data.verificationCode) {
        currentVariantRef.current = 'Create Account - Profile Data';
        setCurrentVariant('Create Account - Profile Data');
      }
    }
  }, [getRegistrationSessionData, fixedScreen, getUploadUrl]);

  const onLogoutUser = useCallback(() => {
    currentVariantRef.current = 'Create Account';
    setCurrentVariant('Create Account');
    profilePhotoRef.current = null;
    companyLogoRef.current = null;
    setButtonVariant(FormButtonVariant.DEFAULT);
    setDragState(Overlay.NONE);
  }, []);

  useEffect(() => {
    regId.current = localStorage.getItem('registrationId');
    const verificationCode = localStorage.getItem('verificationCode');
    if (regId.current && verificationCode) {
      getRegistrationData();
    }
  }, [getRegistrationData]);

  const validateEmailExists = async (formData: OnboardingFormData): Promise<boolean> => {
    if (!formData.email) return false;
    if (formData.password) {
      const result = await login({ email: formData.email, password: formData.password });
      if (!('data' in result)) {
        return false;
      }
      const netloc = window.location.host;
      if (result.data.companySiteNetloc && result.data.companySiteNetloc !== netloc) {
        window.location.href = `https://${result.data.companySiteNetloc}/auth?exchangeToken=${result.data.exchangeToken}`;
      }

      navigate({
        pathname: '/auth',
        search: `?exchangeToken=${result.data.exchangeToken}`,
      });
      return false;
    }
    const result = await registerEmail({ email: formData.email, locale: i18n.language });
    if ('data' in result) {
      regId.current = result.data.registerSessionId;
      localStorage.setItem('registrationId', result.data.registerSessionId);
      getRegistrationData();
      return true;
    }
    return false;
  };

  const handleProfilePhotoUpload = useCallback(
    async (file: File): Promise<void> => {
      if (!file) return;
      try {
        const res = await generateUploadUrl({
          filename: file.name,
          registerSessionId: regId.current as string,
        });
        if (!('data' in res)) return;
        const { data } = res;
        await uploadAnonImage(data.uploadUrl, file);
        const { data: downloadData } = await getUploadUrl({ sessionId: data.id });

        if (!downloadData) return;
        if (ONBOARDING_STEPS.indexOf(currentVariantRef.current as any) > 2) {
          companyLogoRef.current = { id: data.id, url: downloadData.url };
        } else {
          profilePhotoRef.current = { id: data.id, url: downloadData.url };
        }
      } catch {
        showNotification(t('onboarding.uploadPhotoError'), 'error');
      }
    },
    [generateUploadUrl, getUploadUrl, t]
  );

  const validateCode = async (formData: OnboardingFormData): Promise<boolean> => {
    if (!formData.verificationCode || !regId.current) return false;
    const result = await verifyCode({
      id: regId.current,
      verificationCode: formData.verificationCode,
    });

    if ('error' in result) {
      return false;
    }

    if ('data' in result && result.data) {
      if ('exchangeToken' in result.data) {
        window.location.href = `https://${result.data.companySiteNetloc}/auth?exchangeToken=${result.data.exchangeToken}`;
      }
    }
    localStorage.setItem('verificationCode', formData.verificationCode);
    getRegistrationData();
    return true;
  };

  const validateCompanyStep = async (formData: OnboardingFormData): Promise<boolean> => {
    if (!formData.workspaceDomain || !formData.workspaceName) return false;
    const result = await checkDomain({
      domain: `https://${formData.workspaceDomain}.workbase.${
        NODE_ENV === 'production' ? 'io' : 'dev'
      }`,
    });

    if ('error' in result) {
      return false;
    }

    setFormData((prev) => ({
      ...prev,
      ...formData,
    }));
    const res = await updateRegistration({
      id: regId.current as string,
      companyName: formData.workspaceName,
      companySubdomain: formData.workspaceDomain,
      companyLogoId: formData.companyLogo?.id,
      verificationCode: localStorage.getItem('verificationCode') as string,
    });
    if ('error' in res) {
      return false;
    }
    return true;
  };

  const handleResendVerificationCode = useCallback(async () => {
    if (!regId.current) return;
    const result = await resendCode({ id: regId.current });
    if ('error' in result) {
      showNotification('Failed to resend verification code', 'error');
    } else {
      showNotification('Verification code resent', 'success');
    }
  }, [resendCode]);

  const handleInviteStep = async (formData: OnboardingFormData): Promise<boolean> => {
    if (!formData.inviteEmail1) return true;
    const localFormData = { ...formData } as Partial<OnboardingFormData>;
    delete localFormData.step;
    const result = await updateRegistration({
      id: regId.current as string,
      verificationCode: localStorage.getItem('verificationCode') as string,
      // @ts-expect-error
      initialInvitations: Object.values(localFormData)
        .filter((value) => typeof value === 'string' && value.trim().length > 0)
        .slice(0, 5),
    });
    if ('error' in result) {
      return false;
    }
    const localId = regId.current;
    const verificationCode = localStorage.getItem('verificationCode') as string;
    const completeResult = await completeRegistration({ id: localId as string, verificationCode });
    if ('error' in completeResult) {
      return false;
    }
    return true;
  };

  const handleProfileStep = async (formData: OnboardingFormData) => {
    if (!regId.current) return false;
    const result = await updateRegistration({
      id: regId.current as string,
      firstName: formData.name,
      password: formData.password,
      avatarId: profilePhotoRef.current?.id,
      marketingOptIn: formData.marketing === 'on' ? true : false,
      verificationCode: localStorage.getItem('verificationCode') as string,
    });
    if ('error' in result) {
      return false;
    }
    return true;
  };

  return {
    formData,
    buttonVariant,
    dragState,
    profilePhotoRef,
    companyLogoRef,
    currentVariantRef,
    setFormData,
    setButtonVariant,
    completeRegistrationLoading,
    currentVariant,
    setCurrentVariant,
    setDragState,
    loginWithGoogle,
    loginWithApple,
    validateEmailExists,
    handleProfilePhotoUpload,
    getRegistrationData,
    validateCode,
    validateCompanyStep,
    onLogoutUser,
    handleResendVerificationCode,
    handleInviteStep,
    handleProfileStep,
  };
}
