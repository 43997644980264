import SplitPaneLayout from 'src/layouts/SplitPaneLayout';
import Sidebar from 'src/ui-components/layout/sidebar/Sidebar';
import { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useFetchThreadsQuery } from 'src/store/api/aiChatApi';
import { useTranslation } from 'react-i18next';
import { WorkbaseNewChatIcon } from 'src/assets/icons/workbaseIcons';
import useIsMobile from 'src/hooks/useIsMobile';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { PermissionCodes, useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import ChatPanel from './ChatPanel';
import { useChatDispatch, useChatState } from '../../features/ai-chat/helpers/chatContext';
import dayjs from 'dayjs';
import { useAppSelector } from 'src/store';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';

// eslint-disable-next-line max-statements
export default function FullScreenChat() {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isCustomerPortal = useIsCustomerPortal();
  const { data: threads } = useFetchThreadsQuery(isCustomerPortal);
  const { threadId } = useChatState((state) => ({ threadId: state.threadId }));
  const dispatch = useChatDispatch();
  const { isLoading: isLoadingPermissions } = useUserPermissionsQuery();
  const userType = useAppSelector(({ auth }) => auth.userType);
  const hasAnyPermission = useHasAnyPermission();
  const canAccessAiAssistant =
    hasAnyPermission([PermissionCodes.ACCESS_AI_ASSISTANT]) || userType === 'customer';
  const isMobile = useIsMobile();
  // when chat is opened from the link
  useEffect(() => {
    if (canAccessAiAssistant && !isLoadingPermissions) {
      dispatch({ type: 'OPEN_FULLSCREEN' });
      dispatch({ type: 'OPEN_DIALOG' });
      if (chatId) {
        dispatch({ type: 'SET_SELECTED_CHAT', payload: chatId });
      }
    }
    return () => {
      dispatch({ type: 'CLOSE_FULLSCREEN' });
      if (isMobile) {
        dispatch({ type: 'CLOSE_DIALOG' });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAccessAiAssistant, isLoadingPermissions]);

  useEffect(() => {
    if (chatId) {
      dispatch({ type: 'SET_SELECTED_CHAT', payload: chatId as string });
    }
  }, [chatId, dispatch]);

  const handleAddThread = () => {
    dispatch({ type: 'CLEAR_CURRENT_THREAD' });
    navigate('/ai');
  };

  const headerIcon = {
    text: t('assistant.newChat'),
    icon: WorkbaseNewChatIcon,
    onClick: handleAddThread,
    disabled: !threadId,
  };

  const formattedItems = threads
    ? threads.map((item: any) => ({
        key: item.id,
        text: item.summary || t('assistant.newChat'),
        subtext: dayjs(item.createdAt).format('DD.MM.YYYY HH:mm'),
        prefix: null,
        onClick: () => {
          navigate(`/ai/${item.id}`);
        },
      }))
    : [];
  if (!canAccessAiAssistant && !isLoadingPermissions) return <Navigate to="/" />;
  if (isMobile) return <ChatPanel isMobile={isMobile} />;

  return (
    <SplitPaneLayout
      leftContent={
        <Sidebar
          activeKey={chatId || ''}
          items={formattedItems}
          dataCy="ai-sidebar"
          headerIcon={headerIcon}
        />
      }
      rightContent={<ChatPanel isMobile={isMobile} />}
    />
  );
}
