import * as React from 'react';
import { useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2GroupTransition,
  TreeItem2Label,
  TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';
import { IconButton } from '@mui/material';
import SvgIconOrEmoji from 'src/ui-components/icon/SvgIconOrEmoji';

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: UseTreeItem2Parameters,
  ref: React.Ref<HTMLLIElement>
) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root
        {...getRootProps(other)}
        sx={{
          '&:not(:last-child)': {
            mb: 0.25,
          },
        }}
      >
        <TreeItem2Content {...getContentProps()}>
          <TreeItem2IconContainer>
            <SvgIconOrEmoji content={publicAPI.getItem(itemId).icon} />
          </TreeItem2IconContainer>
          <TreeItem2Label
            {...getLabelProps()}
            // eslint-disable-next-line
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          />
          <TreeItem2IconContainer
            {...getIconContainerProps()}
            sx={{
              svg: {
                fontSize: 14,
              },
            }}
            onClick={(e) => {
              getIconContainerProps().onClick(e);
              e.stopPropagation();
            }}
          >
            <IconButton size="small" sx={{ p: 0.5 }}>
              <TreeItem2Icon status={status} />
            </IconButton>
          </TreeItem2IconContainer>
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default CustomTreeItem;
