import { defaultVideoBlockProps, VideoBlockModel, VideoBlockProps } from './videoSchema';
import { html, css, nothing, PropertyValues } from 'lit';
import { createRoot, Root } from 'react-dom/client';
import {
  DeleteIcon,
  DownloadIcon,
  ImageIcon20,
  DuplicateIcon,
  renderToolbarSeparator,
  HoverController,
  CaptionIcon,
} from '@blocksuite/blocks';
import {
  CheckBoxIcon,
  checkboxUnchecked,
  RoundedRectangleIcon,
} from '@blocksuite/affine-components/icons';
import downloadVideo from 'src/lib/utils/downloadVideo';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { UploadProvider } from 'src/core/upload/UploadContext';
import { Provider } from 'react-redux';
import { store } from 'src/store';
import CustomThemeProvider from 'src/providers/CustomThemeProvider';
import VideoItem from 'src/core/video-item';
import { ref } from 'lit/directives/ref.js';
import { CaptionedBlockComponent } from '@blocksuite/affine-components/caption';
import { getCurrentFrameFromVideoElement } from 'src/lib/utils/videoFrame';
import { join } from 'lit/directives/join.js';

function cloneVideoProperties(model: VideoBlockModel) {
  const clonedProps = {} as VideoBlockProps;
  for (const cur in defaultVideoBlockProps) {
    const key = cur as keyof VideoBlockProps;
    clonedProps[key] = model[key] as VideoBlockProps[keyof VideoBlockProps];
  }
  return clonedProps;
}

export class WorkbaseEmbedVideoBlock extends CaptionedBlockComponent<VideoBlockModel> {
  static override styles = css`
    .affine-block-component {
      position: relative;
    }
    .affine-video-container {
      margin: 18px 0;
    }
    .toolbar-label {
      margin-left: 8px;
    }
  `;

  private _reactRoot: Root | null = null;

  override accessor useCaptionEditor = true;

  override connectedCallback() {
    super.connectedCallback();

    this.contentEditable = 'false';

    this.handleEvent('click', () => {
      this.host.selection.setGroup('note', [
        this.host.selection.create('block', {
          blockId: this.blockId,
        }),
      ]);
    });
  }

  private _reinitializeReactRoot() {
    if (this._reactRoot) {
      this._reactRoot.unmount();
      this._reactRoot = null;
    }

    const reactContainer = this.renderRoot.querySelector(`#react-container-${this.model.id}`);
    if (reactContainer) {
      this._reactRoot = createRoot(reactContainer);
      this._renderReact();
    }
  }

  override firstUpdated() {
    const model = this.model;
    this.updateHoverState();
    const reactContainer = this.renderRoot.querySelector(`#react-container-${model.id}`);
    if (reactContainer && !this._reactRoot) {
      this._reactRoot = createRoot(reactContainer);
      this._renderReact();
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this._reactRoot) {
      this._reactRoot.unmount();
      this._reactRoot = null;
    }
  }

  private toggleDisableFastForward() {
    const currentValue = this.model.disabledFastForward || false;
    this.model.doc.updateBlock(this.model, { disabledFastForward: !currentValue });
    this.requestUpdate();
  }

  private getToolbarConfig() {
    const isReadOnly = this.model.doc.readonly;

    const toolbarConfig = [
      {
        icon: RoundedRectangleIcon,
        tooltip: 'Choose current frame as thumbnail',
        action: this.handleUploadCurrentFrameAsThumbnail.bind(this),
        readOnly: false,
      },
      {
        icon: ImageIcon20,
        tooltip: 'Upload Thumbnail',
        action: this.triggerThumbnailUpload.bind(this),
        readOnly: false,
      },
      {
        icon: this.model.disabledFastForward ? CheckBoxIcon : checkboxUnchecked(),
        label: 'Disable Fast Forward',
        action: this.toggleDisableFastForward.bind(this),
        readOnly: false,
      },
      {
        icon: DownloadIcon,
        tooltip: 'Download',
        action: () => downloadVideo(this.model.videoId as string, this.model.videoName as string),
        readOnly: true,
      },
      {
        icon: CaptionIcon,
        tooltip: 'Caption',
        action: () => this.captionEditor?.show(),
        readOnly: false,
      },
      {
        icon: DuplicateIcon,
        tooltip: 'Duplicate',
        action: this.handleDuplicateBlock.bind(this),
        readOnly: false,
      },
      {
        icon: DeleteIcon,
        tooltip: 'Delete',
        action: () => this.model.doc.deleteBlock(this.model),
        readOnly: false,
      },
    ];

    return toolbarConfig.filter((item) => (isReadOnly ? item.readOnly : true));
  }

  private async uploadThumbnail(blob: Blob): Promise<string | null> {
    try {
      const response = await imageUpload(new File([blob], 'poster.jpg'));
      if (response.status !== 201) {
        showNotification('Failed to upload thumbnail.', 'error');
        return null;
      }

      const { result } = response.data;
      const { variants } = result;
      const [imageUrl] = variants;

      // Update block data with the new poster URL
      this.model.doc.updateBlock(this.model, { posterSrc: imageUrl });
      return imageUrl;
    } catch (_) {
      showNotification('Failed to upload thumbnail.', 'error');
      return null;
    }
  }

  private triggerThumbnailUpload() {
    const input = this.renderRoot.querySelector('#thumbnail-upload') as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  protected async handleThumbnailUpload(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    if (!file) return;

    await this.uploadThumbnail(file);
  }

  private async handleUploadCurrentFrameAsThumbnail() {
    const video = this.querySelector('video') as HTMLVideoElement | null;
    if (!video) {
      showNotification('Failed to upload thumbnail: No video found.', 'error');
      return;
    }

    try {
      const currentFrameBlob = await getCurrentFrameFromVideoElement(video);
      if (currentFrameBlob) {
        await this.uploadThumbnail(currentFrameBlob);
      }
    } catch (_) {
      showNotification('Failed to upload thumbnail from current frame.', 'error');
    }
  }

  private handleDuplicateBlock() {
    const model = this.model;
    const prop: { flavour: 'affine:embed-workbase-video' } = {
      flavour: 'affine:embed-workbase-video',
      ...cloneVideoProperties(model),
    };
    this.doc.addSiblingBlocks(model, [prop]);
  }

  private renderToolbar() {
    const buttons = this.getToolbarConfig().map(
      ({ icon, label, tooltip, action }) => html`
        <editor-icon-button aria-label=${label || tooltip} .tooltip=${tooltip} @click=${action}>
          ${icon}
        </editor-icon-button>
        ${label
          ? html`<div class="toolbar-label" style="cursor: pointer;" @click=${action}>
              ${label}
            </div>`
          : nothing}
      `
    );

    return html` <editor-toolbar> ${join(buttons, renderToolbarSeparator)} </editor-toolbar> `;
  }

  private _renderReact() {
    const model = this.model;

    const handleDeleteBlock = () => model.doc.deleteBlock(model);
    const handleUpdateBlockData = (newData: Partial<VideoBlockModel>) => {
      model.doc.updateBlock(model, newData);
    };
    this._reactRoot?.render(
      <UploadProvider>
        <Provider store={store}>
          <CustomThemeProvider>
            <VideoItem
              id={model.id}
              videoId={model.videoId || undefined}
              collectionId={model.doc.collection.id}
              onUpdateBlockData={handleUpdateBlockData}
              onDeleteBlock={handleDeleteBlock}
              posterSrc={this.model.posterSrc}
              disabledFastForward={this.model.disabledFastForward}
            />
          </CustomThemeProvider>
        </Provider>
      </UploadProvider>
    );
  }

  private createHoverController() {
    this._whenHover = new HoverController(this, () => {
      const selection = this.host.selection;
      const textSelection = selection.find('text');
      if (!!textSelection && (!!textSelection.to || !!textSelection.from.length)) {
        return null;
      }
      const blockSelections = selection.filter('block');
      if (
        blockSelections.length > 1 ||
        (blockSelections.length === 1 && blockSelections[0].blockId !== this.blockId)
      ) {
        return null;
      }

      return {
        template: this.model.videoId ? this.renderToolbar() : null,
        computePosition: {
          referenceElement: this,
          placement: 'top-start',
          autoUpdate: true,
        },
      };
    });
  }

  protected _whenHover: HoverController | null = null;

  private updateHoverState() {
    if (this._whenHover) {
      this._whenHover.abort();
      this._whenHover = null;
    }
    this.createHoverController();
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    this._reinitializeReactRoot();
  }

  renderBlock() {
    this.updateHoverState();
    return html`<div
      class="affine-block-component"
      ${this._whenHover ? ref(this._whenHover.setReference) : nothing}
    >
      <div
        class="affine-video-container"
        id="react-container-${this.model.id}"
        style="position: relative; z-index: 0;"
      ></div>
      <input
        id="thumbnail-upload"
        type="file"
        accept="image/*"
        style="display: none;"
        @change=${this.handleThumbnailUpload}
      />
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'workbase-embed-video-block': WorkbaseEmbedVideoBlock;
  }
}
