import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import AwarenessSlice from 'src/features/awareness-avatars/controller/awareness.slice';
import { resetPasswordApi, resendInvitationApi } from 'src/features/company-users/controller';
import { departmentsApi } from 'src/features/company-settings/departments/controller/departmentsApi';
import { departmentsSlice } from 'src/features/company-settings/departments/controller/departments.slice';
import companyUserSlice from 'src/features/company-users/controller/companyUserDetails.slice';
import { apiKeysApi } from 'src/store/api/apiKeysApi';
import { driveApi } from 'src/store/api/driveApi';
import { searchHistorySlice } from 'src/features/global-search/controller/search.slice';
import { wikiApi } from './api/wikiApi';
import { dashboardApi } from './api/dashboardApi';
import { trainingsApi } from './api/trainings/trainingsApi';
import { settingsApi } from './api/settingsApi';
import { rolesApi } from './api/roles.api';
import { trainingModulesApi } from './api/trainings/trainingModulesApi';
import { trainingSectionsApi } from './api/trainings/trainingSectionsApi';
import { trainingLessonsApi } from './api/trainings/trainingLessonsApi';
import { searchResourceApi } from './api/searchResourceApi';
import { shareResourceApi } from './api/shareResourceApi';
import { searchApi } from './api/searchApi';
import { userPermissionsApi } from './api/userPermissionsApi';
import { videoProgressApi } from './api/trainings/videoProgressApi';
import { videoRequiredApi } from './api/trainings/videoRequiredApi';
import { companyUsersApi } from './api/company-users/companyUsersApi';
import { pagesApi } from './api/pagesApi';
import { embeddedAppsApi } from './api/embeddedAppsApi';
import { newsfeedApi } from './api/newsfeedApi';
import UnauthorizedMiddlewareGuard from './middlewares/UnauthorizedMiddlewareGuard';
import { currentUserApi } from './api/currentUserApi';
import { iframeApi } from './api/iframeApi';
import { sidebarApi } from './api/sidebarApi';
import { themeSettingsSlice } from './client/themeSettingsSlice';
import { authSlice } from './client/authSlice';
import { authApi } from './api/authApi';
import aiChatApi from './api/aiChatApi';
import { dashboardSlice } from './client/dashboardSlice';
import { linksApi } from './api/linksApi';
import { videosApi } from './api/videos';
import { userPortalMiddleware, userPortalSlice } from './client/userPortalSlice';
import { permissionGroupsApi } from './api/permissionGroupsApi';
import { customerGroupsSlice } from 'src/features/company-settings/customer-groups/store/customerGroupsSlice';
import { customerGroupsApi } from './api/customerGroupsApi';
import { companiesApi } from './api/companiesApi';
import { uploadApi } from './api/uploadSlice';

const slices = {
  awareness: AwarenessSlice.reducer,
  auth: authSlice.reducer,
  dashboard: dashboardSlice.reducer,
  userPortal: userPortalSlice.reducer,
  customerGroups: customerGroupsSlice.reducer,
  departments: departmentsSlice.reducer,
  companyUser: companyUserSlice.reducer,
  searchHistory: searchHistorySlice.reducer,
  themeSettings: themeSettingsSlice.reducer,
} as const;

const apis = [
  aiChatApi,
  wikiApi,
  apiKeysApi,
  driveApi,
  currentUserApi,
  dashboardApi,
  trainingsApi,
  companyUsersApi,
  iframeApi,
  embeddedAppsApi,
  settingsApi,
  rolesApi,
  authApi,
  departmentsApi,
  permissionGroupsApi,
  trainingModulesApi,
  resetPasswordApi,
  resendInvitationApi,
  trainingSectionsApi,
  trainingLessonsApi,
  searchApi,
  searchResourceApi,
  shareResourceApi,
  userPermissionsApi,
  videoProgressApi,
  videoRequiredApi,
  pagesApi,
  newsfeedApi,
  sidebarApi,
  linksApi,
  videosApi,
  customerGroupsApi,
  companiesApi,
  uploadApi,
  trainingLessonsApi,
  trainingSectionsApi,
  trainingModulesApi,
] as const;

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  ...slices,
  ...Object.fromEntries(apis.map((api) => [api.reducerPath, api.reducer])),
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    });

    return defaultMiddleware.concat([
      ...apis.map((api) => api.middleware as any),
      UnauthorizedMiddlewareGuard as any,
      userPortalMiddleware.middleware as any,
    ]);
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
