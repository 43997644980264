// @ts-nocheck
/* eslint-disable */
/* This file was generated by Unframer for Framer project 71fd72204da79e7e "Workbase App", do not edit manually */
// /:https://framerusercontent.com/modules/gjdhdNvZaMVMW82oNOhJ/srNvS5WPgtfVlIoew3wT/m_ghCbbVn.js
import { fontStore, } from 'unframer';
fontStore.loadFonts(['CUSTOM;SanBase Medium',],);
var fonts = [{
  explicitInter: true,
  fonts: [{ family: 'SanBase Medium', source: 'custom', url: 'https://framerusercontent.com/assets/Anmn6ZKjuUjcfr07HEmCpUbPxdY.woff2', },],
},];
var css = [
  `.framer-zYrrI .framer-styles-preset-1c4zv3b:not(.rich-text-wrapper), .framer-zYrrI .framer-styles-preset-1c4zv3b.rich-text-wrapper p { --framer-font-family: "SanBase Medium", "SanBase Medium Placeholder", sans-serif; --framer-font-open-type-features: 'blwf' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv11' on; --framer-font-size: 19px; --framer-font-style: normal; --framer-font-variation-axes: normal; --framer-font-weight: 400; --framer-letter-spacing: 0px; --framer-line-height: 1em; --framer-paragraph-spacing: 19px; --framer-text-alignment: left; --framer-text-color: var(--token-ad22872e-8820-44b3-9dc3-0d1bf136ea27, #3f3f46); --framer-text-decoration: none; --framer-text-stroke-color: initial; --framer-text-stroke-width: initial; --framer-text-transform: none; }`,
  `@media (max-width: 1199px) and (min-width: 810px) { .framer-zYrrI .framer-styles-preset-1c4zv3b:not(.rich-text-wrapper), .framer-zYrrI .framer-styles-preset-1c4zv3b.rich-text-wrapper p { --framer-font-family: "SanBase Medium", "SanBase Medium Placeholder", sans-serif; --framer-font-family-bold: "Inter", "Inter Placeholder", sans-serif; --framer-font-family-bold-italic: "Inter", "Inter Placeholder", sans-serif; --framer-font-family-italic: "Inter", "Inter Placeholder", sans-serif; --framer-font-open-type-features: 'blwf' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv11' on; --framer-font-size: 19px; --framer-font-style: normal; --framer-font-style-bold: normal; --framer-font-style-bold-italic: italic; --framer-font-style-italic: italic; --framer-font-variation-axes: normal; --framer-font-weight: 400; --framer-font-weight-bold: 700; --framer-font-weight-bold-italic: 700; --framer-font-weight-italic: 400; --framer-letter-spacing: 0px; --framer-line-height: 26px; --framer-paragraph-spacing: 19px; --framer-text-alignment: left; --framer-text-color: var(--token-ad22872e-8820-44b3-9dc3-0d1bf136ea27, #3f3f46); --framer-text-decoration: none; --framer-text-stroke-color: initial; --framer-text-stroke-width: initial; --framer-text-transform: none; } }`,
  `@media (max-width: 809px) and (min-width: 0px) { .framer-zYrrI .framer-styles-preset-1c4zv3b:not(.rich-text-wrapper), .framer-zYrrI .framer-styles-preset-1c4zv3b.rich-text-wrapper p { --framer-font-family: "SanBase Medium", "SanBase Medium Placeholder", sans-serif; --framer-font-family-bold: "Inter", "Inter Placeholder", sans-serif; --framer-font-family-bold-italic: "Inter", "Inter Placeholder", sans-serif; --framer-font-family-italic: "Inter", "Inter Placeholder", sans-serif; --framer-font-open-type-features: 'blwf' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv11' on; --framer-font-size: 17px; --framer-font-style: normal; --framer-font-style-bold: normal; --framer-font-style-bold-italic: italic; --framer-font-style-italic: italic; --framer-font-variation-axes: normal; --framer-font-weight: 400; --framer-font-weight-bold: 700; --framer-font-weight-bold-italic: 700; --framer-font-weight-italic: 400; --framer-letter-spacing: 0px; --framer-line-height: 24px; --framer-paragraph-spacing: 17px; --framer-text-alignment: left; --framer-text-color: var(--token-ad22872e-8820-44b3-9dc3-0d1bf136ea27, #3f3f46); --framer-text-decoration: none; --framer-text-stroke-color: initial; --framer-text-stroke-width: initial; --framer-text-transform: none; } }`,
];
var className = 'framer-zYrrI';

export { className, css, fonts, };
