import { type DocMode, type DocModeProvider } from '@blocksuite/blocks';
import { Slot } from '@blocksuite/store';

const slotMap = new Map<string, Slot<DocMode>>();

export default function docModeService(
  getEditorModeCallback: () => DocMode,
  setEditorModeCallback: (mode: DocMode) => void
) {
  const docModeService: DocModeProvider = {
    getPrimaryMode: () => {
      return 'page';
    },
    onPrimaryModeChange: (handler: (mode: DocMode) => void, docId: string) => {
      if (!slotMap.get(docId)) {
        slotMap.set(docId, new Slot());
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return slotMap.get(docId)!.on(handler);
    },
    getEditorMode: () => {
      return getEditorModeCallback();
    },
    setEditorMode: (mode: DocMode) => {
      setEditorModeCallback(mode);
    },
    setPrimaryMode: (mode: DocMode, docId: string) => {
      slotMap.get(docId)?.emit(mode);
    },
    togglePrimaryMode: (docId: string) => {
      const mode = docModeService.getPrimaryMode(docId) === 'page' ? 'edgeless' : 'page';
      docModeService.setPrimaryMode(mode, docId);
      return mode;
    },
  };
  return docModeService;
}
