import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import AuthLayoutFramerComponent from '@framer/auth-layout';
import '@framer/styles.css';
import { allowedFileTypes } from 'src/constants/files';
import {
  FormButtonVariant,
  OnboardingFormData,
  Overlay,
  ONBOARDING_STEPS,
  OnboardingSteps,
} from '../../features/onboarding/types';
import { useOnboardingLogic } from 'src/features/onboarding/use-onboarding-logic';
import useThemeLogos from 'src/hooks/useThemeLogos';

export default function OnboardingView({ fixedScreen }: { fixedScreen?: OnboardingSteps }) {
  const [hiddenEmails, setHiddenEmails] = useState<boolean[]>([true, true]);
  const { favicon } = useThemeLogos();
  const addInviteEmail = useCallback(() => {
    const nextIndex = hiddenEmails.findIndex((email) => email);
    if (nextIndex !== -1) {
      const newHiddenEmails = [...hiddenEmails];
      newHiddenEmails[nextIndex] = false;
      setHiddenEmails(newHiddenEmails);
    }
  }, [hiddenEmails]);

  const {
    formData,
    buttonVariant,
    dragState,
    setFormData,
    currentVariantRef,
    currentVariant,
    setCurrentVariant,
    setButtonVariant,
    setDragState,
    loginWithGoogle,
    loginWithApple,
    validateEmailExists,
    handleProfilePhotoUpload,
    validateCode,
    validateCompanyStep,
    onLogoutUser,
    handleResendVerificationCode,
    handleInviteStep,
    handleProfileStep,
  } = useOnboardingLogic({ fixedScreen });

  useEffect(() => {
    if (fixedScreen) {
      setFormData((prev) => ({
        ...prev,
        companyLogo: {
          url: favicon.src,
        },
      }));
    }
  }, [fixedScreen, favicon.src, setFormData]);

  const verifySubmit = async (formData: OnboardingFormData, step: string): Promise<boolean> => {
    setButtonVariant(FormButtonVariant.LOADING);
    try {
      let isValid = false;
      switch (step) {
        case 'Create Account':
          break;
        case 'Create Account - Email Verification':
          isValid = await validateEmailExists(formData);
          break;
        case 'Create Account - Profile Data':
          isValid = await validateCode(formData);
          break;
        case 'Create Company - Name':
          isValid = await handleProfileStep(formData);
          break;
        case 'Create Company - Use-cases':
          isValid = await validateCompanyStep(formData);
          break;
        case 'Create Company - Use-Case: Work':
          isValid = true;
          break;
        case 'Create Compoany - About yourself':
          isValid = true;
          break;
        case 'Create Company - Invite':
          isValid = await handleInviteStep(formData);
          break;
        case 'Logged in - No Company':
          isValid = true;
          break;
        default:
          isValid = true;
      }

      if (isValid) {
        setFormData((prev) => ({
          ...prev,
          ...formData,
        }));
        return true;
      }
      return false;
    } catch (_error) {
      return false;
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setDragState(Overlay.NONE);
      if (acceptedFiles?.[0]) {
        handleProfilePhotoUpload(acceptedFiles[0]);
      }
    },
    [handleProfilePhotoUpload, setDragState]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDragEnter: () => setDragState(Overlay.DRAG_AND_DROP),
    onDragLeave: () => setDragState(Overlay.NONE),
    accept: allowedFileTypes.image,
    noClick: true,
    multiple: false,
    disabled:
      ONBOARDING_STEPS.indexOf(currentVariantRef.current as any) !== 2 &&
      ONBOARDING_STEPS.indexOf(currentVariantRef.current as any) !== 3,
  });

  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        '& > *': {
          width: '100% !important',
          height: '100% !important',
        },
      }}
      {...getRootProps()}
    >
      <input type="file" {...getInputProps()} />
      <AuthLayoutFramerComponent
        variant={fixedScreen ? fixedScreen : currentVariant}
        formButtonVariant={buttonVariant}
        profilePhoto={formData.profilePhoto?.url ? { src: formData.profilePhoto.url } : undefined}
        companyLogo={formData.companyLogo?.url ? { src: formData.companyLogo.url } : undefined}
        fullpageOverlay={dragState}
        userName={formData.name}
        loginWithGoogle={loginWithGoogle}
        loginWithApple={loginWithApple}
        logoutUser={onLogoutUser}
        workbaseName={formData.workspaceName}
        workbaseDomain={formData.workspaceDomain}
        inviteEmail4Hidden={hiddenEmails[0]}
        inviteEmail5Hidden={hiddenEmails[1]}
        addInviteEmail={addInviteEmail}
        email={formData.email}
        addPhotoOrLogo={open}
        formSubmit={async (newFormData: OnboardingFormData) => {
          const isValid = await verifySubmit(newFormData, newFormData.step);
          if (isValid) {
            setCurrentVariant(newFormData.step);
            setButtonVariant(FormButtonVariant.DEFAULT);
            currentVariantRef.current = newFormData.step;
          } else {
            setButtonVariant(FormButtonVariant.ERROR);
          }
        }}
        resendVerificationCode={handleResendVerificationCode}
      />
    </Box>
  );
}
