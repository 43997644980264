/* eslint-disable */
// @ts-nocheck

import stickerCover000 from './stickers/Arrows/Cover/arrow-1.svg';
import stickerContent000 from './stickers/Arrows/Content/arrow-1.svg';
import stickerCover001 from './stickers/Arrows/Cover/arrow-2.svg';
import stickerContent001 from './stickers/Arrows/Content/arrow-2.svg';
import stickerCover002 from './stickers/Arrows/Cover/arrow-3.svg';
import stickerContent002 from './stickers/Arrows/Content/arrow-3.svg';
import stickerCover003 from './stickers/Arrows/Cover/arrow-4.svg';
import stickerContent003 from './stickers/Arrows/Content/arrow-4.svg';
import stickerCover004 from './stickers/Arrows/Cover/arrow-5.svg';
import stickerContent004 from './stickers/Arrows/Content/arrow-5.svg';
import stickerCover005 from './stickers/Arrows/Cover/arrow-6.svg';
import stickerContent005 from './stickers/Arrows/Content/arrow-6.svg';
import stickerCover006 from './stickers/Arrows/Cover/arrow-7.svg';
import stickerContent006 from './stickers/Arrows/Content/arrow-7.svg';
import stickerCover007 from './stickers/Arrows/Cover/arrow-8.svg';
import stickerContent007 from './stickers/Arrows/Content/arrow-8.svg';
import stickerCover008 from './stickers/Arrows/Cover/arrow-9.svg';
import stickerContent008 from './stickers/Arrows/Content/arrow-9.svg';
import stickerCover009 from './stickers/Arrows/Cover/arrow-10.svg';
import stickerContent009 from './stickers/Arrows/Content/arrow-10.svg';
import stickerCover010 from './stickers/Arrows/Cover/arrow-11.svg';
import stickerContent010 from './stickers/Arrows/Content/arrow-11.svg';
import stickerCover011 from './stickers/Arrows/Cover/arrow-12.svg';
import stickerContent011 from './stickers/Arrows/Content/arrow-12.svg';
import stickerCover012 from './stickers/Arrows/Cover/arrow-13.svg';
import stickerContent012 from './stickers/Arrows/Content/arrow-13.svg';
import stickerCover013 from './stickers/Arrows/Cover/arrow-14.svg';
import stickerContent013 from './stickers/Arrows/Content/arrow-14.svg';
import stickerCover014 from './stickers/Arrows/Cover/arrow-15.svg';
import stickerContent014 from './stickers/Arrows/Content/arrow-15.svg';
import stickerCover015 from './stickers/Arrows/Cover/arrow-16.svg';
import stickerContent015 from './stickers/Arrows/Content/arrow-16.svg';
import stickerCover016 from './stickers/Arrows/Cover/arrow-17.svg';
import stickerContent016 from './stickers/Arrows/Content/arrow-17.svg';
import stickerCover017 from './stickers/Arrows/Cover/arrow-18.svg';
import stickerContent017 from './stickers/Arrows/Content/arrow-18.svg';
import stickerCover018 from './stickers/Arrows/Cover/arrow-19.svg';
import stickerContent018 from './stickers/Arrows/Content/arrow-19.svg';
import stickerCover019 from './stickers/Arrows/Cover/arrow-20.svg';
import stickerContent019 from './stickers/Arrows/Content/arrow-20.svg';
import stickerCover020 from './stickers/Arrows/Cover/arrow-21.svg';
import stickerContent020 from './stickers/Arrows/Content/arrow-21.svg';
import stickerCover021 from './stickers/Arrows/Cover/arrow-22.svg';
import stickerContent021 from './stickers/Arrows/Content/arrow-22.svg';
import stickerCover022 from './stickers/Arrows/Cover/arrow-23.svg';
import stickerContent022 from './stickers/Arrows/Content/arrow-23.svg';
import stickerCover023 from './stickers/Arrows/Cover/arrow-24.svg';
import stickerContent023 from './stickers/Arrows/Content/arrow-24.svg';
import stickerCover024 from './stickers/Arrows/Cover/arrow-25.svg';
import stickerContent024 from './stickers/Arrows/Content/arrow-25.svg';
import stickerCover025 from './stickers/Arrows/Cover/arrow-26.svg';
import stickerContent025 from './stickers/Arrows/Content/arrow-26.svg';
import stickerCover026 from './stickers/Arrows/Cover/arrow-27.svg';
import stickerContent026 from './stickers/Arrows/Content/arrow-27.svg';
import stickerCover027 from './stickers/Arrows/Cover/arrow-28.svg';
import stickerContent027 from './stickers/Arrows/Content/arrow-28.svg';
import stickerCover028 from './stickers/Arrows/Cover/arrow-29.svg';
import stickerContent028 from './stickers/Arrows/Content/arrow-29.svg';
import stickerCover029 from './stickers/Arrows/Cover/arrow-30.svg';
import stickerContent029 from './stickers/Arrows/Content/arrow-30.svg';
import stickerCover030 from './stickers/Arrows/Cover/arrow-31.svg';
import stickerContent030 from './stickers/Arrows/Content/arrow-31.svg';
import stickerCover031 from './stickers/Arrows/Cover/arrow-32.svg';
import stickerContent031 from './stickers/Arrows/Content/arrow-32.svg';
import stickerCover032 from './stickers/Arrows/Cover/arrow-33.svg';
import stickerContent032 from './stickers/Arrows/Content/arrow-33.svg';
import stickerCover033 from './stickers/Arrows/Cover/arrow-34.svg';
import stickerContent033 from './stickers/Arrows/Content/arrow-34.svg';
import stickerCover034 from './stickers/Arrows/Cover/arrow-35.svg';
import stickerContent034 from './stickers/Arrows/Content/arrow-35.svg';
import stickerCover035 from './stickers/Arrows/Cover/arrow-36.svg';
import stickerContent035 from './stickers/Arrows/Content/arrow-36.svg';
import stickerCover036 from './stickers/Arrows/Cover/arrow-37.svg';
import stickerContent036 from './stickers/Arrows/Content/arrow-37.svg';
import stickerCover037 from './stickers/Arrows/Cover/arrow-38.svg';
import stickerContent037 from './stickers/Arrows/Content/arrow-38.svg';
import stickerCover038 from './stickers/Arrows/Cover/arrow-39.svg';
import stickerContent038 from './stickers/Arrows/Content/arrow-39.svg';
import stickerCover039 from './stickers/Arrows/Cover/arrow-40.svg';
import stickerContent039 from './stickers/Arrows/Content/arrow-40.svg';
import stickerCover040 from './stickers/Arrows/Cover/arrow-41.svg';
import stickerContent040 from './stickers/Arrows/Content/arrow-41.svg';
import stickerCover041 from './stickers/Arrows/Cover/arrow-42.svg';
import stickerContent041 from './stickers/Arrows/Content/arrow-42.svg';
import stickerCover042 from './stickers/Arrows/Cover/arrow-43.svg';
import stickerContent042 from './stickers/Arrows/Content/arrow-43.svg';
import stickerCover043 from './stickers/Arrows/Cover/arrow-44.svg';
import stickerContent043 from './stickers/Arrows/Content/arrow-44.svg';
import stickerCover044 from './stickers/Arrows/Cover/arrow-45.svg';
import stickerContent044 from './stickers/Arrows/Content/arrow-45.svg';
import stickerCover045 from './stickers/Arrows/Cover/arrow-46.svg';
import stickerContent045 from './stickers/Arrows/Content/arrow-46.svg';
import stickerCover046 from './stickers/Arrows/Cover/arrow-47.svg';
import stickerContent046 from './stickers/Arrows/Content/arrow-47.svg';
import stickerCover047 from './stickers/Arrows/Cover/arrow-48.svg';
import stickerContent047 from './stickers/Arrows/Content/arrow-48.svg';
import stickerCover048 from './stickers/Arrows/Cover/arrow-49.svg';
import stickerContent048 from './stickers/Arrows/Content/arrow-49.svg';
import stickerCover049 from './stickers/Arrows/Cover/arrow-50.svg';
import stickerContent049 from './stickers/Arrows/Content/arrow-50.svg';
import stickerCover050 from './stickers/Arrows/Cover/arrow-51.svg';
import stickerContent050 from './stickers/Arrows/Content/arrow-51.svg';
import stickerCover051 from './stickers/Arrows/Cover/arrow-52.svg';
import stickerContent051 from './stickers/Arrows/Content/arrow-52.svg';
import stickerCover052 from './stickers/Arrows/Cover/arrow-53.svg';
import stickerContent052 from './stickers/Arrows/Content/arrow-53.svg';
import stickerCover053 from './stickers/Arrows/Cover/arrow-54.svg';
import stickerContent053 from './stickers/Arrows/Content/arrow-54.svg';
import stickerCover054 from './stickers/Arrows/Cover/arrow-55.svg';
import stickerContent054 from './stickers/Arrows/Content/arrow-55.svg';
import stickerCover055 from './stickers/Arrows/Cover/arrow-56.svg';
import stickerContent055 from './stickers/Arrows/Content/arrow-56.svg';
import stickerCover056 from './stickers/Arrows/Cover/arrow-57.svg';
import stickerContent056 from './stickers/Arrows/Content/arrow-57.svg';
import stickerCover057 from './stickers/Arrows/Cover/arrow-58.svg';
import stickerContent057 from './stickers/Arrows/Content/arrow-58.svg';
import stickerCover058 from './stickers/Arrows/Cover/arrow-59.svg';
import stickerContent058 from './stickers/Arrows/Content/arrow-59.svg';
import stickerCover059 from './stickers/Arrows/Cover/arrow-60.svg';
import stickerContent059 from './stickers/Arrows/Content/arrow-60.svg';
import stickerCover060 from './stickers/Arrows/Cover/arrow-61.svg';
import stickerContent060 from './stickers/Arrows/Content/arrow-61.svg';
import stickerCover061 from './stickers/Arrows/Cover/arrow-62.svg';
import stickerContent061 from './stickers/Arrows/Content/arrow-62.svg';
import stickerCover062 from './stickers/Arrows/Cover/arrow-63.svg';
import stickerContent062 from './stickers/Arrows/Content/arrow-63.svg';
import stickerCover063 from './stickers/Arrows/Cover/arrow-64.svg';
import stickerContent063 from './stickers/Arrows/Content/arrow-64.svg';
import stickerCover064 from './stickers/Arrows/Cover/arrow-65.svg';
import stickerContent064 from './stickers/Arrows/Content/arrow-65.svg';
import stickerCover065 from './stickers/Arrows/Cover/arrow-66.svg';
import stickerContent065 from './stickers/Arrows/Content/arrow-66.svg';
import stickerCover066 from './stickers/Arrows/Cover/arrow-67.svg';
import stickerContent066 from './stickers/Arrows/Content/arrow-67.svg';
import stickerCover067 from './stickers/Arrows/Cover/arrow-68.svg';
import stickerContent067 from './stickers/Arrows/Content/arrow-68.svg';
import stickerCover068 from './stickers/Arrows/Cover/arrow-69.svg';
import stickerContent068 from './stickers/Arrows/Content/arrow-69.svg';
import stickerCover069 from './stickers/Arrows/Cover/arrow-70.svg';
import stickerContent069 from './stickers/Arrows/Content/arrow-70.svg';
import stickerCover070 from './stickers/Arrows/Cover/arrow-71.svg';
import stickerContent070 from './stickers/Arrows/Content/arrow-71.svg';
import stickerCover071 from './stickers/Arrows/Cover/arrow-72.svg';
import stickerContent071 from './stickers/Arrows/Content/arrow-72.svg';
import stickerCover072 from './stickers/Arrows/Cover/arrow-73.svg';
import stickerContent072 from './stickers/Arrows/Content/arrow-73.svg';
import stickerCover073 from './stickers/Arrows/Cover/arrow-74.svg';
import stickerContent073 from './stickers/Arrows/Content/arrow-74.svg';
import stickerCover074 from './stickers/Arrows/Cover/arrow-75.svg';
import stickerContent074 from './stickers/Arrows/Content/arrow-75.svg';
import stickerCover075 from './stickers/Arrows/Cover/arrow-76.svg';
import stickerContent075 from './stickers/Arrows/Content/arrow-76.svg';
import stickerCover076 from './stickers/Arrows/Cover/arrow-77.svg';
import stickerContent076 from './stickers/Arrows/Content/arrow-77.svg';
import stickerCover077 from './stickers/Arrows/Cover/arrow-78.svg';
import stickerContent077 from './stickers/Arrows/Content/arrow-78.svg';
import stickerCover078 from './stickers/Arrows/Cover/arrow-79.svg';
import stickerContent078 from './stickers/Arrows/Content/arrow-79.svg';
import stickerCover079 from './stickers/Arrows/Cover/arrow-80.svg';
import stickerContent079 from './stickers/Arrows/Content/arrow-80.svg';
import stickerCover080 from './stickers/Arrows/Cover/arrow-81.svg';
import stickerContent080 from './stickers/Arrows/Content/arrow-81.svg';
import stickerCover081 from './stickers/Arrows/Cover/arrow-82.svg';
import stickerContent081 from './stickers/Arrows/Content/arrow-82.svg';
import stickerCover082 from './stickers/Arrows/Cover/arrow-83.svg';
import stickerContent082 from './stickers/Arrows/Content/arrow-83.svg';
import stickerCover083 from './stickers/Arrows/Cover/arrow-84.svg';
import stickerContent083 from './stickers/Arrows/Content/arrow-84.svg';
import stickerCover084 from './stickers/Arrows/Cover/arrow-85.svg';
import stickerContent084 from './stickers/Arrows/Content/arrow-85.svg';
import stickerCover085 from './stickers/Arrows/Cover/arrow-86.svg';
import stickerContent085 from './stickers/Arrows/Content/arrow-86.svg';
import stickerCover086 from './stickers/Arrows/Cover/arrow-87.svg';
import stickerContent086 from './stickers/Arrows/Content/arrow-87.svg';
import stickerCover087 from './stickers/Arrows/Cover/arrow-88.svg';
import stickerContent087 from './stickers/Arrows/Content/arrow-88.svg';
import stickerCover088 from './stickers/Arrows/Cover/arrow-89.svg';
import stickerContent088 from './stickers/Arrows/Content/arrow-89.svg';
import stickerCover089 from './stickers/Arrows/Cover/arrow-90.svg';
import stickerContent089 from './stickers/Arrows/Content/arrow-90.svg';
import stickerCover090 from './stickers/Arrows/Cover/arrow-91.svg';
import stickerContent090 from './stickers/Arrows/Content/arrow-91.svg';
import stickerCover091 from './stickers/Arrows/Cover/arrow-92.svg';
import stickerContent091 from './stickers/Arrows/Content/arrow-92.svg';
import stickerCover092 from './stickers/Arrows/Cover/arrow-93.svg';
import stickerContent092 from './stickers/Arrows/Content/arrow-93.svg';
import stickerCover093 from './stickers/Arrows/Cover/arrow-94.svg';
import stickerContent093 from './stickers/Arrows/Content/arrow-94.svg';
import stickerCover094 from './stickers/Arrows/Cover/arrow-95.svg';
import stickerContent094 from './stickers/Arrows/Content/arrow-95.svg';
import stickerCover095 from './stickers/Arrows/Cover/arrow-96.svg';
import stickerContent095 from './stickers/Arrows/Content/arrow-96.svg';
import stickerCover096 from './stickers/Arrows/Cover/arrow-97.svg';
import stickerContent096 from './stickers/Arrows/Content/arrow-97.svg';
import stickerCover097 from './stickers/Arrows/Cover/arrow-98.svg';
import stickerContent097 from './stickers/Arrows/Content/arrow-98.svg';
import stickerCover098 from './stickers/Arrows/Cover/arrow-99.svg';
import stickerContent098 from './stickers/Arrows/Content/arrow-99.svg';
import stickerCover099 from './stickers/Arrows/Cover/arrow-100.svg';
import stickerContent099 from './stickers/Arrows/Content/arrow-100.svg';
import stickerCover100 from './stickers/Arrows/Cover/arrow-101.svg';
import stickerContent100 from './stickers/Arrows/Content/arrow-101.svg';
import stickerCover101 from './stickers/Arrows/Cover/arrow-102.svg';
import stickerContent101 from './stickers/Arrows/Content/arrow-102.svg';
import stickerCover102 from './stickers/Arrows/Cover/arrow-103.svg';
import stickerContent102 from './stickers/Arrows/Content/arrow-103.svg';
import stickerCover103 from './stickers/Arrows/Cover/arrow-104.svg';
import stickerContent103 from './stickers/Arrows/Content/arrow-104.svg';
import stickerCover104 from './stickers/Arrows/Cover/arrow-105.svg';
import stickerContent104 from './stickers/Arrows/Content/arrow-105.svg';
import stickerCover105 from './stickers/Arrows/Cover/arrow-106.svg';
import stickerContent105 from './stickers/Arrows/Content/arrow-106.svg';
import stickerCover106 from './stickers/Arrows/Cover/arrow-107.svg';
import stickerContent106 from './stickers/Arrows/Content/arrow-107.svg';
import stickerCover107 from './stickers/Arrows/Cover/arrow-108.svg';
import stickerContent107 from './stickers/Arrows/Content/arrow-108.svg';
import stickerCover108 from './stickers/Arrows/Cover/arrow-109.svg';
import stickerContent108 from './stickers/Arrows/Content/arrow-109.svg';
import stickerCover109 from './stickers/Arrows/Cover/arrow-110.svg';
import stickerContent109 from './stickers/Arrows/Content/arrow-110.svg';
import stickerCover110 from './stickers/Arrows/Cover/arrow-111.svg';
import stickerContent110 from './stickers/Arrows/Content/arrow-111.svg';
import stickerCover111 from './stickers/Arrows/Cover/arrow-112.svg';
import stickerContent111 from './stickers/Arrows/Content/arrow-112.svg';
import stickerCover112 from './stickers/Arrows/Cover/arrow-113.svg';
import stickerContent112 from './stickers/Arrows/Content/arrow-113.svg';
import stickerCover113 from './stickers/Arrows/Cover/arrow-114.svg';
import stickerContent113 from './stickers/Arrows/Content/arrow-114.svg';
import stickerCover114 from './stickers/Arrows/Cover/arrow-115.svg';
import stickerContent114 from './stickers/Arrows/Content/arrow-115.svg';
import stickerCover115 from './stickers/Arrows/Cover/arrow-116.svg';
import stickerContent115 from './stickers/Arrows/Content/arrow-116.svg';
import stickerCover116 from './stickers/Arrows/Cover/arrow-117.svg';
import stickerContent116 from './stickers/Arrows/Content/arrow-117.svg';
import stickerCover117 from './stickers/Arrows/Cover/arrow-118.svg';
import stickerContent117 from './stickers/Arrows/Content/arrow-118.svg';
import stickerCover118 from './stickers/Arrows/Cover/arrow-119.svg';
import stickerContent118 from './stickers/Arrows/Content/arrow-119.svg';
import stickerCover119 from './stickers/Arrows/Cover/arrow-120.svg';
import stickerContent119 from './stickers/Arrows/Content/arrow-120.svg';
import stickerCover120 from './stickers/Arrows/Cover/arrow-121.svg';
import stickerContent120 from './stickers/Arrows/Content/arrow-121.svg';
import stickerCover121 from './stickers/Arrows/Cover/arrow-122.svg';
import stickerContent121 from './stickers/Arrows/Content/arrow-122.svg';
import stickerCover122 from './stickers/Arrows/Cover/arrow-123.svg';
import stickerContent122 from './stickers/Arrows/Content/arrow-123.svg';
import stickerCover123 from './stickers/Arrows/Cover/arrow-124.svg';
import stickerContent123 from './stickers/Arrows/Content/arrow-124.svg';
import stickerCover124 from './stickers/Arrows/Cover/arrow-125.svg';
import stickerContent124 from './stickers/Arrows/Content/arrow-125.svg';
import stickerCover125 from './stickers/Arrows/Cover/arrow-126.svg';
import stickerContent125 from './stickers/Arrows/Content/arrow-126.svg';
import stickerCover126 from './stickers/Arrows/Cover/arrow-127.svg';
import stickerContent126 from './stickers/Arrows/Content/arrow-127.svg';
import stickerCover127 from './stickers/Arrows/Cover/arrow-128.svg';
import stickerContent127 from './stickers/Arrows/Content/arrow-128.svg';
import stickerCover128 from './stickers/Arrows/Cover/arrow-129.svg';
import stickerContent128 from './stickers/Arrows/Content/arrow-129.svg';
import stickerCover129 from './stickers/Arrows/Cover/arrow-130.svg';
import stickerContent129 from './stickers/Arrows/Content/arrow-130.svg';
import stickerCover130 from './stickers/Arrows/Cover/arrow-131.svg';
import stickerContent130 from './stickers/Arrows/Content/arrow-131.svg';
import stickerCover131 from './stickers/Arrows/Cover/arrow-132.svg';
import stickerContent131 from './stickers/Arrows/Content/arrow-132.svg';
import stickerCover132 from './stickers/Arrows/Cover/arrow-133.svg';
import stickerContent132 from './stickers/Arrows/Content/arrow-133.svg';
import stickerCover133 from './stickers/Arrows/Cover/arrow-134.svg';
import stickerContent133 from './stickers/Arrows/Content/arrow-134.svg';
import stickerCover134 from './stickers/Arrows/Cover/arrow-135.svg';
import stickerContent134 from './stickers/Arrows/Content/arrow-135.svg';
import stickerCover135 from './stickers/Arrows/Cover/arrow-136.svg';
import stickerContent135 from './stickers/Arrows/Content/arrow-136.svg';
import stickerCover136 from './stickers/Arrows/Cover/arrow-137.svg';
import stickerContent136 from './stickers/Arrows/Content/arrow-137.svg';
import stickerCover137 from './stickers/Arrows/Cover/arrow-138.svg';
import stickerContent137 from './stickers/Arrows/Content/arrow-138.svg';
import stickerCover138 from './stickers/Arrows/Cover/arrow-139.svg';
import stickerContent138 from './stickers/Arrows/Content/arrow-139.svg';
import stickerCover139 from './stickers/Arrows/Cover/arrow-140.svg';
import stickerContent139 from './stickers/Arrows/Content/arrow-140.svg';
import stickerCover140 from './stickers/Arrows/Cover/arrow-141.svg';
import stickerContent140 from './stickers/Arrows/Content/arrow-141.svg';
import stickerCover141 from './stickers/Arrows/Cover/arrow-142.svg';
import stickerContent141 from './stickers/Arrows/Content/arrow-142.svg';
import stickerCover142 from './stickers/Arrows/Cover/arrow-143.svg';
import stickerContent142 from './stickers/Arrows/Content/arrow-143.svg';
import stickerCover143 from './stickers/Arrows/Cover/arrow-144.svg';
import stickerContent143 from './stickers/Arrows/Content/arrow-144.svg';
import stickerCover144 from './stickers/Arrows/Cover/arrow-145.svg';
import stickerContent144 from './stickers/Arrows/Content/arrow-145.svg';
import stickerCover145 from './stickers/Arrows/Cover/arrow-146.svg';
import stickerContent145 from './stickers/Arrows/Content/arrow-146.svg';
import stickerCover146 from './stickers/Arrows/Cover/arrow-147.svg';
import stickerContent146 from './stickers/Arrows/Content/arrow-147.svg';
import stickerCover147 from './stickers/Arrows/Cover/arrow-148.svg';
import stickerContent147 from './stickers/Arrows/Content/arrow-148.svg';
import stickerCover148 from './stickers/Arrows/Cover/arrow-149.svg';
import stickerContent148 from './stickers/Arrows/Content/arrow-149.svg';
import stickerCover149 from './stickers/Arrows/Cover/arrow-150.svg';
import stickerContent149 from './stickers/Arrows/Content/arrow-150.svg';
import stickerCover150 from './stickers/Arrows/Cover/arrow-151.svg';
import stickerContent150 from './stickers/Arrows/Content/arrow-151.svg';
import stickerCover151 from './stickers/Arrows/Cover/arrow-152.svg';
import stickerContent151 from './stickers/Arrows/Content/arrow-152.svg';
import stickerCover152 from './stickers/Arrows/Cover/arrow-153.svg';
import stickerContent152 from './stickers/Arrows/Content/arrow-153.svg';
import stickerCover153 from './stickers/Arrows/Cover/arrow-154.svg';
import stickerContent153 from './stickers/Arrows/Content/arrow-154.svg';
import stickerCover154 from './stickers/Arrows/Cover/arrow-155.svg';
import stickerContent154 from './stickers/Arrows/Content/arrow-155.svg';
import stickerCover155 from './stickers/Arrows/Cover/arrow-156.svg';
import stickerContent155 from './stickers/Arrows/Content/arrow-156.svg';
import stickerCover156 from './stickers/Arrows/Cover/arrow-157.svg';
import stickerContent156 from './stickers/Arrows/Content/arrow-157.svg';
import stickerCover157 from './stickers/Arrows/Cover/arrow-158.svg';
import stickerContent157 from './stickers/Arrows/Content/arrow-158.svg';
import stickerCover158 from './stickers/Arrows/Cover/arrow-159.svg';
import stickerContent158 from './stickers/Arrows/Content/arrow-159.svg';
import stickerCover159 from './stickers/Arrows/Cover/arrow-160.svg';
import stickerContent159 from './stickers/Arrows/Content/arrow-160.svg';
import stickerCover160 from './stickers/Arrows/Cover/arrow-161.svg';
import stickerContent160 from './stickers/Arrows/Content/arrow-161.svg';
import stickerCover161 from './stickers/Arrows/Cover/arrow-162.svg';
import stickerContent161 from './stickers/Arrows/Content/arrow-162.svg';
import stickerCover162 from './stickers/Arrows/Cover/arrow-163.svg';
import stickerContent162 from './stickers/Arrows/Content/arrow-163.svg';
import stickerCover163 from './stickers/Arrows/Cover/arrow-164.svg';
import stickerContent163 from './stickers/Arrows/Content/arrow-164.svg';
import stickerCover164 from './stickers/Arrows/Cover/arrow-165.svg';
import stickerContent164 from './stickers/Arrows/Content/arrow-165.svg';
import stickerCover165 from './stickers/Arrows/Cover/arrow-166.svg';
import stickerContent165 from './stickers/Arrows/Content/arrow-166.svg';
import stickerCover166 from './stickers/Arrows/Cover/arrow-167.svg';
import stickerContent166 from './stickers/Arrows/Content/arrow-167.svg';
import stickerCover167 from './stickers/Arrows/Cover/arrow-168.svg';
import stickerContent167 from './stickers/Arrows/Content/arrow-168.svg';
import stickerCover168 from './stickers/Arrows/Cover/arrow-169.svg';
import stickerContent168 from './stickers/Arrows/Content/arrow-169.svg';
import stickerCover169 from './stickers/Arrows/Cover/arrow-170.svg';
import stickerContent169 from './stickers/Arrows/Content/arrow-170.svg';
import stickerCover170 from './stickers/Arrows/Cover/arrow-171.svg';
import stickerContent170 from './stickers/Arrows/Content/arrow-171.svg';
import stickerCover171 from './stickers/Arrows/Cover/arrow-172.svg';
import stickerContent171 from './stickers/Arrows/Content/arrow-172.svg';
import stickerCover172 from './stickers/Arrows/Cover/arrow-173.svg';
import stickerContent172 from './stickers/Arrows/Content/arrow-173.svg';
import stickerCover173 from './stickers/Arrows/Cover/arrow-174.svg';
import stickerContent173 from './stickers/Arrows/Content/arrow-174.svg';
import stickerCover174 from './stickers/Arrows/Cover/arrow-175.svg';
import stickerContent174 from './stickers/Arrows/Content/arrow-175.svg';
import stickerCover175 from './stickers/Arrows/Cover/arrow-176.svg';
import stickerContent175 from './stickers/Arrows/Content/arrow-176.svg';
import stickerCover176 from './stickers/Arrows/Cover/arrow-177.svg';
import stickerContent176 from './stickers/Arrows/Content/arrow-177.svg';
import stickerCover177 from './stickers/Arrows/Cover/arrow-178.svg';
import stickerContent177 from './stickers/Arrows/Content/arrow-178.svg';
import stickerCover178 from './stickers/Arrows/Cover/arrow-179.svg';
import stickerContent178 from './stickers/Arrows/Content/arrow-179.svg';
import stickerCover179 from './stickers/Arrows/Cover/arrow-180.svg';
import stickerContent179 from './stickers/Arrows/Content/arrow-180.svg';
import stickerCover180 from './stickers/Arrows/Cover/arrow-181.svg';
import stickerContent180 from './stickers/Arrows/Content/arrow-181.svg';
import stickerCover181 from './stickers/Arrows/Cover/arrow-182.svg';
import stickerContent181 from './stickers/Arrows/Content/arrow-182.svg';
import stickerCover182 from './stickers/Arrows/Cover/arrow-183.svg';
import stickerContent182 from './stickers/Arrows/Content/arrow-183.svg';
import stickerCover183 from './stickers/Arrows/Cover/arrow-184.svg';
import stickerContent183 from './stickers/Arrows/Content/arrow-184.svg';
import stickerCover184 from './stickers/Arrows/Cover/arrow-185.svg';
import stickerContent184 from './stickers/Arrows/Content/arrow-185.svg';
import stickerCover186 from './stickers/Cheeky Piggies/Cover/Crybaby.svg';
import stickerContent186 from './stickers/Cheeky Piggies/Content/Crybaby.svg';
import stickerCover187 from './stickers/Cheeky Piggies/Cover/Drool.svg';
import stickerContent187 from './stickers/Cheeky Piggies/Content/Drool.svg';
import stickerCover188 from './stickers/Cheeky Piggies/Cover/Fuming.svg';
import stickerContent188 from './stickers/Cheeky Piggies/Content/Fuming.svg';
import stickerCover189 from './stickers/Cheeky Piggies/Cover/Hi~.svg';
import stickerContent189 from './stickers/Cheeky Piggies/Content/Hi~.svg';
import stickerCover190 from './stickers/Cheeky Piggies/Cover/Holding Tears.svg';
import stickerContent190 from './stickers/Cheeky Piggies/Content/Holding Tears.svg';
import stickerCover191 from './stickers/Cheeky Piggies/Cover/Love Blows.svg';
import stickerContent191 from './stickers/Cheeky Piggies/Content/Love Blows.svg';
import stickerCover192 from './stickers/Cheeky Piggies/Cover/Me_ Really_.svg';
import stickerContent192 from './stickers/Cheeky Piggies/Content/Me_ Really_.svg';
import stickerCover193 from './stickers/Cheeky Piggies/Cover/OK.svg';
import stickerContent193 from './stickers/Cheeky Piggies/Content/OK.svg';
import stickerCover194 from './stickers/Cheeky Piggies/Cover/Sassy Flick.svg';
import stickerContent194 from './stickers/Cheeky Piggies/Content/Sassy Flick.svg';
import stickerCover195 from './stickers/Cheeky Piggies/Cover/Shockwave.svg';
import stickerContent195 from './stickers/Cheeky Piggies/Content/Shockwave.svg';
import stickerCover196 from './stickers/Cheeky Piggies/Cover/Snooze Drool.svg';
import stickerContent196 from './stickers/Cheeky Piggies/Content/Snooze Drool.svg';
import stickerCover197 from './stickers/Cheeky Piggies/Cover/Swag.svg';
import stickerContent197 from './stickers/Cheeky Piggies/Content/Swag.svg';
import stickerCover198 from './stickers/Cheeky Piggies/Cover/Sweatdrop.svg';
import stickerContent198 from './stickers/Cheeky Piggies/Content/Sweatdrop.svg';
import stickerCover199 from './stickers/Cheeky Piggies/Cover/Thumbs Up.svg';
import stickerContent199 from './stickers/Cheeky Piggies/Content/Thumbs Up.svg';
import stickerCover200 from './stickers/Cheeky Piggies/Cover/What_.svg';
import stickerContent200 from './stickers/Cheeky Piggies/Content/What_.svg';
import stickerCover202 from './stickers/Contorted Stickers/Cover/AI.svg';
import stickerContent202 from './stickers/Contorted Stickers/Content/AI.svg';
import stickerCover203 from './stickers/Contorted Stickers/Cover/Cat.svg';
import stickerContent203 from './stickers/Contorted Stickers/Content/Cat.svg';
import stickerCover204 from './stickers/Contorted Stickers/Cover/Closed.svg';
import stickerContent204 from './stickers/Contorted Stickers/Content/Closed.svg';
import stickerCover205 from './stickers/Contorted Stickers/Cover/Eyes.svg';
import stickerContent205 from './stickers/Contorted Stickers/Content/Eyes.svg';
import stickerCover206 from './stickers/Contorted Stickers/Cover/Fire.svg';
import stickerContent206 from './stickers/Contorted Stickers/Content/Fire.svg';
import stickerCover207 from './stickers/Contorted Stickers/Cover/Info.svg';
import stickerContent207 from './stickers/Contorted Stickers/Content/Info.svg';
import stickerCover208 from './stickers/Contorted Stickers/Cover/King.svg';
import stickerContent208 from './stickers/Contorted Stickers/Content/King.svg';
import stickerCover209 from './stickers/Contorted Stickers/Cover/Love Face.svg';
import stickerContent209 from './stickers/Contorted Stickers/Content/Love Face.svg';
import stickerCover210 from './stickers/Contorted Stickers/Cover/Love.svg';
import stickerContent210 from './stickers/Contorted Stickers/Content/Love.svg';
import stickerCover211 from './stickers/Contorted Stickers/Cover/Notice.svg';
import stickerContent211 from './stickers/Contorted Stickers/Content/Notice.svg';
import stickerCover212 from './stickers/Contorted Stickers/Cover/Pin.svg';
import stickerContent212 from './stickers/Contorted Stickers/Content/Pin.svg';
import stickerCover213 from './stickers/Contorted Stickers/Cover/Question.svg';
import stickerContent213 from './stickers/Contorted Stickers/Content/Question.svg';
import stickerCover214 from './stickers/Contorted Stickers/Cover/Smile Face.svg';
import stickerContent214 from './stickers/Contorted Stickers/Content/Smile Face.svg';
import stickerCover215 from './stickers/Contorted Stickers/Cover/Stop.svg';
import stickerContent215 from './stickers/Contorted Stickers/Content/Stop.svg';
import stickerCover216 from './stickers/Paper/Cover/+1.svg';
import stickerContent216 from './stickers/Paper/Content/+1.svg';
import stickerCover217 from './stickers/Paper/Cover/A lot of question.svg';
import stickerContent217 from './stickers/Paper/Content/A lot of question.svg';
import stickerCover219 from './stickers/Paper/Cover/Arrow.svg';
import stickerContent219 from './stickers/Paper/Content/Arrow.svg';
import stickerCover220 from './stickers/Paper/Cover/Atention.svg';
import stickerContent220 from './stickers/Paper/Content/Atention.svg';
import stickerCover221 from './stickers/Paper/Cover/Blue Screen.svg';
import stickerContent221 from './stickers/Paper/Content/Blue Screen.svg';
import stickerCover222 from './stickers/Paper/Cover/Boom.svg';
import stickerContent222 from './stickers/Paper/Content/Boom.svg';
import stickerCover223 from './stickers/Paper/Cover/Cool.svg';
import stickerContent223 from './stickers/Paper/Content/Cool.svg';
import stickerCover224 from './stickers/Paper/Cover/Dino.svg';
import stickerContent224 from './stickers/Paper/Content/Dino.svg';
import stickerCover225 from './stickers/Paper/Cover/Histogram.svg';
import stickerContent225 from './stickers/Paper/Content/Histogram.svg';
import stickerCover226 from './stickers/Paper/Cover/Local First.svg';
import stickerContent226 from './stickers/Paper/Content/Local First.svg';
import stickerCover227 from './stickers/Paper/Cover/Medal.svg';
import stickerContent227 from './stickers/Paper/Content/Medal.svg';
import stickerCover228 from './stickers/Paper/Cover/Notice.svg';
import stickerContent228 from './stickers/Paper/Content/Notice.svg';
import stickerCover229 from './stickers/Paper/Cover/Pin.svg';
import stickerContent229 from './stickers/Paper/Content/Pin.svg';
import stickerCover230 from './stickers/Paper/Cover/Star.svg';
import stickerContent230 from './stickers/Paper/Content/Star.svg';

function buildStickerTemplate(data) {
  return {
    name: data.name,
    preview: data.cover,
    type: 'sticker',
    assets: {
      [data.hash]: data.content,
    },
    content: {
      type: 'page',
      meta: {
        id: 'doc:home',
        title: 'Sticker',
        createDate: 1701765881935,
        tags: [],
      },
      blocks: {
        type: 'block',
        id: 'block:1VxnfD_8xb',
        flavour: 'affine:page',
        props: {
          title: {
            '$blocksuite:internal:text$': true,
            delta: [
              {
                insert: 'Sticker',
              },
            ],
          },
        },
        children: [
          {
            type: 'block',
            id: 'block:pcmYJQ63hX',
            flavour: 'affine:surface',
            props: {
              elements: {},
            },
            children: [
              {
                type: 'block',
                id: 'block:N24al1Qgl7',
                flavour: 'affine:image',
                props: {
                  caption: '',
                  sourceId: data.hash,
                  width: 0,
                  height: 0,
                  index: 'b0D',
                  xywh: '[0,0,460,430]',
                  rotate: 0,
                },
                children: [],
              },
            ],
          },
        ],
      },
    },
  };
}

function lcs(text1: string, text2: string) {
  const dp: number[][] = Array.from({ length: text1.length + 1 })
    .fill(null)
    .map(() => Array.from<number>({ length: text2.length + 1 }).fill(0));

  for (let i = 1; i <= text1.length; i++) {
    for (let j = 1; j <= text2.length; j++) {
      if (text1[i - 1] === text2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
      } else {
        dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1]);
      }
    }
  }

  return dp[text1.length][text2.length];
}

const templates = {
  Arrows: [
    buildStickerTemplate({
      name: 'Arrow 1',
      cover: stickerCover000,
      content: stickerContent000,
      hash: 'YaaYGysUizxrn-koKS9MlYXg2R2zpQecoFx08jya0aQ=',
    }),
    buildStickerTemplate({
      name: 'Arrow 2',
      cover: stickerCover001,
      content: stickerContent001,
      hash: 'T3QfBMbD4gWhI6lL4TRNe5FMat02T2YBvPe-Rm4FkrY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 3',
      cover: stickerCover002,
      content: stickerContent002,
      hash: 'zfqg42rHf9B8I74XfxaZ4-mXnmFlwX9SZk47AN-MuY8=',
    }),
    buildStickerTemplate({
      name: 'Arrow 4',
      cover: stickerCover003,
      content: stickerContent003,
      hash: 'OYOorxDDTlC73QmhkhpxYlpCl00nZGG_MY3fCaChAyw=',
    }),
    buildStickerTemplate({
      name: 'Arrow 5',
      cover: stickerCover004,
      content: stickerContent004,
      hash: 'lVpqGZ4JOJLnF8Lj6LUr3HXt8Uzg14HUKTdy61mA06E=',
    }),
    buildStickerTemplate({
      name: 'Arrow 6',
      cover: stickerCover005,
      content: stickerContent005,
      hash: 'YQvXGmyQ5CMoeQOqiICDhRlPMx4GxJbK1s1VcCgWJXg=',
    }),
    buildStickerTemplate({
      name: 'Arrow 7',
      cover: stickerCover006,
      content: stickerContent006,
      hash: 'W0soM0fGEdNGDj8Aes2l2XpXfC8ib-8zpCw8HbEMcsI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 8',
      cover: stickerCover007,
      content: stickerContent007,
      hash: 'UU1vpJSy17AzURffXHIfQbkNPQ8nH5_Z60xTYUy2ifI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 9',
      cover: stickerCover008,
      content: stickerContent008,
      hash: 'SrCFM2aHAyR6-oBEqHdhiflZVmVL2zt-rDbgLtANvAM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 10',
      cover: stickerCover009,
      content: stickerContent009,
      hash: 'lehO7Xs0UKt0MR8RyZ6kV74M_zCy0GgrTSiOD7oNRmE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 11',
      cover: stickerCover010,
      content: stickerContent010,
      hash: '_dXUvx5tTcm4IykbislTxwNoSLJ4g3oqmd7A9x4ONdY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 12',
      cover: stickerCover011,
      content: stickerContent011,
      hash: 'cwaReNMJ3a7-qEjDWyiqV-BZB3hH7yJmm7xTRyEQktM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 13',
      cover: stickerCover012,
      content: stickerContent012,
      hash: 'OZS78ZRp59njtnYy4EMPrDJRBaW7GHCHOAnlB7oquBo=',
    }),
    buildStickerTemplate({
      name: 'Arrow 14',
      cover: stickerCover013,
      content: stickerContent013,
      hash: '3htAxVQCWAm3c0_xy0sUXKB_TAzwqwcodLA2W-633nA=',
    }),
    buildStickerTemplate({
      name: 'Arrow 15',
      cover: stickerCover014,
      content: stickerContent014,
      hash: 'xjqPPHMP_NtP_T4c8SDW-9XMiZJOf01BnXATYq7WwUc=',
    }),
    buildStickerTemplate({
      name: 'Arrow 16',
      cover: stickerCover015,
      content: stickerContent015,
      hash: 'celWgMBt-s1RP6U9e4XY0Cl2DbQ640-d-dmmKJOTPbU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 17',
      cover: stickerCover016,
      content: stickerContent016,
      hash: 'tlHzKPxi-aD8VBnvYY92WL44AXLrS1fiiAT4Q2BZM0A=',
    }),
    buildStickerTemplate({
      name: 'Arrow 18',
      cover: stickerCover017,
      content: stickerContent017,
      hash: '_XMVZ9Ky61ezOSN9lhOUpIozBo8s0rzWDnb7wmOhpXA=',
    }),
    buildStickerTemplate({
      name: 'Arrow 19',
      cover: stickerCover018,
      content: stickerContent018,
      hash: 'Wz0AJRnxvmUP5tVN2lauHdJ8zTaM9YNp6bDGqz1oeJk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 20',
      cover: stickerCover019,
      content: stickerContent019,
      hash: 'spOLMoa4_zQrMdEQVp7N9WNP2p-fidto0KBHPcONRPE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 21',
      cover: stickerCover020,
      content: stickerContent020,
      hash: 'h38dM2XAmu4KuVJX9ub4KN0BRoTz9HL0bSF6wsH-GZU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 22',
      cover: stickerCover021,
      content: stickerContent021,
      hash: 'Q06vAxpk3bVg7tPPx6d_rLt_BXSn2XtQm04Y83o5mVE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 23',
      cover: stickerCover022,
      content: stickerContent022,
      hash: 'A4rLg-FbEFkqP7NMZV1clkPRT-QINd26jg16Fa3fVwU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 24',
      cover: stickerCover023,
      content: stickerContent023,
      hash: 'WvD3rniT5lIIXDPzZjLDRFA8Dyh9fyZXu3pTWEtF1-s=',
    }),
    buildStickerTemplate({
      name: 'Arrow 25',
      cover: stickerCover024,
      content: stickerContent024,
      hash: 'Qvp034XeuAbYvvHqbJJ6FYU15EqR9JMSQwNVLBVHeyk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 26',
      cover: stickerCover025,
      content: stickerContent025,
      hash: 'e7hjjnG2FI3n2dfUfRg5Knpn944nnMQYhdr1LVCNhRU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 27',
      cover: stickerCover026,
      content: stickerContent026,
      hash: 'c8fsJDnXqHrRFg3Ju83wRHa1RCO9k3o2NFF1Tjr8thQ=',
    }),
    buildStickerTemplate({
      name: 'Arrow 28',
      cover: stickerCover027,
      content: stickerContent027,
      hash: 'JGY17Y2grsR8lGd0sZf85_ZjL6-uvAp0ONtX3FK95ck=',
    }),
    buildStickerTemplate({
      name: 'Arrow 29',
      cover: stickerCover028,
      content: stickerContent028,
      hash: 'F4YbPiTTNGq9cyiqSKjIxAEBYdWORNLFYxHMmfAs24o=',
    }),
    buildStickerTemplate({
      name: 'Arrow 30',
      cover: stickerCover029,
      content: stickerContent029,
      hash: 'wDMTQN26VcBOOF9nDhlem7ovgKLdNbFOvPyEDoHk6H0=',
    }),
    buildStickerTemplate({
      name: 'Arrow 31',
      cover: stickerCover030,
      content: stickerContent030,
      hash: 'c9p-sdq9YXFyNcsfgn6CC6n-DNDi9MxAW7v-4u_sLbo=',
    }),
    buildStickerTemplate({
      name: 'Arrow 32',
      cover: stickerCover031,
      content: stickerContent031,
      hash: 'lQr19wl2u3bkJ4y_q5BaLDe6K2DtIpu8AUktOAEou38=',
    }),
    buildStickerTemplate({
      name: 'Arrow 33',
      cover: stickerCover032,
      content: stickerContent032,
      hash: 'wGi2efXTFGlBV97YvMm72_miToeWITK_SfSgEmL04LY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 34',
      cover: stickerCover033,
      content: stickerContent033,
      hash: '-nOQjmN_oJrfSZqwRVK5SxKVNcLNDjsgd1CkpOyBIyY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 35',
      cover: stickerCover034,
      content: stickerContent034,
      hash: 'I0WOQG_uY4j7l7xonH2D0dEpcvsqE7g5h_B8ZZqxBgI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 36',
      cover: stickerCover035,
      content: stickerContent035,
      hash: 'Cov5L8kmEp9w5xza4bDj_EQ6u--_Xx1GNO3vcGgx3YE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 37',
      cover: stickerCover036,
      content: stickerContent036,
      hash: '3V1oNO4Mpy2GMi8uAqSqpYXYPJBGp_1ZCgDaEl9wVxI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 38',
      cover: stickerCover037,
      content: stickerContent037,
      hash: 'uvdQrHq3w8jVwJe5KRBJHi9kd0CmEJl2sZfAEj2XKdM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 39',
      cover: stickerCover038,
      content: stickerContent038,
      hash: 'Az86WgB08HFKq7bUIokNEw-nEp42A27Gt3_ZbJXksyw=',
    }),
    buildStickerTemplate({
      name: 'Arrow 40',
      cover: stickerCover039,
      content: stickerContent039,
      hash: 'X0XV84s7C0Kdu3YwrSPgENA9ljWCXzSXpgY3A-xyaXA=',
    }),
    buildStickerTemplate({
      name: 'Arrow 41',
      cover: stickerCover040,
      content: stickerContent040,
      hash: 'mYOSSo5okBDq6skCILrS8LTpW6-xfwusBoKVlkuCkzQ=',
    }),
    buildStickerTemplate({
      name: 'Arrow 42',
      cover: stickerCover041,
      content: stickerContent041,
      hash: '4l6wtVF-XjqEnsKe-IorebIcgUG4GGLLLlbns2LFh5Y=',
    }),
    buildStickerTemplate({
      name: 'Arrow 43',
      cover: stickerCover042,
      content: stickerContent042,
      hash: '3gR8h5LNWKGH8gA2C5_AjzrfDZD62pgJADPVTFDDaCw=',
    }),
    buildStickerTemplate({
      name: 'Arrow 44',
      cover: stickerCover043,
      content: stickerContent043,
      hash: 'qDhEedh5a5F5qXuGGec6_y_v4Qp1TFrgtuKqF4PG9Xc=',
    }),
    buildStickerTemplate({
      name: 'Arrow 45',
      cover: stickerCover044,
      content: stickerContent044,
      hash: 'R2Xz-yMk_8lA_svgD8p0kt1qEiDoNh0kmqggaMcqLAE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 46',
      cover: stickerCover045,
      content: stickerContent045,
      hash: 'zJ8gOpjZ-v9dgH7lsJBohyTCPqCLCY_hAmRLkgnXuOs=',
    }),
    buildStickerTemplate({
      name: 'Arrow 47',
      cover: stickerCover046,
      content: stickerContent046,
      hash: 'AmYp6Sz1U336rD4IbOlLFg0vxCbuhhSXbqBdXz7Uxjw=',
    }),
    buildStickerTemplate({
      name: 'Arrow 48',
      cover: stickerCover047,
      content: stickerContent047,
      hash: 'TKnls2KcG0don7CExsXGfGoCR__wxKHywMClrdalLt4=',
    }),
    buildStickerTemplate({
      name: 'Arrow 49',
      cover: stickerCover048,
      content: stickerContent048,
      hash: '39a8HJTi7-d-fWMsbS52trjZRXv6dCeZprY3C7Z87K8=',
    }),
    buildStickerTemplate({
      name: 'Arrow 50',
      cover: stickerCover049,
      content: stickerContent049,
      hash: 'EbBer46rRfgwQrkn-wmvGtlnPt8E0BPtys5AZaAiCBg=',
    }),
    buildStickerTemplate({
      name: 'Arrow 51',
      cover: stickerCover050,
      content: stickerContent050,
      hash: '0AUgxWzhjSlb6dJ00JdlaDauf3XYbu6s5LkREGOQu2o=',
    }),
    buildStickerTemplate({
      name: 'Arrow 52',
      cover: stickerCover051,
      content: stickerContent051,
      hash: 'lod43ond8UWBEt1BuCtARNI4CR4dxM7rWpslbDpgI64=',
    }),
    buildStickerTemplate({
      name: 'Arrow 53',
      cover: stickerCover052,
      content: stickerContent052,
      hash: 'Jacw1kzigDegUKTqoGCblNRKLZ1x5v0DKdDzeX1ZOI4=',
    }),
    buildStickerTemplate({
      name: 'Arrow 54',
      cover: stickerCover053,
      content: stickerContent053,
      hash: 'Kt1rXTJAbBpYPBFa503w_lT5tB3T1elds1FAFZP3uSQ=',
    }),
    buildStickerTemplate({
      name: 'Arrow 55',
      cover: stickerCover054,
      content: stickerContent054,
      hash: 'B9NdjtekEcN5oAl5OJNCS_nLpKejXTRNgsCoHDlrMOU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 56',
      cover: stickerCover055,
      content: stickerContent055,
      hash: 'qHg56iOOdWQuE-rZaow6EjW8xCmM8KW89s2kUXnsPPk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 57',
      cover: stickerCover056,
      content: stickerContent056,
      hash: '10MHITGc7WLFxUHX48DBaT4kGB4-_U387XnY8sLckyk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 58',
      cover: stickerCover057,
      content: stickerContent057,
      hash: 'AR3Vb5Ls8s1bYxNcKyqNp1hzbEN_0ONG7XbCOOP9MIQ=',
    }),
    buildStickerTemplate({
      name: 'Arrow 59',
      cover: stickerCover058,
      content: stickerContent058,
      hash: 'FhklHxC9FkPnYOelqR9fmyDePimgC2AdFUal9lQTxT0=',
    }),
    buildStickerTemplate({
      name: 'Arrow 60',
      cover: stickerCover059,
      content: stickerContent059,
      hash: 'qxGeYRvhJdFiDHFKeh1x1PFdGOwMB5vcS_TS84plCnU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 61',
      cover: stickerCover060,
      content: stickerContent060,
      hash: 'KAdP7kc19_WdMA8-liEOPC1ziXaxTXmA7tppdFq8e9s=',
    }),
    buildStickerTemplate({
      name: 'Arrow 62',
      cover: stickerCover061,
      content: stickerContent061,
      hash: 'UnGxrjNjKzW34c_q1Aot6xquEa2tmLht9FjTSM4tABg=',
    }),
    buildStickerTemplate({
      name: 'Arrow 63',
      cover: stickerCover062,
      content: stickerContent062,
      hash: 'T7jQAdOwCgw3q4kPSWiuhMuiTyYSqc66OSnvfs9FRJ0=',
    }),
    buildStickerTemplate({
      name: 'Arrow 64',
      cover: stickerCover063,
      content: stickerContent063,
      hash: 'sKzuGfE90E8IIluGiUwuyx8OJKbvoTReiOAHDCSRp1M=',
    }),
    buildStickerTemplate({
      name: 'Arrow 65',
      cover: stickerCover064,
      content: stickerContent064,
      hash: 'XI_NKdIzwCHUdpKJWIjLbcIRAfqWcndTysQM4rih9ts=',
    }),
    buildStickerTemplate({
      name: 'Arrow 66',
      cover: stickerCover065,
      content: stickerContent065,
      hash: 'RleQTrXAxdfBAULTfmrQniFREUI_Qsz4dTjmDGhccQo=',
    }),
    buildStickerTemplate({
      name: 'Arrow 67',
      cover: stickerCover066,
      content: stickerContent066,
      hash: 'x5TMf4rI1S-YzA0V9wERKTdOaMbRKBZD1NDu_1I5THY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 68',
      cover: stickerCover067,
      content: stickerContent067,
      hash: 'a6KuDZVNqxg1m8zcdPJ5X37yp1Z2RaPBibLON6DHG_o=',
    }),
    buildStickerTemplate({
      name: 'Arrow 69',
      cover: stickerCover068,
      content: stickerContent068,
      hash: 'w9ONvmYzWnZZOOjQNGZHNvHBj4O_tEii3aSSk27604E=',
    }),
    buildStickerTemplate({
      name: 'Arrow 70',
      cover: stickerCover069,
      content: stickerContent069,
      hash: 'q-TzLIs1Vnom51w4gCmvmcsB_X8ZzauX-reEqIzL4AE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 71',
      cover: stickerCover070,
      content: stickerContent070,
      hash: 'nJt3Lie_4lb7JfZvJYbr_JbanmvN2HspMw11Cd1FsGw=',
    }),
    buildStickerTemplate({
      name: 'Arrow 72',
      cover: stickerCover071,
      content: stickerContent071,
      hash: '_4yssK79DQYCjYpmVR71G1J_jGDsWqUpnLRjl2fxl1g=',
    }),
    buildStickerTemplate({
      name: 'Arrow 73',
      cover: stickerCover072,
      content: stickerContent072,
      hash: 'XxN6YXRVZMVXGhkZbuqrTGZapUCKWQ5eCKkWqzWpm6o=',
    }),
    buildStickerTemplate({
      name: 'Arrow 74',
      cover: stickerCover073,
      content: stickerContent073,
      hash: 'gTDGr6agieS0aX7Zqn_JeHzXwK_4zh_bu21dT_azrEg=',
    }),
    buildStickerTemplate({
      name: 'Arrow 75',
      cover: stickerCover074,
      content: stickerContent074,
      hash: 'cQQFdMzD9BHwFEKveUdf4TBSSn5v6RzFEWRgNbT-2aE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 76',
      cover: stickerCover075,
      content: stickerContent075,
      hash: 'jLYcblTKLLbF3LqlH2y6hct4davXDEGzgL47IYNgjMA=',
    }),
    buildStickerTemplate({
      name: 'Arrow 77',
      cover: stickerCover076,
      content: stickerContent076,
      hash: '_mWNkJ8I_nAuO7KsPgKm-3MjAutNc-WSS0uYvvKlYz8=',
    }),
    buildStickerTemplate({
      name: 'Arrow 78',
      cover: stickerCover077,
      content: stickerContent077,
      hash: 'koB_mtrAP5nO43INYL17oBFgMcdAOBg01myAEa-WeaU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 79',
      cover: stickerCover078,
      content: stickerContent078,
      hash: 'sxgXGRnY1INaau0E7lB-9BIUdKSDuRv3yhkhZ735WNo=',
    }),
    buildStickerTemplate({
      name: 'Arrow 80',
      cover: stickerCover079,
      content: stickerContent079,
      hash: 'WoKb0XxY2vkV9qwFyGMYC0T7sPFlUmP9CZIKypuDM4Q=',
    }),
    buildStickerTemplate({
      name: 'Arrow 81',
      cover: stickerCover080,
      content: stickerContent080,
      hash: 'eKh09dHTXqvmMICV1HoJeEKQUBFP3uD0n6dB2qs6etM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 82',
      cover: stickerCover081,
      content: stickerContent081,
      hash: 'uaezPvW7KieyG0_58MD6BkJEbfjnnUpQBIE3ZhsRGiw=',
    }),
    buildStickerTemplate({
      name: 'Arrow 83',
      cover: stickerCover082,
      content: stickerContent082,
      hash: '1EGNaIXzya6Gy_MhxB_nuvzVWVa3osLYhcAj6ZpX4BI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 84',
      cover: stickerCover083,
      content: stickerContent083,
      hash: 'mvQMRlR_GJ8tp8ncap4jIgBmPalapJV-cJVdTRMZgXM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 85',
      cover: stickerCover084,
      content: stickerContent084,
      hash: 'qFvtDG3q_nsUl5FR3cPH6bcfkbVwOWn1XCyac8QoQBY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 86',
      cover: stickerCover085,
      content: stickerContent085,
      hash: 'uXpsDDpWzxUBu2OaMxFJFGkHuJDQ59NTQXocl5HmiNE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 87',
      cover: stickerCover086,
      content: stickerContent086,
      hash: '2V5PR2qrVW516Td2advDoYFrQVPO2HGHv82xZ5jxVds=',
    }),
    buildStickerTemplate({
      name: 'Arrow 88',
      cover: stickerCover087,
      content: stickerContent087,
      hash: 'YxmlQ71SPxltuf3pHx6DUOvCFtQB9z9nCgETxX4iW8Y=',
    }),
    buildStickerTemplate({
      name: 'Arrow 89',
      cover: stickerCover088,
      content: stickerContent088,
      hash: 'hIeVzZMwHE5mKg7s0cQ__4v9MIOKNbgvoYybrChR9_c=',
    }),
    buildStickerTemplate({
      name: 'Arrow 90',
      cover: stickerCover089,
      content: stickerContent089,
      hash: 'e2Fh3BuJJ9r3eyN92MeLeP-rTfGVgtGw4-10BeIfR-c=',
    }),
    buildStickerTemplate({
      name: 'Arrow 91',
      cover: stickerCover090,
      content: stickerContent090,
      hash: 'quwM6cyRSvnsn1j6fsB4saZUpAxooky3F_9BSLKn21Y=',
    }),
    buildStickerTemplate({
      name: 'Arrow 92',
      cover: stickerCover091,
      content: stickerContent091,
      hash: 'poYBN2TaRZ2vqOU7SSDl2lfftpWZxqQW0iC7JWK-EaA=',
    }),
    buildStickerTemplate({
      name: 'Arrow 93',
      cover: stickerCover092,
      content: stickerContent092,
      hash: 'XWVUNPUtdC-XouXk1lduRIftYPrv6evaATxUWnUh7P0=',
    }),
    buildStickerTemplate({
      name: 'Arrow 94',
      cover: stickerCover093,
      content: stickerContent093,
      hash: '9MGtE1XdWXKTCdBJr7nnA5bpcxLCCu_A8aBBSWzpCSI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 95',
      cover: stickerCover094,
      content: stickerContent094,
      hash: 'hgMEGBkWqMHfIkWVLcZ1PbK-LVqRxgXYapd6EuBWcaA=',
    }),
    buildStickerTemplate({
      name: 'Arrow 96',
      cover: stickerCover095,
      content: stickerContent095,
      hash: 'IceU12pEQ7MBcrQC35LhJ_Zn3L04QuFV3Mdv7YT7FMk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 97',
      cover: stickerCover096,
      content: stickerContent096,
      hash: 'o_H8jR75oxwJEF9JZobNHeQaxz_UXNeGyVHErb95bo4=',
    }),
    buildStickerTemplate({
      name: 'Arrow 98',
      cover: stickerCover097,
      content: stickerContent097,
      hash: 'T_Nv1A4TIaiyXAwt8gljpkAT2Z3J_LTB02_x-fx3QfY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 99',
      cover: stickerCover098,
      content: stickerContent098,
      hash: 'RKbA1LvkNKrqITLidXxAtkabVSmqXn4CyUBc3CZ3oKc=',
    }),
    buildStickerTemplate({
      name: 'Arrow 100',
      cover: stickerCover099,
      content: stickerContent099,
      hash: 'YHrOpB868jEjxFoCAEjx11RMw7AEhnLEoBCMwlt3LYo=',
    }),
    buildStickerTemplate({
      name: 'Arrow 101',
      cover: stickerCover100,
      content: stickerContent100,
      hash: 'maGNtNtcIRjZNSCvmrg06iUnJa1w5Nf4JvzSmIGY8ws=',
    }),
    buildStickerTemplate({
      name: 'Arrow 102',
      cover: stickerCover101,
      content: stickerContent101,
      hash: 'ODaH0-jBWhlR0xy2ELPGP78Xokjrr5VjoQnfPZu0EcE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 103',
      cover: stickerCover102,
      content: stickerContent102,
      hash: 'qYjgNfKU265UxXMDWPWYUNsjeN46VUYS6jnEJ4xeQUI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 104',
      cover: stickerCover103,
      content: stickerContent103,
      hash: 'Tj0oBWQKtSO_1528dsfUlwgOqnPq8EBhC7aZYDWXfhs=',
    }),
    buildStickerTemplate({
      name: 'Arrow 105',
      cover: stickerCover104,
      content: stickerContent104,
      hash: 'KJS9VQh7NVNidvVTr8ue0mHPABDIe_OCqlWYejZr1Hg=',
    }),
    buildStickerTemplate({
      name: 'Arrow 106',
      cover: stickerCover105,
      content: stickerContent105,
      hash: 'Ut25LI6ck53-qeZ78Cydd0R3l1Rc6NORHudUd6wvTj4=',
    }),
    buildStickerTemplate({
      name: 'Arrow 107',
      cover: stickerCover106,
      content: stickerContent106,
      hash: '7HpyfAAitzLHycn52giubS8TI7nVsR29LQ1oP4m8tEs=',
    }),
    buildStickerTemplate({
      name: 'Arrow 108',
      cover: stickerCover107,
      content: stickerContent107,
      hash: 'eoAS3h5skTCH12LqQ1_XkpZkZnSiJIoz51l-Q7Hhcuc=',
    }),
    buildStickerTemplate({
      name: 'Arrow 109',
      cover: stickerCover108,
      content: stickerContent108,
      hash: 'MagxHByjiJ2s33LdBCxP9JNTEy-qnHdYJ_EOg1OYdb4=',
    }),
    buildStickerTemplate({
      name: 'Arrow 110',
      cover: stickerCover109,
      content: stickerContent109,
      hash: 'UoOMFSmjXcHqtngkSNdx68zRVhU5Hp3uxERKLlooQVY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 111',
      cover: stickerCover110,
      content: stickerContent110,
      hash: 'm2DEb21bFxLa6XkfLvCtxmIQfnDDllSdOrxXvFcOMT4=',
    }),
    buildStickerTemplate({
      name: 'Arrow 112',
      cover: stickerCover111,
      content: stickerContent111,
      hash: 'OokShOsH5aZC5Zbfh8Lkqj01HoPbnfbjdQkJQaaw3ug=',
    }),
    buildStickerTemplate({
      name: 'Arrow 113',
      cover: stickerCover112,
      content: stickerContent112,
      hash: 'dnKWQym8Dgz0UzYcUaK2tfph0kp7PrP0pY1wEFIcgZ0=',
    }),
    buildStickerTemplate({
      name: 'Arrow 114',
      cover: stickerCover113,
      content: stickerContent113,
      hash: 'QrU4_P6AF-1K1EaD68OHBQf0uPXOhTG24QfdcqOMa1Y=',
    }),
    buildStickerTemplate({
      name: 'Arrow 115',
      cover: stickerCover114,
      content: stickerContent114,
      hash: 'GMkgR0E8CJUZ-tmyXAElVSAYz_NRG4sYrZdOv2oRB3Q=',
    }),
    buildStickerTemplate({
      name: 'Arrow 116',
      cover: stickerCover115,
      content: stickerContent115,
      hash: 'UHl_GxFYv6N0vv14tia8ikpumzM_0U60tQBu7IRRgas=',
    }),
    buildStickerTemplate({
      name: 'Arrow 117',
      cover: stickerCover116,
      content: stickerContent116,
      hash: 'djrkvvGtEKWqJaKEJYEtULt3-irEqCbrKpR9ZO5ZfEs=',
    }),
    buildStickerTemplate({
      name: 'Arrow 118',
      cover: stickerCover117,
      content: stickerContent117,
      hash: 'FSjNcXiifqvpDqKTJgJmAbftD_NwfbUm5ls24hupE3A=',
    }),
    buildStickerTemplate({
      name: 'Arrow 119',
      cover: stickerCover118,
      content: stickerContent118,
      hash: '_-fmAAV8-Y_46ovEt7Cj4vjpguT_IUTNXMfzwmzpXvM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 120',
      cover: stickerCover119,
      content: stickerContent119,
      hash: 'GkDSsSD4vncYvGWmx5jD-VkyGLPZe74i34HHEV6lxs4=',
    }),
    buildStickerTemplate({
      name: 'Arrow 121',
      cover: stickerCover120,
      content: stickerContent120,
      hash: '54oAfew92u5k2i2m_wKzDrDKCZMPVwecWPNu-j8SlYI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 122',
      cover: stickerCover121,
      content: stickerContent121,
      hash: 'KORPcjmy9n9mMG0vqyxmBQA-4vJ0RjD7KoS5S45T3jw=',
    }),
    buildStickerTemplate({
      name: 'Arrow 123',
      cover: stickerCover122,
      content: stickerContent122,
      hash: 'Qz59poJguyJw9AGiHRRPf3PW0ZeE8OgglRSmBa1D2rc=',
    }),
    buildStickerTemplate({
      name: 'Arrow 124',
      cover: stickerCover123,
      content: stickerContent123,
      hash: 'GKr2EbYxDpz_q1Zkr6JJXTFzfSHUt2LJFbRAD75h9Bw=',
    }),
    buildStickerTemplate({
      name: 'Arrow 125',
      cover: stickerCover124,
      content: stickerContent124,
      hash: 'WFMY2PQ03BRTNXwBu1JnMUUymFpkYMrRXDtnSPdldHQ=',
    }),
    buildStickerTemplate({
      name: 'Arrow 126',
      cover: stickerCover125,
      content: stickerContent125,
      hash: 'gmsqvXOgIMM16zlHXLbfwZABSzAN3C0FBGq5Wk-v1xg=',
    }),
    buildStickerTemplate({
      name: 'Arrow 127',
      cover: stickerCover126,
      content: stickerContent126,
      hash: 'e5bXvc6Uf0qPkajmzAL0sNSg8gFHPhPpKGh3O5eh1M0=',
    }),
    buildStickerTemplate({
      name: 'Arrow 128',
      cover: stickerCover127,
      content: stickerContent127,
      hash: 'XDRUK8DczeG1-oCVPE8NGSkKT0DWo4LebdZ_qcW-frk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 129',
      cover: stickerCover128,
      content: stickerContent128,
      hash: '7OUGO8MAMnuXrlJTa3n9tV_97BEUdmvUTEJJ_Su5o00=',
    }),
    buildStickerTemplate({
      name: 'Arrow 130',
      cover: stickerCover129,
      content: stickerContent129,
      hash: 'UBjSYs2MzMR8knqu3SkZNerIpoQAesAFv9jb9y0qaWM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 131',
      cover: stickerCover130,
      content: stickerContent130,
      hash: 'gw0lkMrt8Nxf7VH4WB82psFO1CI1cge29F2FFDE6HVU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 132',
      cover: stickerCover131,
      content: stickerContent131,
      hash: 'JgcPNXMXJrlbGiVc_IyLcwxiAYjXVGTQKxZLvGnr_uk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 133',
      cover: stickerCover132,
      content: stickerContent132,
      hash: 'luQXiAzIJ7hxWEqiG483JKupOtkFpgOAKKgI8Wzms1o=',
    }),
    buildStickerTemplate({
      name: 'Arrow 134',
      cover: stickerCover133,
      content: stickerContent133,
      hash: 'H5XE_wOB5dg1TKso2zxseeXIubLIhSwNZ3Py3n9k-FE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 135',
      cover: stickerCover134,
      content: stickerContent134,
      hash: 'HtTxkr8kBtSDHy7HW2_E_csFc4KhDRi3Z5gkZyDm4kM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 136',
      cover: stickerCover135,
      content: stickerContent135,
      hash: 'sDe_sAZ9japs2uB025cVp5qViZVw4m2LeAWE6HZKQnU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 137',
      cover: stickerCover136,
      content: stickerContent136,
      hash: '75EsFpQ8QYEh2z-sLanaSWaJttlvuthbMPzQJjply64=',
    }),
    buildStickerTemplate({
      name: 'Arrow 138',
      cover: stickerCover137,
      content: stickerContent137,
      hash: 'MLrbqX_vUy3k6lm42iKp4qJZWzXFDzf1DxzjQghElqI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 139',
      cover: stickerCover138,
      content: stickerContent138,
      hash: 'PQ4Qj4DuBFKF4J5SOmW2jcNLjtlIcnzoYYfiEdFU5Hk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 140',
      cover: stickerCover139,
      content: stickerContent139,
      hash: 'y8nOErU_v0Ko_nvzIhSlilF0ue6lbSTE7D3Tdla5cYo=',
    }),
    buildStickerTemplate({
      name: 'Arrow 141',
      cover: stickerCover140,
      content: stickerContent140,
      hash: 'rta9VUGWRd_0PQ-5ZATrec3K4TKzPfoOXZ4aQbYJlC8=',
    }),
    buildStickerTemplate({
      name: 'Arrow 142',
      cover: stickerCover141,
      content: stickerContent141,
      hash: 'JLhISwmOHa6UNuU5cQSXRZHWdbtWF-B7m7whN7H-440=',
    }),
    buildStickerTemplate({
      name: 'Arrow 143',
      cover: stickerCover142,
      content: stickerContent142,
      hash: 'FPtqso4jtpd234L1b2f8aYJVSpoMdjrbDuTjTkGFSl8=',
    }),
    buildStickerTemplate({
      name: 'Arrow 144',
      cover: stickerCover143,
      content: stickerContent143,
      hash: '7O9NNBRHcHVsVWpzgx7gYSjR5F93SRw4MV2n53b-rfo=',
    }),
    buildStickerTemplate({
      name: 'Arrow 145',
      cover: stickerCover144,
      content: stickerContent144,
      hash: 'Zy_Qh9RquYQy-NAqdd4f71SnULIw4kfih0clDMUXoJU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 146',
      cover: stickerCover145,
      content: stickerContent145,
      hash: 'unLJEDYQ-wmBigb-qUBQMoHbAkr0kpzmNaAkurJPd9I=',
    }),
    buildStickerTemplate({
      name: 'Arrow 147',
      cover: stickerCover146,
      content: stickerContent146,
      hash: 'zFD6YXAGNl_BaxcnPe-MwXOj-6v1IoHWkyBb21ssnrE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 148',
      cover: stickerCover147,
      content: stickerContent147,
      hash: 'P6zOprXE8RAaqavT7JB_2Wm8TTtKe9SlWDslfayDG3I=',
    }),
    buildStickerTemplate({
      name: 'Arrow 149',
      cover: stickerCover148,
      content: stickerContent148,
      hash: 'qDvs8pxqTYqjZRSIvjr4JIcLo00MMK5-REo_foIxqGk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 150',
      cover: stickerCover149,
      content: stickerContent149,
      hash: 'Xmw_3s09sxS2x68zK_4tKRJchcEGPOT4ekldYUBwuhc=',
    }),
    buildStickerTemplate({
      name: 'Arrow 151',
      cover: stickerCover150,
      content: stickerContent150,
      hash: 'ncvXReXL12IJ_-jSPP3MNoaUm0sbJEiwDFbSOCm5YrA=',
    }),
    buildStickerTemplate({
      name: 'Arrow 152',
      cover: stickerCover151,
      content: stickerContent151,
      hash: 'tc6g4G4P6sXFh_-RyIwC-mPARxlI9GGTWT2qM4qGQv8=',
    }),
    buildStickerTemplate({
      name: 'Arrow 153',
      cover: stickerCover152,
      content: stickerContent152,
      hash: 'fgv878SEsW1-XSuSQvmtfvSEGTz6n2d_bkEX2LpFhxM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 154',
      cover: stickerCover153,
      content: stickerContent153,
      hash: 'ehrIzGjsE2q-5vqSG_mv8QBpMp9KYacRxxh9MoRgj4Y=',
    }),
    buildStickerTemplate({
      name: 'Arrow 155',
      cover: stickerCover154,
      content: stickerContent154,
      hash: 'kLQkz14oGfs8pra_VArR6TwO6u08J3S58n4qfIm54PM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 156',
      cover: stickerCover155,
      content: stickerContent155,
      hash: 'rkK3vR72XGsQ7HnaZlaQe_ooBNy7wvELFPl-hIOR_Vk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 157',
      cover: stickerCover156,
      content: stickerContent156,
      hash: 'QEKKz5wIKf2cQotvTq3C86KkXhDMqSbE2lylbwkXDh4=',
    }),
    buildStickerTemplate({
      name: 'Arrow 158',
      cover: stickerCover157,
      content: stickerContent157,
      hash: 'sqjVjNiIlEF3tO8g9JVEFScUKCPBY0caznqsP0G9H9Y=',
    }),
    buildStickerTemplate({
      name: 'Arrow 159',
      cover: stickerCover158,
      content: stickerContent158,
      hash: '2HmHLWQm_D9n8s-b8ozrmwfMLusAZ9ZJ6Ho2CAvvIDU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 160',
      cover: stickerCover159,
      content: stickerContent159,
      hash: 'oITqoWKu7lLGPUuIYVaxeZriD-9ScH54HJ_GSUEGeWY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 161',
      cover: stickerCover160,
      content: stickerContent160,
      hash: 'tT1v09rMg2t7jsjdBQlan-VhY3zn2HOQNt41bQz-CB4=',
    }),
    buildStickerTemplate({
      name: 'Arrow 162',
      cover: stickerCover161,
      content: stickerContent161,
      hash: 'n5sh39cXKuzEt3xgOKe5qXL4jcxAWq-ofTPsZVFkQS0=',
    }),
    buildStickerTemplate({
      name: 'Arrow 163',
      cover: stickerCover162,
      content: stickerContent162,
      hash: 'HVd59C5Za0TPfjZ7VP2VkgT33gdGL9EEod2JtyiKMzI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 164',
      cover: stickerCover163,
      content: stickerContent163,
      hash: '2-UWNL3XDEYX1zQkORaDLLePdZDYYUdB8LdnxU3uGMA=',
    }),
    buildStickerTemplate({
      name: 'Arrow 165',
      cover: stickerCover164,
      content: stickerContent164,
      hash: '1-kbw2QzT09CqljGliDnNnqOIeDimVBCYaSgLkPMPsE=',
    }),
    buildStickerTemplate({
      name: 'Arrow 166',
      cover: stickerCover165,
      content: stickerContent165,
      hash: 'm3NKcP-reCn2I5O2do79AtYyC-WvKEgWOYfFCUhsxDk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 167',
      cover: stickerCover166,
      content: stickerContent166,
      hash: '7_2MDEWn8DO6re_dBgDXqST_KD8P8oU8fLqCXgqX5yU=',
    }),
    buildStickerTemplate({
      name: 'Arrow 168',
      cover: stickerCover167,
      content: stickerContent167,
      hash: 'uqghDJdRp3nYpE3w3bDhga8Hplwl9Wqj610njR0IF_Y=',
    }),
    buildStickerTemplate({
      name: 'Arrow 169',
      cover: stickerCover168,
      content: stickerContent168,
      hash: 'L8rCxau1aGk5jTgkDJnJOxKuR5AJ8c0m6ba7-A7nJ44=',
    }),
    buildStickerTemplate({
      name: 'Arrow 170',
      cover: stickerCover169,
      content: stickerContent169,
      hash: 'E3KTyPCwLWhSMK_yEYUJIBvcBEDahQDyBq3iWCidIrk=',
    }),
    buildStickerTemplate({
      name: 'Arrow 171',
      cover: stickerCover170,
      content: stickerContent170,
      hash: 'mpnL0nWiiZQgc8J2k3waH0KfEatljxPRyqiDOMls0EM=',
    }),
    buildStickerTemplate({
      name: 'Arrow 172',
      cover: stickerCover171,
      content: stickerContent171,
      hash: 'wOh96Sl2ZJa_dOYHD6jasbMmdy8M-vnui64gX5L-kLY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 173',
      cover: stickerCover172,
      content: stickerContent172,
      hash: 'Jcz5e418FcYZO3VkkFT7_2agkFzjFEsaA2zepwdOjgc=',
    }),
    buildStickerTemplate({
      name: 'Arrow 174',
      cover: stickerCover173,
      content: stickerContent173,
      hash: 'NX8g8xaf9VaR_IUxYFvGN4W96gtY08t1xn3o7ABRO7c=',
    }),
    buildStickerTemplate({
      name: 'Arrow 175',
      cover: stickerCover174,
      content: stickerContent174,
      hash: 'wB3lJNTCUkHF3-TL9DLlevqxHlLHdJxi7HoR5r0Wg08=',
    }),
    buildStickerTemplate({
      name: 'Arrow 176',
      cover: stickerCover175,
      content: stickerContent175,
      hash: 'jn9sQ9iPETR1k3hep0hH03f74x-nWtpDsFHP3h2yor8=',
    }),
    buildStickerTemplate({
      name: 'Arrow 177',
      cover: stickerCover176,
      content: stickerContent176,
      hash: '9GO5x53CdMLEodGlKfjBwMHcUDMZ2KoxFrxKdy3i1DY=',
    }),
    buildStickerTemplate({
      name: 'Arrow 178',
      cover: stickerCover177,
      content: stickerContent177,
      hash: 'PAwkZBx_VXXXPLHhPJtf4fpWbYa4YJe8iZbhsovXrB4=',
    }),
    buildStickerTemplate({
      name: 'Arrow 179',
      cover: stickerCover178,
      content: stickerContent178,
      hash: 'SJR0JAX-p8esSoC6csYz1fRl3AXBs3VJglyf5nd8Deg=',
    }),
    buildStickerTemplate({
      name: 'Arrow 180',
      cover: stickerCover179,
      content: stickerContent179,
      hash: 'cTLltuV1M3z6lmdrtDDx0MbHJUnKp7KVG-WanFqjxTI=',
    }),
    buildStickerTemplate({
      name: 'Arrow 181',
      cover: stickerCover180,
      content: stickerContent180,
      hash: 'enogocPOFTCe_-q8scyRcK2E326DG_3h8YM0CvoPyuw=',
    }),
    buildStickerTemplate({
      name: 'Arrow 182',
      cover: stickerCover181,
      content: stickerContent181,
      hash: 'iNTYzdLr4Y5sbhnjgAWgXFuf7WYB03oxzKVDfudR42k=',
    }),
    buildStickerTemplate({
      name: 'Arrow 183',
      cover: stickerCover182,
      content: stickerContent182,
      hash: 'FuV06U5Dc6S8b8m3pp0fml_1Imi_wMfVOZEAUOM_71g=',
    }),
    buildStickerTemplate({
      name: 'Arrow 184',
      cover: stickerCover183,
      content: stickerContent183,
      hash: 'SQdUoVHFVgchAjKVwpaLSdvrqKsi9hkJh1b3Ev85S-w=',
    }),
  ],
  'Cheeky Piggies': [
    buildStickerTemplate({
      name: 'Crybaby',
      cover: stickerCover186,
      content: stickerContent186,
      hash: 'bRWBcaZveq6swjn8MwKVISsVnAr2tf4ZHTSTU-eRA5Q=',
    }),
    buildStickerTemplate({
      name: 'Drool',
      cover: stickerCover187,
      content: stickerContent187,
      hash: 'BUwkYl7SHNQCypB_SvkggKwAD3XxCRUPV6dorpW_ki0=',
    }),
    buildStickerTemplate({
      name: 'Fuming',
      cover: stickerCover188,
      content: stickerContent188,
      hash: 'Iu2DZ5PecYn6Rg7ONIzLqIVZa2v5WYnRKkMv8qTD8a8=',
    }),
    buildStickerTemplate({
      name: 'Hi~',
      cover: stickerCover189,
      content: stickerContent189,
      hash: 'h6r0wW-eIhWUF1AkN_EnHv-q8VfpZ4NOQKKTsbU8RPc=',
    }),
    buildStickerTemplate({
      name: 'Holding Tears',
      cover: stickerCover190,
      content: stickerContent190,
      hash: 'NnXjSqFfmw_D3Ne13JOx0yXIWtA9Exm6hggPGDgDfgc=',
    }),
    buildStickerTemplate({
      name: 'Love Blows',
      cover: stickerCover191,
      content: stickerContent191,
      hash: 'Oggqz68tzBBYevbwcwXqZjb4im48-f3hh94wf8RS-Ok=',
    }),
    buildStickerTemplate({
      name: 'Me_ Really_',
      cover: stickerCover192,
      content: stickerContent192,
      hash: 'W8dfy2MD-Fu2VOIPcYfHOuPNBnEIWcFg8TJJeta9iOc=',
    }),
    buildStickerTemplate({
      name: 'OK',
      cover: stickerCover193,
      content: stickerContent193,
      hash: 'aTpuWm7bxzUevhFn_xyIz0HO5YD-I4GmdoPvmw590PY=',
    }),
    buildStickerTemplate({
      name: 'Sassy Flick',
      cover: stickerCover194,
      content: stickerContent194,
      hash: 'ai5PdJq184Vxlagtbo5fo90RIvT7K0kQtKlhFF0T3h0=',
    }),
    buildStickerTemplate({
      name: 'Shockwave',
      cover: stickerCover195,
      content: stickerContent195,
      hash: 'NfiIZ-FHd2XWYF8L7pp8X1M3nGTM3-005VUtCOchld8=',
    }),
    buildStickerTemplate({
      name: 'Snooze Drool',
      cover: stickerCover196,
      content: stickerContent196,
      hash: 'HiRDmqZNvnKQDBX05caQF4Fg9PHh4_ZS0n_alWZcQ_M=',
    }),
    buildStickerTemplate({
      name: 'Swag',
      cover: stickerCover197,
      content: stickerContent197,
      hash: '4bEGq5-p-s6HfbtbVNwGEvEg-YEQ8wA8NA7Uj_vxTBE=',
    }),
    buildStickerTemplate({
      name: 'Sweatdrop',
      cover: stickerCover198,
      content: stickerContent198,
      hash: '6axmrPIHx4ahOGB_TtjLOPh4J6HYggLxxx0VGxnMu2E=',
    }),
    buildStickerTemplate({
      name: 'Thumbs Up',
      cover: stickerCover199,
      content: stickerContent199,
      hash: 'r97GwoejPTxjumyvS9kdAnB16nZvlM81xsHo0FqdUrM=',
    }),
    buildStickerTemplate({
      name: 'What_',
      cover: stickerCover200,
      content: stickerContent200,
      hash: 'JqWfcP9Q0kGE4wDuVZCi4lW2U7O15trpL--fdNrRJvQ=',
    }),
  ],
  'Contorted Stickers': [
    buildStickerTemplate({
      name: 'AI',
      cover: stickerCover202,
      content: stickerContent202,
      hash: 'VZJPB8ZBVtiZ-m04KNtlguY_t9VLx4itHILIQ3l1MRw=',
    }),
    buildStickerTemplate({
      name: 'Cat',
      cover: stickerCover203,
      content: stickerContent203,
      hash: 'IS6xbnAo5WXDRxnP98UBkdOP2Zt2luQXEojcLfnfsR4=',
    }),
    buildStickerTemplate({
      name: 'Closed',
      cover: stickerCover204,
      content: stickerContent204,
      hash: 'wzrJyCiyflFnyvvHdH2XONsuwbuw119wiFCekvopsmQ=',
    }),
    buildStickerTemplate({
      name: 'Eyes',
      cover: stickerCover205,
      content: stickerContent205,
      hash: 'eT4Nbl90OC9ivTjRBmEabaWqjdmITjCgOtTJNSJu1SU=',
    }),
    buildStickerTemplate({
      name: 'Fire',
      cover: stickerCover206,
      content: stickerContent206,
      hash: 'cQnt7T9qxI5-It-reeo3E4XVA3HA89L2myi1k2EJfn8=',
    }),
    buildStickerTemplate({
      name: 'Info',
      cover: stickerCover207,
      content: stickerContent207,
      hash: 'kwKlgzVYNRk4AyOJs3Xtyt0vMWovo-7BfEqaWndDInM=',
    }),
    buildStickerTemplate({
      name: 'King',
      cover: stickerCover208,
      content: stickerContent208,
      hash: 'W-RCNTaadPNEI9OALAGHqv1cGmYD1y7KxIRGLsbr-DM=',
    }),
    buildStickerTemplate({
      name: 'Love Face',
      cover: stickerCover209,
      content: stickerContent209,
      hash: '51B1S9eZ1rgxT-zG5npI_5l1sGss6dTVYiyut5fNPrs=',
    }),
    buildStickerTemplate({
      name: 'Love',
      cover: stickerCover210,
      content: stickerContent210,
      hash: 'fK5Bk7hxwSEHuNQ2WfO-ysII_T20z37P1JvLf00ocUQ=',
    }),
    buildStickerTemplate({
      name: 'Notice',
      cover: stickerCover211,
      content: stickerContent211,
      hash: 'RS787c3FcijjBEhKrKFa6KwB8ZINUD5MSCEMWL7F53w=',
    }),
    buildStickerTemplate({
      name: 'Pin',
      cover: stickerCover212,
      content: stickerContent212,
      hash: 'HDozRCXEtlDfNFFs3sSozkvXUVAP3XXd3zQVI8aW1ak=',
    }),
    buildStickerTemplate({
      name: 'Question',
      cover: stickerCover213,
      content: stickerContent213,
      hash: 'bvNeY3Q-At8NxFzcjTYx_mn3YnJkbUhh6XEBp3xB0Uk=',
    }),
    buildStickerTemplate({
      name: 'Smile Face',
      cover: stickerCover214,
      content: stickerContent214,
      hash: 'nBVc87wjO0NnM4utzjOLxjUzFjeFcf90C0jkgrpBhrA=',
    }),
    buildStickerTemplate({
      name: 'Stop',
      cover: stickerCover215,
      content: stickerContent215,
      hash: 'oH6E3y8ZpdgrMGbtcSX5k3NASEkgayohDCEoO0eU7hE=',
    }),
  ],
  Paper: [
    buildStickerTemplate({
      name: '+1',
      cover: stickerCover216,
      content: stickerContent216,
      hash: 'FEF1FPZ9H1lIO54e6gP5RlNNZqukz3ADuzPFgog5qH4=',
    }),
    buildStickerTemplate({
      name: 'A lot of question',
      cover: stickerCover217,
      content: stickerContent217,
      hash: 'yKPa7vqOxC6rh-e0SVdlp0RwMWQ9mzDKTtE5g2UnHGk=',
    }),
    buildStickerTemplate({
      name: 'Arrow',
      cover: stickerCover219,
      content: stickerContent219,
      hash: 'evuSkommPr7PBAWCioYDRQpKPZGoY6izIGev2C8Xdt0=',
    }),
    buildStickerTemplate({
      name: 'Atention',
      cover: stickerCover220,
      content: stickerContent220,
      hash: 'Lmvftjmkw5oQEyZ2VP6eTohbXgQyEtNWKkrg9AbDknI=',
    }),
    buildStickerTemplate({
      name: 'Blue Screen',
      cover: stickerCover221,
      content: stickerContent221,
      hash: '30OHymd5x-3zr_5KxQm3DzVfxyWWAf0QnmfHpIOoLzQ=',
    }),
    buildStickerTemplate({
      name: 'Boom',
      cover: stickerCover222,
      content: stickerContent222,
      hash: 'mpTlbN8fJWJUMlHcCCrQuzKTK844-F9YuebgR0kgJa8=',
    }),
    buildStickerTemplate({
      name: 'Cool',
      cover: stickerCover223,
      content: stickerContent223,
      hash: '3OujPx_YOY1MTqmgrbWaNDJlJeoLNvTWw96gW22rxps=',
    }),
    buildStickerTemplate({
      name: 'Dino',
      cover: stickerCover224,
      content: stickerContent224,
      hash: 'j13ZqHGUnVdGW3_1uWw_sFYeHj1SFoNsi5JwrTvpC-k=',
    }),
    buildStickerTemplate({
      name: 'Histogram',
      cover: stickerCover225,
      content: stickerContent225,
      hash: 'A1oGPUmv-Ypb-W7_jPgpSsVGA71J8njyr9f-97UnJQg=',
    }),
    buildStickerTemplate({
      name: 'Local First',
      cover: stickerCover226,
      content: stickerContent226,
      hash: 'LFIRZK4TswzJvThRO2Vch_aqfY2UZ6kjAyAEsQS-hHM=',
    }),
    buildStickerTemplate({
      name: 'Medal',
      cover: stickerCover227,
      content: stickerContent227,
      hash: 'cMIe6PYQLi0s9ryW3fbiXA9ACs3YsQFDtKjlfliXTC8=',
    }),
    buildStickerTemplate({
      name: 'Notice',
      cover: stickerCover228,
      content: stickerContent228,
      hash: 'oafBAmM8MB094GI9I4U2iG6TWoZpCoa4nDmGY2eH_Kw=',
    }),
    buildStickerTemplate({
      name: 'Pin',
      cover: stickerCover229,
      content: stickerContent229,
      hash: 'kEy0pTA3dsClFtIwaJJV9NZQvn2xib-biyFJvRp9tzM=',
    }),
    buildStickerTemplate({
      name: 'Star',
      cover: stickerCover230,
      content: stickerContent230,
      hash: 'Au-JbxT7XZvvDueaVg37tJ4kyH9vLxiC7tLgcbrZV48=',
    }),
  ],
};

export const builtInTemplates = {
  list: async (category: string) => {
    return templates[category] ?? [];
  },

  categories: async () => {
    return Object.keys(templates);
  },

  search: async (query: string) => {
    const candidates: unknown[] = [];
    const cates = Object.keys(templates);

    query = query.toLowerCase();

    for (const cate of cates) {
      const templatesOfCate = templates[cate];

      for (const temp of templatesOfCate) {
        if (lcs(query, temp.name.toLowerCase()) === query.length) {
          candidates.push(temp);
        }
      }
    }

    return candidates;
  },
};
