import { AffineEditorContainer } from '@blocksuite/presets';
import { useEffect, useRef, useState } from 'react';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { useAppSelector } from 'src/store';
import { Doc, DocCollection, IdGeneratorType, Schema, Text } from '@blocksuite/store';
import {
  AffineSchemas,
  PreviewEdgelessEditorBlockSpecs,
  PreviewEditorBlockSpecs,
  SpecProvider,
} from '@blocksuite/blocks';
import { effects as blocksEffects } from '@blocksuite/blocks/effects';
import { effects as presetsEffects } from '@blocksuite/presets/effects';
import {
  BroadcastChannelAwarenessSource,
  IndexedDBBlobSource,
  IndexedDBDocSource,
  MemoryBlobSource,
} from '@blocksuite/sync';
import useEditorCssVariables from './useEditorCssVariables';
import { initDefaultDocEditor } from './initDefaultDocEditor';
import initializeSlashMenu from './initializeShashMenu';
import { WorkbaseEmbedVideoBlock } from './blocks/video/videoBlock';
import { VideoBlockSchema } from './blocks/video/videoSchema';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';
import { EmbedWorkbaseVideoBlockSpec } from './blocks/video/videoSpec';
import { BlobSourceServer } from './BlobSourceServer';
import setupEdgelessTemplate from './templates/setupEdgelessTemplates';

blocksEffects();
presetsEffects();
setupEdgelessTemplate();
initializeSlashMenu();
if (!customElements.get('workbase-embed-video-block')) {
  customElements.define('workbase-embed-video-block', WorkbaseEmbedVideoBlock as any);
}

SpecProvider.getInstance().clearSpec('page:preview');
SpecProvider.getInstance().addSpec('page:preview', [
  ...PreviewEditorBlockSpecs,
  ...EmbedWorkbaseVideoBlockSpec,
]);

SpecProvider.getInstance().clearSpec('edgeless:preview');
SpecProvider.getInstance().addSpec('edgeless:preview', [
  ...PreviewEdgelessEditorBlockSpecs,
  ...EmbedWorkbaseVideoBlockSpec,
]);

const parsedUrl = new URL(import.meta.env.VITE_API_URL);
const socketProtocol = parsedUrl.protocol === 'https:' ? 'wss' : 'ws';
const socketUrl = `${socketProtocol}://${parsedUrl.host}/editor`;

export default function useInitEditor(
  name: string,
  readOnly: boolean,
  onTitleChange?: (title: string) => void
) {
  const [editor, setEditor] = useState<AffineEditorContainer>();
  const [doc, setDoc] = useState<Doc>();
  const token = useAppSelector((state) => state.auth.token);
  const isCustomerPortal = useIsCustomerPortal();

  const providerRef = useRef<HocuspocusProvider | null>(null);

  useEffect(() => {
    if (!token) return undefined;

    const controller = new AbortController();

    const schema = new Schema().register([...AffineSchemas, VideoBlockSchema]);
    const collectionId = `${name}`;

    const blobSources = {
      main: new MemoryBlobSource(),
      shadows: [new BlobSourceServer(collectionId), new IndexedDBBlobSource(collectionId)],
    };

    const collection = new DocCollection({
      id: collectionId,
      idGenerator: IdGeneratorType.UUIDv4,
      schema,
      docSources: { main: new IndexedDBDocSource() },
      awarenessSources: [new BroadcastChannelAwarenessSource(collectionId)],
      blobSources,
      defaultFlags: {
        enable_synced_doc_block: true,
        enable_lasso_tool: true,
        enable_edgeless_text: true,
        enable_color_picker: true,
        enable_mind_map_import: true,
        enable_advanced_block_visibility: true,
        enable_shape_shadow_blur: false,
      },
    });

    collection.meta.initialize();

    const doc = collection.createDoc({ id: name });
    doc.clear();
    doc?.load(() => {
      const docEditor = initDefaultDocEditor(doc);
      docEditor.mode = 'page';
      docEditor.doc = doc;
      if (!providerRef.current) {
        providerRef.current = new HocuspocusProvider({
          url: `${socketUrl}?portalMode=${isCustomerPortal ? 'customerPortal' : 'employeePortal'}`,
          name,
          document: doc.spaceDoc,
          token,
          onSynced: async () => {
            const rootBlock = doc.getBlocksByFlavour('affine:page')[0];
            if (!rootBlock) {
              const rootBlockId = doc.addBlock('affine:page', { title: new Text() });
              doc.addBlock('affine:surface', {}, rootBlockId);
              collection.setDocMeta(doc.id, { titleIcon: 'WorkbasePageIcon' });
            }
            doc.resetHistory();
            setEditor(docEditor);
            setDoc(doc);
          },
        });
        providerRef.current.connect();
      }
      controller.signal.addEventListener(
        'abort',
        () => {
          if (providerRef.current) {
            setEditor(undefined);
            setDoc(undefined);
            providerRef.current.configuration.websocketProvider.disconnect();
            providerRef.current.configuration.websocketProvider.destroy();
            providerRef.current.disconnect();
            providerRef.current.destroy();
            providerRef.current = null;
          }
        },
        { once: true }
      );
    });

    return () => {
      controller.abort();
    };
  }, [token, name, isCustomerPortal]);

  useEffect(() => {
    if (doc) {
      doc.awarenessStore.setReadonly(doc.blockCollection, readOnly);
    }
  }, [doc, readOnly]);

  useEffect(() => {
    const title = doc?.getBlocksByFlavour('affine:page')[0];
    if (title && onTitleChange) {
      const onObserveTitle = () => {
        onTitleChange(doc.meta?.title || '');
      };
      title?.yBlock.observeDeep(onObserveTitle);
      return () => title?.yBlock.unobserve(onObserveTitle);
    }
    return undefined;
  }, [doc, onTitleChange]);

  useEditorCssVariables(editor);

  return { editor, doc, setDoc };
}

declare module '@blocksuite/store' {
  interface DocMeta {
    titleIcon?: string;
  }
}
