import { AffineEditorContainer } from '@blocksuite/presets';
import { Doc } from '@blocksuite/store';
import { createContext, useContext } from 'react';

export const EditorContext = createContext<{
  editor?: AffineEditorContainer;
  doc?: Doc;
} | null>(null);

export function useEditor() {
  return useContext(EditorContext);
}
