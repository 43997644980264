import { useSidebarQuery, usePatchSidebarMutation, SidebarItem } from 'src/store/api/sidebarApi';
import { useEffect, useState } from 'react';
import { RichTreeViewPro, RichTreeViewProProps } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import generateJitteredKeyBetween from 'src/lib/utils/generateJitteredKeyBetween';
import CustomTreeItem from './CustomTreeItem';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';

type SidebarTreeViewItem = TreeViewBaseItem & {
  icon: string;
  children: SidebarTreeViewItem[];
};

export function buildNestedSidebarTree(items: SidebarItem[], t: any): SidebarTreeViewItem[] {
  const itemMap = new Map<string, SidebarTreeViewItem>();

  items.forEach((item) => {
    itemMap.set(item.id, {
      id: item.id,
      icon: item.icon,
      label: item.name || t('customSites.unnamedPage'),
      children: [],
    });
  });

  const roots: SidebarTreeViewItem[] = [];

  items.forEach((item) => {
    if (item.parentId === null) {
      const rootItem = itemMap.get(item.id);
      if (rootItem) roots.push(rootItem);
    } else {
      const parentItem = itemMap.get(String(item.parentId));
      const childItem = itemMap.get(item.id);
      if (parentItem && childItem) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parentItem.children!.push(childItem);
      }
    }
  });

  function getPosition(id: string | number): string {
    const matched = items.find((i) => i.id === id);
    return matched ? matched.position : '0';
  }

  function sortTree(treeItems: TreeViewBaseItem[]) {
    treeItems.sort((a, b) => {
      const aPos = parseFloat(getPosition(a.id));
      const bPos = parseFloat(getPosition(b.id));
      return aPos - bPos;
    });
    treeItems.forEach((child) => {
      if (child.children && child.children.length) {
        sortTree(child.children);
      }
    });
  }

  sortTree(roots);
  return roots;
}

function findPathToNode(tree: SidebarTreeViewItem[], targetId: string): string[] {
  function dfs(node: SidebarTreeViewItem, path: string[]): string[] | null {
    if (node.id === targetId) {
      return path;
    }
    for (const child of node.children) {
      const result = dfs(child, [...path, node.id]);
      if (result) {
        return result;
      }
    }
    return null;
  }

  for (const root of tree) {
    const path = dfs(root, []);
    if (path) {
      return path;
    }
  }
  return [];
}

export default function SidebarTree() {
  const isCustomerPortal = useIsCustomerPortal();
  const { data } = useSidebarQuery(isCustomerPortal);
  const [patchSidebar] = usePatchSidebarMutation();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialTree = data?.sidebarItems ? buildNestedSidebarTree(data.sidebarItems, t) : [];

  const [items, setItems] = useState<SidebarTreeViewItem[]>(initialTree);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  useEffect(() => {
    if (data?.sidebarItems) {
      setItems(buildNestedSidebarTree(data.sidebarItems, t));
    }
  }, [data, t]);

  useEffect(() => {
    if (params.id && items.length > 0) {
      const path = findPathToNode(items, params.id);

      setExpandedItems((prevExpanded) => {
        const newSet = new Set(prevExpanded);
        path.forEach((id) => newSet.add(id));
        return Array.from(newSet);
      });
    }
  }, [params.id, items]);

  function findSiblings(treeItems: SidebarTreeViewItem[], parentId: string | null) {
    if (!parentId) {
      return treeItems;
    }
    const queue: SidebarTreeViewItem[] = [...treeItems];
    while (queue.length) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const node = queue.shift()!;
      if (String(node.id) === String(parentId)) {
        return node.children || [];
      }
      if (node.children) {
        queue.push(...node.children);
      }
    }
    return [];
  }

  function getBackendPosition(id: string | undefined, allItems?: SidebarItem[]): string | null {
    if (!id || !allItems) return null;
    return allItems.find((item) => item.id === id)?.position || null;
  }

  const handleItemPositionChange: RichTreeViewProProps<any, any>['onItemPositionChange'] = async ({
    itemId,
    oldPosition,
    newPosition,
  }) => {
    const { parentId: oldParentId } = oldPosition;
    const { parentId: newParentId, index: newIndex } = newPosition;

    // 1) If same parent, remove the dragged item from siblings before using newIndex
    const siblings = findSiblings(items, newParentId);
    if (oldParentId === newParentId) {
      const draggedIndex = siblings.findIndex((node) => String(node.id) === String(itemId));
      if (draggedIndex !== -1) {
        siblings.splice(draggedIndex, 1);
      }
    }

    // 2) Now we can pick "beforeSibling" & "afterSibling" using newIndex
    const beforeSibling = siblings[newIndex - 1];
    const afterSibling = siblings[newIndex];

    const beforePos = getBackendPosition(beforeSibling?.id, data?.sidebarItems);
    const afterPos = getBackendPosition(afterSibling?.id, data?.sidebarItems);

    // generate a fractional index between these two
    const newPosString = generateJitteredKeyBetween(beforePos, afterPos);

    try {
      await patchSidebar({
        id: String(itemId),
        parentId: newParentId ?? null, // or parseInt if needed
        position: newPosString,
      }).unwrap();
      // eslint-disable-next-line no-empty
    } catch (_) {}
  };

  const findResourceType = (id: string) =>
    data?.sidebarItems.find((item) => item.id === id)?.resourceType;

  return (
    <RichTreeViewPro
      sx={{
        '& > div:last-child': {
          display: 'none',
        },
        py: 2,
      }}
      selectedItems={params.id}
      onSelectedItemsChange={(_, id) => {
        if (id) {
          const resourceType = findResourceType(id);
          navigate(`/${resourceType}/${id}`);
        }
      }}
      slots={{ item: CustomTreeItem as any }}
      items={items}
      expandedItems={expandedItems}
      onExpandedItemsChange={(_, ids) => setExpandedItems(ids)}
      itemsReordering
      expansionTrigger="iconContainer"
      experimentalFeatures={{
        indentationAtItemLevel: true,
        itemsReordering: true,
      }}
      onItemPositionChange={handleItemPositionChange}
    />
  );
}
