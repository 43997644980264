import { useCallback, useEffect, useRef } from 'react';
import { useEditor } from './context';
import { Box, Container, Skeleton, Stack } from '@mui/material';
import EditorStyles from './EditorStyles';
import { useSearchParams } from 'react-router-dom';
import { useToggleEditorModeHotkey } from './useToggleEditorModeHotkey';
import EditorTools from './EditorTools';

export default function Editor() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { editor } = useEditor()!;
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const editorMode = searchParams.get('mode') === 'edgeless' ? 'edgeless' : 'page';

  useEffect(() => {
    if (editorContainerRef.current && editor) {
      editorContainerRef.current.innerHTML = '';
      editorContainerRef.current.appendChild(editor);
    }
  }, [editor]);

  useEffect(() => {
    if (!editor || !editorMode) return undefined;

    editor.mode = editorMode;

    let observer: MutationObserver | null = null;
    let moreButtonObserver: MutationObserver | null = null;

    const handlePageModeWidget = () => {
      const formatBarWidget = document.querySelector('affine-format-bar-widget');
      if (!formatBarWidget) return;

      observer = new MutationObserver(() => {
        const editorToolbar = formatBarWidget.shadowRoot?.querySelector('editor-toolbar');
        if (editorToolbar) {
          const linkedDocButton = editorToolbar.querySelector(
            'editor-icon-button[data-testid="convert-to-linked-doc"]'
          );
          const lastSeparator = editorToolbar.querySelector(
            'editor-toolbar-separator:nth-last-child(2)'
          );
          if (linkedDocButton && lastSeparator) {
            linkedDocButton.remove();
            lastSeparator.remove();
          }
        }
      });

      if (formatBarWidget.shadowRoot) {
        observer.observe(formatBarWidget.shadowRoot, { childList: true, subtree: true });
      }
    };

    const handleEdgelessModeWidget = () => {
      const edgelessWidget = document.querySelector('edgeless-element-toolbar-widget');
      if (!edgelessWidget) return;

      observer = new MutationObserver(() => {
        const edgelessToolbar = edgelessWidget.shadowRoot?.querySelector('editor-toolbar');
        if (edgelessToolbar) {
          const edgelessMoreButton = edgelessToolbar.querySelector('edgeless-more-button');
          if (edgelessMoreButton?.shadowRoot) {
            moreButtonObserver = new MutationObserver(() => {
              const linkedDocMenuAction = edgelessMoreButton.shadowRoot?.querySelector(
                'editor-menu-action[aria-label="Turn into linked doc"]'
              );
              if (linkedDocMenuAction) {
                linkedDocMenuAction.remove();
              }
            });

            moreButtonObserver.observe(edgelessMoreButton.shadowRoot, {
              childList: true,
              subtree: true,
            });
          }
        }
      });

      if (edgelessWidget.shadowRoot) {
        observer.observe(edgelessWidget.shadowRoot, { childList: true, subtree: true });
      }
    };

    setTimeout(() => {
      if (editorMode === 'page') {
        handlePageModeWidget();
      } else if (editorMode === 'edgeless') {
        handleEdgelessModeWidget();
      }
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [editor, editorMode]);

  const toggleMode = useCallback(() => {
    const newMode = editorMode === 'page' ? 'edgeless' : 'page';
    setSearchParams({ mode: newMode });
  }, [editorMode, setSearchParams]);

  useToggleEditorModeHotkey({
    onToggleMode: toggleMode,
  });

  const isEdgeleessMode = editorMode === 'edgeless';
  const edgelessEditorContainerStyles = {
    position: 'absolute',
    zIndex: 16,
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  };
  const additionalStyles = isEdgeleessMode ? edgelessEditorContainerStyles : {};
  return (
    <>
      {editor && (
        <EditorTools
          editorMode={editorMode}
          editor={editor}
          onEditorModeChange={(newMode) => setSearchParams({ mode: newMode })}
        />
      )}
      {!editor && (
        <Container>
          <Stack spacing={1} py={4}>
            <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 1 }} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Stack>
        </Container>
      )}
      {editor && (
        <>
          <Box
            className="editor-container"
            sx={{
              flexGrow: 1,
              minHeight: 0,
              position: 'relative',
              ...additionalStyles,
            }}
            ref={editorContainerRef}
          />
          <EditorStyles />
        </>
      )}{' '}
    </>
  );
}
