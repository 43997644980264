import { DocMode } from '@blocksuite/affine-model';
import {
  createAssetsArchive,
  docLinkBaseURLMiddleware,
  download,
  EdgelessRootService,
  ExportManager,
  HtmlAdapterFactoryIdentifier,
  MarkdownAdapterFactoryIdentifier,
  PlainTextAdapterFactoryIdentifier,
  TextIcon,
  titleMiddleware,
  ZipTransformer,
} from '@blocksuite/blocks';
import { AffineEditorContainer } from '@blocksuite/presets';
import { IconButton, Menu, MenuItem, Stack, SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMacOS } from 'src/constants/platform';
import FloatingActionWrapper from 'src/ui-components/floating-action-wrapper/FloatingActionWrapper';
import ShortcutBox from 'src/ui-components/shortcut-box';
import {
  PresentationIcon,
  EdgelessIcon,
  PageIcon,
  ExportIcon,
  ExportToMarkdownIcon,
  ExportToHtmlIcon,
  ExportToPdfIcon,
} from '@blocksuite/icons/rc';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { Doc, DocCollection, Job } from '@blocksuite/store';
import { useEditor } from './context';

interface Props {
  editorMode: DocMode;
  onEditorModeChange: (val: DocMode) => void;
  editor: AffineEditorContainer;
}

function ExportMenu() {
  const popup = usePopupState({ variant: 'popover' });
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { doc, editor } = useEditor()!;
  const { t } = useTranslation();

  const menuItemsData = [
    {
      config: {
        identifier: MarkdownAdapterFactoryIdentifier,
        fileExtension: '.md',
        contentType: 'text/plain',
        indexFileName: 'index.md',
      },
      text: t('editor.exportMarkdown'),
      icon: ExportToMarkdownIcon,
    },
    {
      config: {
        identifier: HtmlAdapterFactoryIdentifier,
        fileExtension: '.html',
        contentType: 'text/html',
        indexFileName: 'index.html',
      },
      text: t('editor.exportHtml'),
      icon: ExportToHtmlIcon,
    },
    {
      config: {
        identifier: PlainTextAdapterFactoryIdentifier,
        fileExtension: '.txt',
        contentType: 'text/plain',
        indexFileName: 'index.txt',
      },
      text: t('editor.exportPlainText'),
      icon: TextIcon,
    },
    {
      onClick: () => {
        editor?.std.get(ExportManager).exportPdf();
      },
      text: t('editor.exportPDF'),
      icon: ExportToPdfIcon,
    },
    {
      onClick: () => {
        editor?.std.get(ExportManager).exportPng();
      },
      text: t('editor.exportPNG'),
    },
    {
      onClick: () => {
        doc &&
          ZipTransformer.exportDocs(
            doc.collection as any,
            [...doc.collection.docs.values()].map((collection) => collection.getDoc()) as any
          );
      },
      text: t('editor.exportSnapshot'),
    },
  ];
  const menuProps = bindMenu(popup);

  return (
    <>
      <Tooltip title={t('editor.export')}>
        <IconButton {...bindTrigger(popup)}>
          <SvgIcon component={ExportIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu {...menuProps}>
        {menuItemsData.map(({ config, text, onClick }) => (
          <MenuItem
            key={text}
            onClick={() => {
              if (onClick) {
                menuProps.onClose();
                return onClick();
              }
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              doc && editor && exportFile({ config, editor, doc, collection: doc.collection });
              menuProps.onClose();
              return undefined;
            }}
          >
            {text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default function EditorTools({ editorMode, onEditorModeChange, editor }: Props) {
  const { t } = useTranslation();
  const shortcutContent = isMacOS ? (
    <>
      <ShortcutBox>⌥</ShortcutBox>
      <ShortcutBox>S</ShortcutBox>
    </>
  ) : (
    <>
      <ShortcutBox>CTRL</ShortcutBox>
      <ShortcutBox>SHIFT</ShortcutBox>
      <ShortcutBox>S</ShortcutBox>
    </>
  );
  return (
    // eslint-disable-next-line no-restricted-syntax
    <FloatingActionWrapper sx={{ position: 'absolute', left: 8, top: 8, zIndex: 20 }}>
      <Tooltip
        title={
          <Stack direction="row" gap={0.5} alignItems="center">
            {t('editor.switchModeToggleButtonsTooltip')}
            {shortcutContent}
          </Stack>
        }
      >
        <Stack direction="row" gap={1}>
          <ToggleButton
            value="page"
            selected={'page' === editorMode}
            onChange={() => onEditorModeChange('page')}
          >
            <SvgIcon component={PageIcon} fontSize="small" />
          </ToggleButton>
          <ToggleButton
            value="edgeless"
            selected={'edgeless' === editorMode}
            onChange={() => onEditorModeChange('edgeless')}
          >
            <SvgIcon component={EdgelessIcon} fontSize="small" />
          </ToggleButton>
        </Stack>
      </Tooltip>
      {editorMode === 'edgeless' && (
        <Tooltip title={t('editor.presentFramesButtonTooltip')}>
          <ToggleButton
            value="present"
            onClick={() => {
              const service = editor.std.getService('affine:page');
              if (service instanceof EdgelessRootService) {
                service.gfx.tool.setTool('frameNavigator', {
                  mode: 'fit',
                });
              }
            }}
          >
            <SvgIcon component={PresentationIcon} fontSize="small" />
          </ToggleButton>
        </Tooltip>
      )}
      <ExportMenu />
    </FloatingActionWrapper>
  );
}

// exporting

interface AdapterResult {
  file: string;
  assetsIds: string[];
}

type AdapterFactoryIdentifier =
  | typeof HtmlAdapterFactoryIdentifier
  | typeof MarkdownAdapterFactoryIdentifier
  | typeof PlainTextAdapterFactoryIdentifier;

interface AdapterConfig {
  identifier: AdapterFactoryIdentifier;
  fileExtension: string; // file extension need to be lower case with dot prefix, e.g. '.md', '.txt', '.html'
  contentType: string;
  indexFileName: string;
}

// eslint-disable-next-line
async function exportFile({
  config,
  doc,
  collection,
  editor,
}: {
  config: AdapterConfig;
  doc: Doc;
  collection: DocCollection;
  editor: AffineEditorContainer;
}) {
  const job = new Job({
    collection,
    middlewares: [docLinkBaseURLMiddleware as any, titleMiddleware],
  });

  const adapterFactory = editor.std.provider.get(config.identifier);
  const adapter = adapterFactory.get(job as any);
  const result = (await adapter.fromDoc(doc as any)) as AdapterResult;

  if (!result || (!result.file && !result.assetsIds.length)) {
    return;
  }

  const docTitle = doc.meta?.title || 'Untitled';
  const contentBlob = new Blob([result.file], { type: config.contentType });

  let downloadBlob: Blob;
  let name: string;

  if (result.assetsIds.length > 0) {
    if (!job.assets) {
      throw new Error('No assets found');
    }
    const zip = await createAssetsArchive(job.assets, result.assetsIds);
    await zip.file(config.indexFileName, contentBlob);
    downloadBlob = await zip.generate();
    name = `${docTitle}.zip`;
  } else {
    downloadBlob = contentBlob;
    name = `${docTitle}${config.fileExtension}`;
  }

  download(downloadBlob, name);
}

// importing

// async function importHTML(editor: AffineEditorContainer) {
//   try {
//     const file = await openFileOrFiles({
//       acceptType: 'Html',
//       multiple: false,
//     });

//     if (!file) return;

//       const text = await file.text();
//       const fileName = file.name.split('.').slice(0, -1).join('.');

//       const pageId = await HtmlTransformer.importHTMLToDoc({
//         collection: editor.doc.collection as any,
//         html: text,
//         fileName,
//       });

//       if (!pageId) return
//       const importedDoc = editor.doc.collection.getDoc(pageId)
//       if (importedDoc) {
//         const doc = editor.doc
//         try {
//           editor.doc = importedDoc
//         } catch (e) {
//           console.log("error", e)
//         }

//         const id = doc.id

//         if (doc.meta) {
//         doc.meta.id = id
//         }

//         doc.clear();
//         const rootBlockId = doc.addBlock('affine:page', { title: new Text() });
//         doc.addBlock('affine:surface', {}, rootBlockId);

//         console.log("docID before", doc.id)
//          const update = encodeStateAsUpdate(importedDoc.spaceDoc)
//          applyUpdate(doc.spaceDoc, update)
//         console.log("docID", doc.id)
//          doc.resetHistory();

//          setTimeout(() => {
//          editor.doc = doc
//          })
//     }
//   } catch (_) {
//   }
// }

// function ImportButton() {
//   const popup = usePopupState({variant: 'dialog'})
//   const {t} = useTranslation()
//   const dialogProps = bindDialog(popup)
//   const {editor} = useEditor()!

//   if (!editor) return null

//   const importOptions = [
//     {
//       text: t("editor.markdown"),
//       icon: ExportToMarkdownIcon,
//       onClick: () => {
//         editor.doc.clear()
//       }
//     },
//     {
//       text: t("editor.html"),
//       icon: ExportToHtmlIcon,
//       onClick: () => importHTML(editor)
//     },
//     {
//       text: t("editor.notion"),
//       icon: NotionIcon
//     },
//     {
//       text: t("editor.snapshot"),
//       icon: PageIcon
//     },
//   ]
//   return (
//     <>
//      <Tooltip title={t('editor.import')}>
//       <IconButton
// {...bindTrigger(popup)}
// >
//         <SvgIcon component={ImportIcon} fontSize="small"/>
//       </IconButton>
//      </Tooltip>
//      <Dialog {...dialogProps} fullWidth maxWidth="xs">
//      <DialogTitle>{t('editor.import')}</DialogTitle>
//      <DialogContent>
//       <List sx={{p: 0}}>
//         <Stack gap={1}>
//     {importOptions.map(({text, icon, onClick}) => <ListItemButton disableGutters dense key={text} onClick={()=> {
//         onClick?.();
//         popup.close()
//        }}>
//       <ListItemIcon>
//         <SvgIcon component={icon} fontSize="small"/>
//       </ListItemIcon>
//       <ListItemText>{text}</ListItemText>
//     </ListItemButton>)}
//     </Stack>
//       </List>
//      </DialogContent>
//      </Dialog>
//     </>
//   );

// }
