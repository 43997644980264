import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useSettingsQuery } from 'src/store/api/settingsApi';
import defaultLogos from 'src/constants/defaultLogos';
import useIsCustomerPortal from './useIsCustomerPortal';

export default function useThemeLogos() {
  const { palette } = useTheme();
  const isCustomerPortal = useIsCustomerPortal();
  const { data } = useSettingsQuery(isCustomerPortal);

  const logoDict = useMemo(
    () => ({
      light: {
        logo: {
          src: data?.siteLogoUrl || defaultLogos.siteLogo.src,
          alt: defaultLogos.siteLogo.alt,
        },
        favicon: {
          src: data?.siteFaviconUrl || defaultLogos.siteFavicon.src,
          alt: defaultLogos.siteFavicon.alt,
          isDefault: !data?.siteFaviconUrl,
        },
      },
      dark: {
        logo: {
          src: data?.siteLogoDarkUrl || defaultLogos.siteLogoDark.src,
          alt: defaultLogos.siteLogoDark.alt,
        },
        favicon: {
          src: data?.siteFaviconDarkUrl || defaultLogos.siteFaviconDark.src,
          alt: defaultLogos.siteFaviconDark.alt,
          isDefault: !data?.siteFaviconDarkUrl,
        },
      },
    }),
    [data]
  );

  return logoDict[palette.mode] || logoDict.light;
}
