// @ts-nocheck
/* eslint-disable */
/* This file was generated by Unframer for Framer project 71fd72204da79e7e "Workbase App", do not edit manually */
// /:https://framerusercontent.com/modules/zZsnZtICb5U81dYmdPK3/lDWvdJCmKvhQveLfQRcn/HBfiOaHXW.js
import { fontStore, } from 'unframer';
fontStore.loadFonts(['CUSTOM;SanBase Regular',],);
var fonts = [{
  explicitInter: true,
  fonts: [{ family: 'SanBase Regular', source: 'custom', url: 'https://framerusercontent.com/assets/yst7FBAPGkl0ksj0P2rh10jmhQ.woff2', },],
},];
var css = [
  `.framer-zFOwJ .framer-styles-preset-1wbx5wr:not(.rich-text-wrapper), .framer-zFOwJ .framer-styles-preset-1wbx5wr.rich-text-wrapper p { --framer-font-family: "SanBase Regular", "SanBase Regular Placeholder", sans-serif; --framer-font-open-type-features: 'blwf' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv11' on; --framer-font-size: 14px; --framer-font-style: normal; --framer-font-variation-axes: normal; --framer-font-weight: 400; --framer-letter-spacing: 0px; --framer-line-height: 1em; --framer-paragraph-spacing: 14px; --framer-text-alignment: left; --framer-text-color: var(--token-ad22872e-8820-44b3-9dc3-0d1bf136ea27, #3f3f46); --framer-text-decoration: none; --framer-text-stroke-color: initial; --framer-text-stroke-width: initial; --framer-text-transform: none; }`,
  `@media (max-width: 329px) and (min-width: 0px) { .framer-zFOwJ .framer-styles-preset-1wbx5wr:not(.rich-text-wrapper), .framer-zFOwJ .framer-styles-preset-1wbx5wr.rich-text-wrapper p { --framer-font-family: "SanBase Regular", "SanBase Regular Placeholder", sans-serif; --framer-font-family-bold: "Inter", "Inter Placeholder", sans-serif; --framer-font-family-bold-italic: "Inter", "Inter Placeholder", sans-serif; --framer-font-family-italic: "Inter", "Inter Placeholder", sans-serif; --framer-font-open-type-features: 'blwf' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv11' on; --framer-font-size: 13px; --framer-font-style: normal; --framer-font-style-bold: normal; --framer-font-style-bold-italic: italic; --framer-font-style-italic: italic; --framer-font-variation-axes: normal; --framer-font-weight: 400; --framer-font-weight-bold: 700; --framer-font-weight-bold-italic: 700; --framer-font-weight-italic: 400; --framer-letter-spacing: 0px; --framer-line-height: 1em; --framer-paragraph-spacing: 14px; --framer-text-alignment: left; --framer-text-color: var(--token-ad22872e-8820-44b3-9dc3-0d1bf136ea27, #3f3f46); --framer-text-decoration: none; --framer-text-stroke-color: initial; --framer-text-stroke-width: initial; --framer-text-transform: none; } }`,
];
var className = 'framer-zFOwJ';

export { className, css, fonts, };
