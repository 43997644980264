import { useTranslation } from 'react-i18next';

export default function useDefaultLayoutTranslations(): any {
  const { t } = useTranslation();

  const defaultLayoutTranslations = {
    'Caption Styles': t('vidstackPlayer.Caption Styles'),
    'Captions look like this': t('vidstackPlayer.Captions look like this'),
    'Closed-Captions Off': t('vidstackPlayer.Closed-Captions Off'),
    'Closed-Captions On': t('vidstackPlayer.Closed-Captions On'),
    'Display Background': t('vidstackPlayer.Display Background'),
    'Enter Fullscreen': t('vidstackPlayer.Enter Fullscreen'),
    'Enter PiP': t('vidstackPlayer.Enter PiP'),
    'Exit Fullscreen': t('vidstackPlayer.Exit Fullscreen'),
    'Exit PiP': t('vidstackPlayer.Exit PiP'),
    'Google Cast': t('vidstackPlayer.Google Cast'),
    'Keyboard Animations': t('vidstackPlayer.Keyboard Animations'),
    'Seek Backward': t('vidstackPlayer.Seek Backward'),
    'Seek Forward': t('vidstackPlayer.Seek Forward'),
    'Skip To Live': t('vidstackPlayer.Skip To Live'),
    'Text Background': t('vidstackPlayer.Text Background'),
    Accessibility: t('vidstackPlayer.Accessibility'),
    AirPlay: t('vidstackPlayer.AirPlay'),
    Announcements: t('vidstackPlayer.Announcements'),
    Audio: t('vidstackPlayer.Audio'),
    Auto: t('vidstackPlayer.Auto'),
    Boost: t('vidstackPlayer.Boost'),
    Captions: t('vidstackPlayer.Captions'),
    Chapters: t('vidstackPlayer.Chapters'),
    Color: t('vidstackPlayer.Color'),
    Connected: t('vidstackPlayer.Connected'),
    Connecting: t('vidstackPlayer.Connecting'),
    Continue: t('vidstackPlayer.Continue'),
    Default: t('vidstackPlayer.Default'),
    Disabled: t('vidstackPlayer.Disabled'),
    Disconnected: t('vidstackPlayer.Disconnected'),
    Download: t('vidstackPlayer.Download'),
    Family: t('vidstackPlayer.Family'),
    Font: t('vidstackPlayer.Font'),
    Fullscreen: t('vidstackPlayer.Fullscreen'),
    LIVE: t('vidstackPlayer.LIVE'),
    Loop: t('vidstackPlayer.Loop'),
    Mute: t('vidstackPlayer.Mute'),
    Normal: t('vidstackPlayer.Normal'),
    Off: t('vidstackPlayer.Off'),
    Opacity: t('vidstackPlayer.Opacity'),
    Pause: t('vidstackPlayer.Pause'),
    PiP: t('vidstackPlayer.PiP'),
    Play: t('vidstackPlayer.Play'),
    Playback: t('vidstackPlayer.Playback'),
    Quality: t('vidstackPlayer.Quality'),
    Replay: t('vidstackPlayer.Replay'),
    Reset: t('vidstackPlayer.Reset'),
    Seek: t('vidstackPlayer.Seek'),
    Settings: t('vidstackPlayer.Settings'),
    Shadow: t('vidstackPlayer.Shadow'),
    Size: t('vidstackPlayer.Size'),
    Speed: t('vidstackPlayer.Speed'),
    Text: t('vidstackPlayer.Text'),
    Track: t('vidstackPlayer.Track'),
    Unmute: t('vidstackPlayer.Unmute'),
    Volume: t('vidstackPlayer.Volume'),
  };

  return defaultLayoutTranslations;
}
