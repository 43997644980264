import { useTheme } from '@mui/material';
import { useEffect } from 'react';
import { AffineEditorContainer } from '@blocksuite/presets';
import { ThemeProvider } from '@blocksuite/blocks';

export default function useEditorCssVariables(editor: AffineEditorContainer | undefined) {
  const theme = useTheme();

  const themeMode = theme.palette.mode;
  const paperColor = theme.palette.background.paper;
  const textPrimaryColor = theme.palette.text.primary;
  const hoverColor = theme.palette.action.hover;
  const primaryColor = theme.palette.primary.main;
  const dividerColor = theme.palette.divider;

  useEffect(() => {
    if (editor) {
      const root = document.documentElement;
      root.style.setProperty('--affine-editor-width', '1200px');
      root.style.setProperty('--affine-editor-side-padding', '24px');
      root.style.setProperty('--affine-text-primary-color', textPrimaryColor);
      root.style.setProperty('--affine-v2-layer-insideBorder-primaryBorder', primaryColor);
      root.style.setProperty('--affine-v2-layer-insideBorder-border', dividerColor);
      root.style.setProperty('--affine-primary-color', primaryColor);
      root.style.setProperty('--affine-hover-color', hoverColor);
      root.style.setProperty('--affine-border-color', dividerColor);
      root.style.setProperty('--affine-background-primary-color', paperColor);
      root.style.setProperty('--affine-background-overlay-panel-color', paperColor);
      root.style.setProperty('--affine-v2-layer-background-primary', paperColor);
      root.style.setProperty('--affine-v2-layer-background-overlayPanel', paperColor);
    }
  }, [editor, paperColor, textPrimaryColor, primaryColor, hoverColor, dividerColor]);

  useEffect(() => {
    if (editor) {
      const themeProvider = editor.std.get(ThemeProvider);
      const appTheme = themeMode === 'light' ? 'light' : 'dark';
      themeProvider.app$.value = appTheme as any;
    }
  }, [editor, themeMode]);
}
