import { InputAdornment, SvgIcon, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseLockIcon } from 'src/assets/icons/workbaseIcons';
import { isValidPhoneNumber } from 'libphonenumber-js';
import checkIfCountryCode from 'src/lib/utils/checkIfCountryCode';
import { useUnsavedChanges } from 'src/lib/utils/UnsavedChangesContext';
import { validateName } from 'src/lib/utils/validateName';

interface SharedFieldProps {
  label: string;
  initialVal: string;
  name: string;
  dataCy?: string;
}

interface EditableFieldSharedProps extends SharedFieldProps {
  onUpdate?: (name: string, value: string) => void;
}

export function RequiredFieldWithValidation({
  label,
  initialVal,
  name,
  dataCy,
  onUpdate,
}: EditableFieldSharedProps) {
  const [err, setErr] = useState('');
  const [val, setVal] = useState(initialVal);
  const { t } = useTranslation();
  const { setHasUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    if (err) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [err, setHasUnsavedChanges]);

  const validate = useCallback(
    (value: string): boolean => {
      const validation = validateName(value);
      if (!validation.isValid && validation.errorKey) {
        setErr(t(validation.errorKey));
        return true;
      }
      setErr('');
      return false;
    },
    [t]
  );

  useEffect(() => {
    validate(val);
  }, [val, validate]);

  return (
    <TextField
      size="small"
      label={label}
      data-cy={dataCy}
      value={val}
      onBlur={(e) => !err && onUpdate?.(name, e.target.value)}
      name={name}
      required
      onChange={(e) => setVal(e.target.value)}
      error={!!err}
      helperText={err}
    />
  );
}

export function PhoneField({
  label,
  initialVal,
  name,
  onUpdate,
  dataCy,
}: EditableFieldSharedProps) {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState(initialVal);
  const [err, setErr] = useState('');
  const handlePhoneValueChange = (newValue: string) => {
    setPhoneNumber(newValue);
  };

  const { setHasUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    if (err) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [err, setHasUnsavedChanges]);

  const phoneErrorMsg =
    t('validationErrorMessages.phoneErrorMsg') +
    (checkIfCountryCode(phoneNumber) ? '' : ` ${t('validationErrorMessages.missingCountryCode')}`);

  useEffect(() => {
    if (!isValidPhoneNumber(phoneNumber) && phoneNumber.trim() !== '') {
      setErr(phoneErrorMsg);
    } else {
      setErr('');
    }
  }, [phoneNumber, phoneErrorMsg]);

  return (
    <TextField
      label={label}
      value={phoneNumber}
      size="small"
      name={name}
      error={!!err}
      data-cy={dataCy}
      helperText={err}
      onBlur={(e) => !err && onUpdate?.(name, e.target.value.trim())}
      onChange={(e) => handlePhoneValueChange(e.target.value)}
    />
  );
}

export function EmailField({ label, initialVal, name, dataCy }: SharedFieldProps) {
  return (
    <TextField
      label={label}
      value={initialVal}
      name={name}
      disabled
      data-cy={dataCy}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SvgIcon component={WorkbaseLockIcon} htmlColor="inherit" fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
}
