// @ts-nocheck
/* eslint-disable */
/* This file was generated by Unframer for Framer project 71fd72204da79e7e "Workbase App", do not edit manually */
// /:https://framerusercontent.com/modules/DwWOdDzQZGCuvkNlbUjZ/y3zt4fv5F2ZtnBW8nZvn/FramerFormsShared.js
import { jsx as _jsx, jsxs as _jsxs, } from 'react/jsx-runtime';
import { ControlType, RenderTarget, withCSS, } from 'unframer';
import { forwardRef, useEffect as useEffect2, useRef, useState as useState2, } from 'react';
import { motion, } from 'unframer';

// /:https://framer.com/m/framer/store.js@^1.0.0
import { useEffect, useState, } from 'react';
import { Data, useObserveData, } from 'unframer';
function createStore(state1,) {
  const dataStore = Data({ state: Object.freeze({ ...state1, },), },);
  const setDataStore = (newState,) => {
    if (typeof newState === 'function') {
      newState = newState(dataStore.state,);
    }
    dataStore.state = Object.freeze({ ...dataStore.state, ...newState, },);
  };
  let storeState = typeof state1 === 'object' ? Object.freeze({ ...state1, },) : state1;
  const storeSetters = /* @__PURE__ */ new Set();
  const setStoreState = (newState,) => {
    if (typeof newState === 'function') {
      newState = newState(storeState,);
    }
    storeState = typeof newState === 'object' ? Object.freeze({ ...storeState, ...newState, },) : newState;
    storeSetters.forEach((setter,) => setter(storeState,));
  };
  function useStore() {
    const [state, setState,] = useState(storeState,);
    useEffect(() => {
      storeSetters.add(setState,);
      return () => storeSetters.delete(setState,);
    }, [],);
    if (useObserveData() === true) {
      useObserveData();
      return [dataStore.state, setDataStore,];
    } else {
      return [state, setStoreState,];
    }
  }
  return useStore;
}

// /:https://framerusercontent.com/modules/DwWOdDzQZGCuvkNlbUjZ/y3zt4fv5F2ZtnBW8nZvn/FramerFormsShared.js
var useMultiStepFormStore = createStore({},);
var HIDDEN_CLASS_NAME = 'framerforms-hidden';
var CANVAS_HIDDEN_CLASS_NAME = 'framerforms-canvas-hidden';
var createId = () => 'a' + String(Math.floor(Math.random() * 999999999,),);
var HideElementComponent = /* @__PURE__ */ forwardRef(({}, ref,) => {
  return /* @__PURE__ */ _jsx('div', { ref, className: HIDDEN_CLASS_NAME, },);
},);
var HideElement = withCSS(HideElementComponent, [
  `div:has(> .${HIDDEN_CLASS_NAME}) { display: none; }`,
  `.${HIDDEN_CLASS_NAME} { display: none; }`,
], HIDDEN_CLASS_NAME,);
HideElement.displayName = 'FramerForms/Hide Element';
var HiddenComponentLabel = /* @__PURE__ */ forwardRef(({ text, subtext = '', children = null, ...otherProps }, ref,) => {
  const isCanvas = RenderTarget.current() === RenderTarget.canvas;
  const id = createId();
  return isCanvas
    ? /* @__PURE__ */ _jsxs('div', {
      'data-framerforms': true,
      ...otherProps,
      ref,
      className: CANVAS_HIDDEN_CLASS_NAME,
      style: {
        backgroundColor: 'rgba(187, 187, 187, 0.15)',
        borderRadius: 10,
        padding: '12px 16px',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 12,
        color: '#999',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        textWrap: 'nowrap',
      },
      children: [
        children,
        text,
        subtext && /* @__PURE__ */ _jsx('span', { style: { opacity: 0.7, whiteSpace: 'pre', lineHeight: 1.4, }, children: subtext, },),
        /* @__PURE__ */ _jsx(Border, { width: '1px', style: 'solid', borderColor: 'rgba(136, 136, 136, 0.1)', },),
        /* @__PURE__ */ _jsx('style', {
          children: `
                [data-framer-component-container="true"] div:has(> .${CANVAS_HIDDEN_CLASS_NAME}) {
                    display: none !important;
                }`,
        },),
      ],
    },)
    : /* @__PURE__ */ _jsxs('div', {
      ...otherProps,
      ref,
      className: HIDDEN_CLASS_NAME,
      children: [/* @__PURE__ */ _jsx(HideElement, {},), children,],
    },);
},);
HiddenComponentLabel.displayName = 'FramerForms/Hidden Component Label';
function Icon({ image, color, size, style = {}, defaultImage, },) {
  return /* @__PURE__ */ _jsx('div', {
    style: {
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
      pointerEvents: 'none',
      backgroundColor: color,
      backgroundImage: color ? 'none' : image ? `url('${image}')` : defaultImage,
      maskImage: image ? `url('${image}')` : defaultImage,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      maskSize: 'contain',
      maskPosition: 'center',
      ...style,
    },
  },);
}
Icon.displayName = 'FramerForms/Icon';
function Border({ width, style, transition = null, borderColor = 'inherit', opacity = 1, },) {
  return /* @__PURE__ */ _jsx(motion.div, {
    animate: { borderColor, opacity, },
    style: { position: 'absolute', inset: 0, borderWidth: width, borderStyle: style, borderRadius: 'inherit', pointerEvents: 'none', },
    initial: false,
    transition,
  },);
}
Border.displayName = 'FramerForms/Border';
function createBackground(fill, on = null, focused = null, invalid = null,) {
  if (fill) {
    const onOff = typeof on == 'boolean';
    if (fill.type == 'color') {
      let color = invalid && fill.colorInvalid || focused && fill.colorFocus || (onOff ? on ? fill.colorOn : fill.colorOff : fill.color);
      return { backgroundColor: color, backgroundImage: 'none', };
    } else {
      const colorA = invalid && fill.colorAInvalid || focused && fill.colorAFocus ||
        (onOff ? on ? fill.colorAOn : fill.colorAOff : fill.colorA);
      const colorB = invalid && fill.colorBInvalid || focused && fill.colorBFocus ||
        (onOff ? on ? fill.colorBOn : fill.colorBOff : fill.colorB);
      return { backgroundColor: 'none', backgroundImage: `linear-gradient(${fill.gradientAngle}deg, ${colorB}, ${colorA})`, };
    }
  }
  return {};
}
function parsePadding(padding,) {
  if (typeof padding !== 'string') {
    return [0, 0, 0, 0,];
  }
  const values = padding.split(' ',);
  const parsedValues = values.map((value,) => parseInt(value.replace('px', '',), 10,));
  switch (parsedValues.length) {
    case 1:
      const p = parsedValues[0];
      return [p, p, p, p,];
    case 4:
      return parsedValues;
  }
  return [0, 0, 0, 0,];
}
function fillProp(
  {
    onOff = false,
    focus = true,
    invalid = true,
    optional = false,
    hidden = null,
    color = 'rgba(187, 187, 187, 0.15)',
    colorA = '#BBB',
    colorB = '#676767',
    colorOn = 'rgba(70, 160, 219, 0.2)',
    colorAOn = '#66C2FF',
    colorBOn = '#0099FF',
    colorOff = 'rgba(187, 187, 187, 0.15)',
    colorAOff = '#BBB',
    colorBOff = '#676767',
  } = {},
) {
  return {
    type: ControlType.Object,
    optional,
    hidden,
    icon: 'color',
    defaultValue: { type: 'color', ...onOff ? { colorOn, colorOff, } : { color, colorA, colorB, }, },
    controls: {
      type: {
        type: ControlType.Enum,
        defaultValue: 'color',
        options: ['color', 'linearGradient',],
        optionTitles: ['Color', 'Gradient',],
        displaySegmentedControl: true,
      },
      ...onOff
        ? {
          colorOn: { type: ControlType.Color, defaultValue: colorOn, hidden: (props,) => props.type !== 'color', },
          colorOff: { type: ControlType.Color, defaultValue: colorOff, hidden: (props,) => props.type !== 'color', },
        }
        : { color: { type: ControlType.Color, defaultValue: color, hidden: (props,) => props.type !== 'color', }, },
      ...focus
        ? { colorFocus: { type: ControlType.Color, optional: true, title: 'Focus', hidden: (props,) => props.type !== 'color', }, }
        : {},
      ...invalid
        ? { colorInvalid: { type: ControlType.Color, optional: true, title: 'Invalid', hidden: (props,) => props.type !== 'color', }, }
        : {},
      ...onOff
        ? {
          colorAOn: {
            type: ControlType.Color,
            defaultValue: colorAOn,
            title: 'Colors On',
            hidden: (props,) => props.type !== 'linearGradient',
          },
          colorBOn: { type: ControlType.Color, defaultValue: colorBOn, title: ' ', hidden: (props,) => props.type !== 'linearGradient', },
          colorAOff: {
            type: ControlType.Color,
            defaultValue: colorAOff,
            title: 'Colors Off',
            hidden: (props,) => props.type !== 'linearGradient',
          },
          colorBOff: { type: ControlType.Color, defaultValue: colorBOff, title: ' ', hidden: (props,) => props.type !== 'linearGradient', },
        }
        : {
          colorA: { type: ControlType.Color, defaultValue: colorA, title: 'Colors', hidden: (props,) => props.type !== 'linearGradient', },
          colorB: { type: ControlType.Color, defaultValue: colorB, title: ' ', hidden: (props,) => props.type !== 'linearGradient', },
        },
      ...focus
        ? {
          colorAFocus: { type: ControlType.Color, optional: true, title: 'Focus', hidden: (props,) => props.type !== 'linearGradient', },
          colorBFocus: { type: ControlType.Color, optional: true, title: ' ', hidden: (props,) => props.type !== 'linearGradient', },
        }
        : {},
      ...invalid
        ? {
          colorAInvalid: {
            type: ControlType.Color,
            optional: true,
            title: 'Invalid',
            hidden: (props,) => props.type !== 'linearGradient',
          },
          colorBInvalid: { type: ControlType.Color, optional: true, title: ' ', hidden: (props,) => props.type !== 'linearGradient', },
        }
        : {},
      gradientAngle: {
        type: ControlType.Number,
        defaultValue: 0,
        title: 'Angle',
        step: 1,
        min: 0,
        max: 360,
        unit: '\xB0',
        hidden: (props,) => props.type != 'linearGradient',
      },
    },
  };
}
function borderProp(
  {
    onOff = false,
    focus = true,
    invalid = true,
    defaultValue = true,
    color = 'rgba(136, 136, 136, 0.1)',
    colorOn = '#0099FF',
    colorOff = 'rgba(136, 136, 136, 0.1)',
    colorFocus = '#0099FF',
    colorInvalid = '#FF5A6F',
    hidden = null,
  } = {},
) {
  return {
    type: ControlType.Object,
    optional: true,
    hidden,
    defaultValue: defaultValue ? { color, colorOn, colorOff, colorFocus, colorInvalid, width: '1px', } : void 0,
    controls: {
      ...onOff
        ? { colorOn: { type: ControlType.Color, defaultValue: colorOn, }, colorOff: { type: ControlType.Color, defaultValue: colorOff, }, }
        : { color: { type: ControlType.Color, defaultValue: color, }, },
      ...focus ? { colorFocus: { type: ControlType.Color, defaultValue: colorFocus, optional: true, title: 'Focus', }, } : {},
      ...invalid ? { colorInvalid: { type: ControlType.Color, defaultValue: colorInvalid, optional: true, title: 'Invalid', }, } : {},
      width: { type: ControlType.Padding, defaultValue: '1px', },
      style: {
        type: ControlType.Enum,
        defaultValue: 'solid',
        options: ['solid', 'dashed', 'dotted', 'double',],
        optionTitles: ['Solid', 'Dashed', 'Dotted', 'Double',],
      },
    },
  };
}
function shadowsProp({ invalid = true, onOff = false, } = {},) {
  return {
    type: ControlType.Object,
    buttonTitle: 'Shadows',
    optional: true,
    controls: {
      ...onOff
        ? {
          on: { type: ControlType.BoxShadow, defaultValue: 'rgba(0, 153, 255, 0.5) 0px 2px 4px 0px', },
          off: { type: ControlType.BoxShadow, defaultValue: 'rgba(0, 0, 0, 0.25) 0px 2px 4px 0px', },
        }
        : { default: { type: ControlType.BoxShadow, defaultValue: 'rgba(0, 0, 0, 0.25) 0px 2px 4px 0px', }, },
      focus: { type: ControlType.BoxShadow, },
      invalid: invalid ? { type: ControlType.BoxShadow, } : null,
    },
  };
}

export { Border, borderProp, createBackground, createId, fillProp, Icon, parsePadding, shadowsProp, };
