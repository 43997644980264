import { noSpecialCharsRegex } from './stringValidation';

interface ValidationResult {
  isValid: boolean;
  errorKey?: string;
}

/**
 * Validates a name or surname string
 * @param value - The name/surname to validate
 * @returns ValidationResult object containing validation status and error message key if invalid
 */
export function validateName(value: string): ValidationResult {
  if (value.trim() === '') {
    return {
      isValid: false,
      errorKey: 'validationErrorMessages.requiredErrorMsg',
    };
  }

  if (!noSpecialCharsRegex.test(value)) {
    return {
      isValid: false,
      errorKey: 'validationErrorMessages.specialCharsErrorMsg',
    };
  }

  if (value.length > 35) {
    return {
      isValid: false,
      errorKey: 'validationErrorMessages.fieldLengthErrorMsg',
    };
  }

  return { isValid: true };
}
