import type { BlobSource } from '@blocksuite/sync';
import axiosInstance from 'src/axios';

export class BlobSourceServer implements BlobSource {
  private readonly _cache = new Map<string, Blob>();

  readonly = false;

  constructor(readonly name: string) {}

  async delete(key: string) {
    this._cache.delete(key);
    await axiosInstance.delete(`media/editor/${this.name}/item/${key}`);
  }

  async get(key: string) {
    if (this._cache.has(key)) {
      return this._cache.get(key) as Blob;
    } else {
      const blob = await axiosInstance
        .get(`media/editor/${this.name}/item/${key}`, {
          method: 'GET',
          responseType: 'blob',
        })
        .then(async (response: any) => {
          return response.data;
        })
        .catch(() => {
          return null;
        });
      if (blob === null) return blob;
      this._cache.set(key, blob);

      return blob;
    }
  }

  async list() {
    return Array.from(this._cache.keys());
  }

  async set(key: string, value: Blob) {
    this._cache.set(key, value);
    const blob = await value.arrayBuffer();
    const file = new File([blob], key, { type: value.type });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('documentName', this.name);
    formData.append('documentItemId', key);
    await axiosInstance.put(`media/direct-upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return key;
  }
}
