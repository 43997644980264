import type { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { createListenerMiddleware, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { userPermissionsApi } from '../api/userPermissionsApi';
import { trainingsApi } from '../api/trainings/trainingsApi';
import { apiKeysApi } from '../api/apiKeysApi';
import { shareResourceApi } from '../api/shareResourceApi';
import { searchApi } from '../api/searchApi';
import { companyUsersApi } from '../api/company-users/companyUsersApi';
import { wikiApi } from '../api/wikiApi';

interface UserPortalModeState {
  currentPortal: PortalType;
  previewMode: boolean;
  previewGroupIds: string[];
}

const initialState: UserPortalModeState = {
  currentPortal: 'employeePortal',
  previewMode: false,
  previewGroupIds: [],
};

export type PortalType = 'customerPortal' | 'employeePortal';

export const userPortalSlice = createSlice({
  name: 'userPortal',
  initialState,
  reducers: {
    switchPortal: (state, action: PayloadAction<PortalType>) => {
      state.currentPortal = action.payload;
    },
    setPreviewMode: (state, action: PayloadAction<boolean>) => {
      state.previewMode = action.payload;
    },
    setPreviewGroupIds: (state, action: PayloadAction<string[]>) => {
      state.previewGroupIds = action.payload;
    },
    resetUserPortalState: () => initialState,
  },
});

export const userPortalMiddleware = createListenerMiddleware();

const invalidateData = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(userPermissionsApi.util.invalidateTags(['UserPermissions']));
  dispatch(trainingsApi.util.invalidateTags(['TrainingsByUserId']));
  dispatch(apiKeysApi.util.invalidateTags(['ApiKeys']));
  dispatch(shareResourceApi.util.invalidateTags(['ShareLevel']));
  dispatch(searchApi.util.invalidateTags(['Search']));
  dispatch(companyUsersApi.util.invalidateTags(['CompanyUsersTrainingProgress']));
  dispatch(wikiApi.util.invalidateTags(['Wiki', 'WikiSidebar']));
};

userPortalMiddleware.startListening({
  matcher: isAnyOf(
    userPortalSlice.actions.switchPortal,
    userPortalSlice.actions.setPreviewMode,
    userPortalSlice.actions.setPreviewGroupIds
  ),
  effect: async (_, { dispatch }) => {
    invalidateData(dispatch);
  },
});

export const { switchPortal, setPreviewMode, setPreviewGroupIds, resetUserPortalState } =
  userPortalSlice.actions;
