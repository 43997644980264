// import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'src/store';
// import { AUTH_URL } from '../../global-config';
// import useThemeLogos from 'src/hooks/useThemeLogos';
import OnboardingView from '../onboarding/OnboardingView';

function UnauthorizedView() {
  // const { t } = useTranslation();
  // const { logo } = useThemeLogos();
  const refreshToken = useAppSelector((state) => state.auth.refreshToken);
  if (refreshToken) {
    return <Navigate to="/" />;
  }
  // const redirectionUrl = new URL(`login?redirect=${window.location.hostname}`, `${AUTH_URL}`).href;
  return <OnboardingView fixedScreen="Login - From Company" />;
}

export default UnauthorizedView;
