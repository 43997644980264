import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Container, Paper, Stack, SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCreateThreadMutation } from 'src/store/api/aiChatApi';
import { WorkbaseArrowDownIcon } from 'src/assets/icons/workbaseIcons';
import { loadAllUserData } from 'src/features/ai-chat/helpers/use-socket';
import {
  useChatDispatch,
  useChatSocket,
  useChatState,
} from 'src/features/ai-chat/helpers/chatContext';
import { useDropzone } from 'react-dropzone';
import { imageUpload } from 'src/lib/utils/imageUpload';
import AiDropZone from 'src/features/ai-chat/views/components/AiDropZone';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';
import MessagesList from '../../features/ai-chat/views/components/MessageList';
import DialogInputs from '../../features/ai-chat/views/components/DialogInputs';
import BasicActionsList from '../../features/ai-chat/views/components/BasicActionsList';
import ChatTopBar from '../../features/ai-chat/views/components/ChatTopBar';
import { useAppSelector } from 'src/store';

// eslint-disable-next-line max-statements,
function ChatPanel({ isMobile }: { isMobile: boolean }) {
  const dispatch = useChatDispatch();
  const state = useChatState((state) => ({
    threadId: state.threadId,
    isOpen: state.isOpen,
    isFullScreen: state.isFullScreen,
    locationBeforeFullscreen: state.locationBeforeFullscreen,
    messages: state.messages,
  }));
  const { sendJsonMessage } = useChatSocket();
  const navigate = useNavigate();
  const [createThread] = useCreateThreadMutation();
  const portalMode = useAppSelector(({ userPortal }) => userPortal.currentPortal);
  const userType = useAppSelector(({ auth }) => auth.userType);
  const hasAnyPermission = useHasAnyPermission();
  // TODO: remove this after testing
  const canAccessAiAssistant =
    hasAnyPermission([PermissionCodes.ACCESS_AI_ASSISTANT]) || userType === 'customer';
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      imageUpload(acceptedFiles[0]).then((response) => {
        const { variants } = response.data.result;
        dispatch({
          type: 'SET_IMAGE_URL',
          payload: {
            type: 'image',
            file_name: acceptedFiles[0].name,
            image: variants[0],
          },
        });
      });
    },
    [dispatch]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
    },
    noClick: true,
  });

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const handleSendMessage = useCallback(
    async (message: string, retry: boolean = false) => {
      if (!canAccessAiAssistant) return;
      let currentThreadId = state.threadId;
      if (!retry) {
        dispatch({
          type: 'SEND_CHAT_MESSAGE',
          payload: {
            thread_id: currentThreadId ?? 'temp',
            role: 'user',
            content: [{ type: 'text', text: message }],
            createdAt: new Date().getTime(),
          },
        });
      }
      dispatch({ type: 'SET_WAITING_RESPONSE', payload: true });
      if (!currentThreadId) {
        const response = await createThread().unwrap();
        currentThreadId = response.id;
        dispatch({ type: 'SET_SELECTED_CHAT', payload: response.id });
        if (state.isFullScreen || isMobile) {
          navigate(`/ai/${response.id}`);
        }
      }

      dispatch({ type: 'WEBSOCKET_ERROR', payload: null });
      sendJsonMessage({
        type: 'content',
        content: [{ type: 'text', text: message }],
        metadata: { ...loadAllUserData(portalMode), retry, resources: [] },
        threadId: currentThreadId,
      });
    },
    [
      createThread,
      sendJsonMessage,
      dispatch,
      state.isFullScreen,
      state.threadId,
      isMobile,
      navigate,
      canAccessAiAssistant,
      portalMode,
    ]
  );

  useEffect(
    () => () => {
      if (state.isFullScreen && !window.location.pathname.startsWith('/ai')) {
        dispatch({ type: 'CLOSE_FULLSCREEN' });
      }
    },
    [state.isFullScreen, dispatch]
  );

  useEffect(() => {
    if (state.messages.length && !showScrollButton) {
      scrollToBottom();
    }
  }, [state.messages, showScrollButton]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        setShowScrollButton(scrollHeight - scrollTop - clientHeight > 20);
      }
    };

    const container = scrollRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    } else {
      setShowScrollButton(false);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef.current, state.messages]);

  const content = useMemo(
    () =>
      state.messages.length ? (
        <MessagesList
          data-cy="chat-panel-messages-list"
          messages={state.messages}
          onSendMessage={handleSendMessage}
          ref={scrollRef}
          isScrolled={showScrollButton}
          messagesEndRef={messagesEndRef}
        />
      ) : (
        <Container
          component={Stack}
          flexGrow={1}
          disableGutters
          data-cy="chat-panel-basic-actions-list"
        >
          <BasicActionsList onSendMessage={handleSendMessage} />
        </Container>
      ),
    [state.messages, showScrollButton, handleSendMessage, scrollRef, messagesEndRef]
  );

  if ((!state.isOpen && !state.threadId) || !canAccessAiAssistant) return null;

  return (
    <Paper
      data-cy="chat-panel"
      variant={state.isFullScreen ? 'elevation' : 'outlined'}
      elevation={0}
      component={Stack}
      overflow="hidden"
      width="100%"
      position="relative"
      border={state.isFullScreen || isMobile ? 'none' : undefined}
      flexDirection="column"
      height="100%"
      {...getRootProps()}
    >
      <AiDropZone getInputProps={getInputProps} isDragActive={isDragActive} />
      <ChatTopBar
        data-cy="chat-panel-top-bar"
        dispatch={dispatch}
        navigate={navigate}
        isFullScreen={state.isFullScreen}
        threadId={state.threadId as string}
        locationBeforeFullscreen={state.locationBeforeFullscreen}
      />
      {content}
      <Stack justifyContent="center" alignItems="center" position="relative">
        {showScrollButton && (
          <Stack position="relative">
            <Stack
              position="absolute"
              right="50%"
              sx={{ transform: 'translateY(-150%) translateX(50%)' }}
            >
              <Button
                variant="contained"
                color="inherit"
                onClick={scrollToBottom}
                data-cy="chat-panel-scroll-to-bottom-button"
              >
                <SvgIcon component={WorkbaseArrowDownIcon} fontSize="small" htmlColor="inherit" />
              </Button>
            </Stack>
          </Stack>
        )}
        <DialogInputs data-cy="chat-panel-dialog-inputs" />
      </Stack>
    </Paper>
  );
}

export default memo(ChatPanel);
