import { MediaPlayerInstance, MediaProvider, Poster, Track } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef } from 'react';
import { useLazyVideoMetadataQuery } from 'src/store/api/videos';
import isTouchDevice from 'src/lib/utils/isTouchDevice';
import {
  useGetVideoProgressQuery,
  usePostVideoProgressMutation,
  VideoProgress,
} from 'src/store/api/trainings/videoProgressApi';
import useVideoProgress from './hooks/useVideoProgress';
import useDefaultLayoutTranslations from './hooks/useDefaultLayoutTranslations';
import CustomBufferingIndicator from './CustomBufferingIndicator';
import StyledMediaPlayer from './StyledMediaPlayer';
import useEncodingProgress from './hooks/useEncodingProgress';
import useInitializePlayer from './hooks/useInitializePlayer';
import MediaStorage from './MediaStorage';
import { throttle } from 'lodash';

interface Props {
  poster: string;
  disabledFastForward?: boolean;
  canPlay?: boolean;
  videoId: string;
}

export default function VidstackPlayer({
  poster,
  disabledFastForward = false,
  canPlay = true,
  videoId,
}: Props) {
  const { t } = useTranslation();
  const playerRef = useRef<MediaPlayerInstance>(null);
  const translations = useDefaultLayoutTranslations();
  const { palette } = useTheme();
  const { data: videoProgressData } = useGetVideoProgressQuery(videoId || '', {
    skip: !disabledFastForward,
  });
  const [postVideoProgress] = usePostVideoProgressMutation();

  const handleVideoProgress = useCallback(
    async (data: Partial<VideoProgress>) => {
      await postVideoProgress({ id: videoId || '', data });
    },
    [postVideoProgress, videoId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleHandleVideoProgress = useCallback(
    throttle((data: Partial<VideoProgress>) => {
      handleVideoProgress(data);
    }, 2000),
    [handleVideoProgress]
  );

  const watchedDuration = videoProgressData?.watchedDuration;

  const { handleTimeUpdate, handleEnded } = useVideoProgress({
    playerRef,
    watchedDuration,
    disabledFastForward,
    onVideoProgress: throttleHandleVideoProgress,
  });

  const { handlePlayRequest, handleMouseDown } = useInitializePlayer(canPlay);

  const [fetchVideoMetadataQuery, { data, isError }] = useLazyVideoMetadataQuery();
  const src = data?.hlsPlaylistUrl;
  const encodingProgress = useEncodingProgress(videoId, isError ? null : src || null);

  const progress =
    disabledFastForward && playerRef.current?.duration && watchedDuration
      ? watchedDuration / (playerRef.current?.duration || 1)
      : undefined;

  useEffect(() => {
    if (encodingProgress === 100) {
      fetchVideoMetadataQuery(videoId);
    }
  }, [fetchVideoMetadataQuery, videoId, encodingProgress]);
  const transcription = data?.transcriptions[0];
  return (
    <StyledMediaPlayer
      key={poster}
      ref={playerRef}
      style={{ height: '100%', pointerEvents: src ? 'auto' : 'none' }}
      src={src || undefined}
      viewType="video"
      streamType="on-demand"
      logLevel="warn"
      crossOrigin
      playsInline
      controlsDelay={1500}
      poster={poster}
      onMouseDown={handleMouseDown}
      $progress={progress}
      onTimeUpdate={handleTimeUpdate}
      onEnded={handleEnded}
      storage={MediaStorage}
      onMediaPlayRequest={handlePlayRequest}
    >
      <MediaProvider>
        <Poster className="vds-poster" />
        <Track src={data?.chapters || undefined} kind="chapters" type="vtt" default />
        {transcription && transcription.languageCode !== 'und' && (
          <Track
            src={transcription?.url || undefined}
            kind="subtitles"
            label={t(`languages.${transcription.languageCode}`)}
            type="vtt"
            default
          />
        )}
      </MediaProvider>
      <DefaultVideoLayout
        icons={defaultLayoutIcons}
        translations={translations}
        colorScheme={palette.mode}
        noModal={!isTouchDevice()}
        thumbnails={data?.previewThumbnailVttUrl}
        slots={{
          bufferingIndicator: (
            <CustomBufferingIndicator src={src || null} encodingProgress={encodingProgress} t={t} />
          ),
          googleCastButton: null,
        }}
      />
    </StyledMediaPlayer>
  );
}
