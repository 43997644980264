import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function ShortcutBox({ children }: Props) {
  return (
    <Box
      px={0.5}
      minWidth={24}
      height={24}
      bgcolor="action.selected"
      borderRadius={0.5}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Box>
  );
}
