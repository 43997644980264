import { PaletteMode } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ThemeSettingsState {
  paletteMode: CustomPaletteMode;
}

type CustomPaletteMode = PaletteMode | 'auto';

const initialState: ThemeSettingsState = {
  paletteMode: 'dark',
};

export const themeSettingsSlice = createSlice({
  name: 'themeSettings',
  initialState,
  reducers: {
    setPaletteMode: (state, action: PayloadAction<CustomPaletteMode>) => ({
      ...state,
      paletteMode: action.payload,
    }),
  },
});

export const { setPaletteMode } = themeSettingsSlice.actions;
