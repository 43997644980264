import { useNewsfeedQuery } from 'src/store/api/newsfeedApi';
import { Box, Skeleton } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import Slider from 'src/ui-components/slider/Slider';
import NewsfeedSliderItem from './NewsfeedSliderItem';
import EmptyContent from './EmptyContent';
import {
  setSelectedCustomerPortalNewsfeedItemIdx,
  setSelectedEmployeePortalNewsfeedItemIdx,
} from 'src/store/client/dashboardSlice';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';

export default function Newsfeed() {
  'use no memo';
  const isCustomerPortal = useIsCustomerPortal();

  const selectedIdx = useAppSelector((state) =>
    !isCustomerPortal
      ? state.dashboard.selectedEmployeePortalNewsfeedItemIdx
      : state.dashboard.selectedCustomerPortalNewsfeedItemIdx
  );
  const dispatch = useAppDispatch();
  const { data, isLoading } = useNewsfeedQuery(isCustomerPortal, {
    refetchOnMountOrArgChange: true,
  });
  const isContentEmpty = data?.length === 0;

  const nonEmptyContent =
    isLoading && !data ? (
      <Skeleton variant="rectangular" width="100%" height="100%" />
    ) : (
      <Slider
        infinite
        initialSlide={selectedIdx}
        afterChange={(idx) =>
          dispatch(
            !isCustomerPortal
              ? setSelectedEmployeePortalNewsfeedItemIdx(idx)
              : setSelectedCustomerPortalNewsfeedItemIdx(idx)
          )
        }
      >
        {data?.map((item) => (
          <NewsfeedSliderItem key={item.type === 'training' ? item.id : item.id.lesson} {...item} />
        ))}
      </Slider>
    );
  const content = isContentEmpty ? <EmptyContent /> : nonEmptyContent;
  return (
    <Box width="100%" height={208}>
      {content}
    </Box>
  );
}
