import axiosInstance from 'src/axios';
import { API_URL } from 'src/global-config';

export interface UploadOptions {
  file: File;
  errorMessage: string;
  setPercentage?: (percentage: number) => void;
  onFileUploadSuccess: (id: string) => void;
  onFileUploadError: (id: string) => void;
  filename?: string;
  controller: AbortController;
}

const getFileUploadEndpoint = async (file: any) => {
  const resp = await axiosInstance.post(`${API_URL}/files/upload`, {
    filename: file.name,
    fileType: file.type,
    fileSize: file.size,
  });

  return resp.data;
};

const uploadFileToGoogleCloud = (
  url: string,
  file: File,
  setPercentage: (c: number) => void,
  controller: AbortController | undefined
  // eslint-disable-next-line max-params
) =>
  new Promise<void>((resolve, reject) => {
    const uploadProgressKey = `upload-progress-${file.name}-${file.lastModified}`;
    const savedUploadProgress = localStorage.getItem(uploadProgressKey);
    const chunkSize = 1024 * 1024 * 2; // 2mb
    let bytesUploaded = savedUploadProgress ? parseInt(savedUploadProgress, 10) : 0;
    const fileSize = file.size;

    const uploadChunks = (location: string) => {
      const uploadNextChunk = () => {
        if (controller?.signal?.aborted) {
          reject(new Error('Upload aborted'));
          return;
        }

        const updateProgress = (uploaded: number, total: number) => {
          if (setPercentage) {
            const percent = Math.ceil((uploaded / total) * 100);
            setPercentage(percent);
          }
        };

        const end = Math.min(bytesUploaded + chunkSize, fileSize);
        const chunk = file.slice(bytesUploaded, end);

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', location, true);
        xhr.setRequestHeader('Content-Range', `bytes ${bytesUploaded}-${end - 1}/${fileSize}`);

        xhr.onload = () => {
          if (xhr.status === 308) {
            // Incomplete, continue uploading
            bytesUploaded = end;
            updateProgress(bytesUploaded, fileSize);
            localStorage.setItem(
              `upload-progress-${file.name}-${file.lastModified}`,
              bytesUploaded.toString()
            );
            uploadNextChunk();
          } else if (xhr.status === 200) {
            // Upload completed
            updateProgress(fileSize, fileSize);
            localStorage.removeItem(`upload-url-${file.name}-${file.lastModified}`);
            localStorage.removeItem(`upload-progress-${file.name}-${file.lastModified}`);
            resolve();
          } else {
            reject(new Error(`Upload failed: ${xhr.status}`));
          }
        };

        xhr.onabort = () => {
          reject(new Error('Upload aborted'));
        };

        xhr.onerror = () => {
          reject(new Error('Error during upload'));
        };

        controller?.signal?.addEventListener('abort', () => {
          xhr.abort();
        });

        xhr.send(chunk);
      };

      uploadNextChunk();
    };

    const initiateUpload = async () => {
      if (controller?.signal?.aborted) {
        reject(new Error('Upload aborted'));
        return;
      }

      const xhr = new XMLHttpRequest();
      if (!savedUploadProgress) {
        xhr.open('POST', url, true);
        xhr.setRequestHeader('X-Goog-Resumable', 'start');
        xhr.setRequestHeader('Content-Type', file.type);

        xhr.onload = () => {
          if (xhr.status === 201) {
            const location = xhr.getResponseHeader('Location');
            if (location) {
              localStorage.setItem(`upload-url-${file.name}-${file.lastModified}`, location);
              uploadChunks(location);
            } else {
              reject(new Error('Failed to get upload location'));
            }
          } else {
            reject(new Error(`Failed to initiate upload: ${xhr.status}`));
          }
        };

        xhr.onerror = () => {
          reject(new Error('Error initiating upload'));
        };
        controller?.signal?.addEventListener('abort', () => {
          xhr.abort();
        });

        xhr.send();
      } else {
        await uploadChunks(savedUploadProgress);
      }
    };

    initiateUpload();
  });

export { getFileUploadEndpoint, uploadFileToGoogleCloud };
