import { useEffect, useState } from 'react';
import VidstackPlayer from './vidstack-player';
import { useDropzone } from 'react-dropzone';
import { Box, Skeleton } from '@mui/material';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import { getFirstFrameFromVideoFile } from 'src/lib/utils/videoFrame';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { useUpload } from '../upload/UploadContext';
import UploadProgress from './UploadProgress';
import { VideoBlockModel } from 'src/features/editor2/blocks/video/videoSchema';
import RecordingModal from './RecordingModal';

export const MAX_FILESIZE = 100 * 1024 * 1024 * 1024; // 100GB

export interface VideoItemProps {
  id: string;
  videoId?: string;
  posterSrc?: string | null;
  collectionId: string;
  onDeleteBlock: () => void;
  onUpdateBlockData: (blockDataToUpdate: Partial<VideoBlockModel>) => void;
  disabledFastForward?: boolean;
}

export default function VideoItem({
  id,
  videoId,
  collectionId,
  posterSrc,
  onDeleteBlock,
  onUpdateBlockData,
  disabledFastForward,
}: VideoItemProps) {
  const [fileSize, setFileSize] = useState<null | number>(null);
  const { t } = useTranslation();
  const { uploadVideo, progress, cancelUpload, removeFile, clearProgress } = useUpload();
  const [recordingModalOpen, setRecordingModalOpen] = useState(false);

  // eslint-disable-next-line max-statements
  const handleVideoUpload = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file.size > MAX_FILESIZE) {
      showNotification(t('validationErrorMessages.UploadFileSizeErrorMessage'), 'error');
      onDeleteBlock();
      return;
    }
    setFileSize(file.size);
    let currentFrame: Blob | null = null;
    setRecordingModalOpen(false);
    try {
      currentFrame = await getFirstFrameFromVideoFile(file);
    } catch (_) {
      showNotification(t('validationErrorMessages.generalUploadErrorMessage'), 'error');
      onDeleteBlock();
      return;
    }

    let poster: string | undefined;

    if (currentFrame) {
      const response = await imageUpload(new File([currentFrame], 'poster.jpg'));
      if (response.status !== 201) {
        showNotification(t('validationErrorMessages.generalUploadErrorMessage'), 'error');
        onDeleteBlock();
        return;
      }

      const { result } = response.data;
      const { variants } = result;
      const [imageUrl] = variants;
      poster = imageUrl;
    }

    const onVideoUploadSuccess = async (videoId: string) => {
      onUpdateBlockData({
        videoId,
        posterSrc: poster,
        videoName: file.name,
      });
    };
    try {
      await uploadVideo({
        file,
        errorMessage: t('validationErrorMessages.UploadFailed'),
        onVideoUploadSuccess,
        documentName: collectionId,
        resource: {
          type: collectionId?.split('.')[0] as string,
          id: collectionId?.split('.')[1] as string,
          name: file.name,
          route: window.location.pathname,
        },
        elementId: id,
        posterUrl: poster,
      });
    } catch {
      onDeleteBlock();
      showNotification(t('validationErrorMessages.UploadFailed'), 'error');
    }
  };

  const { getInputProps, open } = useDropzone({
    onDrop: handleVideoUpload,
    onFileDialogCancel: () => {
      onDeleteBlock();
    },
    noDrag: true,
    noClick: true,
    accept: {
      'video/*': [],
    },
  });

  useEffect(() => {
    const targetBlockId = sessionStorage.getItem('openFilePickerForBlock');
    if (targetBlockId === id) {
      open();
      sessionStorage.removeItem('openFilePickerForBlock');
    }
  }, [id, open]);

  useEffect(() => {
    const targetBlockId = sessionStorage.getItem('openRecordingModalForBlock');
    if (targetBlockId === id) {
      setRecordingModalOpen(true);
      sessionStorage.removeItem('openRecordingModalForBlock');
    }
  }, [id, open]);
  return (
    <Box position="relative">
      <RecordingModal
        open={recordingModalOpen}
        onModalClose={onDeleteBlock}
        onUploadFile={handleVideoUpload}
      />
      {fileSize && !videoId && (
        <UploadProgress
          progress={progress[id] || 0}
          size={fileSize}
          onClose={() => {
            cancelUpload(id);
            clearProgress(id);
            removeFile(id);
            onDeleteBlock();
          }}
        />
      )}
      {!videoId && fileSize === null && (
        <Skeleton sx={{ aspectRatio: 16 / 9, transform: 'none' }} />
      )}
      {videoId && (
        <VidstackPlayer
          disabledFastForward={disabledFastForward}
          poster={posterSrc || ''}
          videoId={videoId}
        />
      )}
      <input {...getInputProps()} />
    </Box>
  );
}
