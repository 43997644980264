// @ts-nocheck
/* eslint-disable */
/* This file was generated by Unframer for Framer project 71fd72204da79e7e "Workbase App", do not edit manually */
import { className, css, fonts, } from './chunk-I43UZBKK.js';
import { __export, } from './chunk-WSFCRVEQ.js';

// /:https://framerusercontent.com/modules/sxBT743DxKtqC2ggapcO/loFON7KcpVrUKkGtJy3M/n4oWo9hts.js
var n4oWo9hts_exports = {};
__export(n4oWo9hts_exports, {
  __FramerMetadata__: () => __FramerMetadata__2,
  default: () => stdin_default,
},);
import { jsx as _jsx2, jsxs as _jsxs, } from 'react/jsx-runtime';
import {
  addFonts,
  addPropertyControls,
  ControlType,
  cx,
  getFontsFromSharedStyle,
  RichText,
  useActiveVariantCallback,
  useComponentViewport,
  useLocaleInfo,
  useVariantState,
  withCSS,
  withFX,
} from 'unframer';
import { LayoutGroup, motion as motion2, MotionConfigContext, } from 'unframer';
import * as React2 from 'react';

// /:https://framerusercontent.com/modules/EEAEhCiPkvVOhBJWGgxo/Hm5cyeJkSKnkPnNurkqk/n4oWo9hts-0.js
var n4oWo9hts_0_exports = {};
__export(n4oWo9hts_0_exports, {
  __FramerMetadata__: () => __FramerMetadata__,
  v0: () => v0,
  v1: () => v1,
},);
import { jsx as _jsx, } from 'react/jsx-runtime';
import { motion, } from 'unframer';
import * as React from 'react';
var v0 = /* @__PURE__ */ _jsx(React.Fragment, {
  children: /* @__PURE__ */ _jsx(motion.p, {
    style: {
      '--font-selector': 'Q1VTVE9NO0Jhc2UgTWVkaXVt',
      '--framer-font-family': '"Base Medium", "Base Medium Placeholder", sans-serif',
      '--framer-font-size': '14px',
      '--framer-text-color': 'var(--extracted-r6o4lv, rgb(255, 255, 255))',
    },
    children: 'Danke',
  },),
},);
var v1 = /* @__PURE__ */ _jsx(React.Fragment, {
  children: /* @__PURE__ */ _jsx(motion.p, {
    style: {
      '--font-selector': 'Q1VTVE9NO0Jhc2UgTWVkaXVt',
      '--framer-font-family': '"Base Medium", "Base Medium Placeholder", sans-serif',
      '--framer-font-size': '14px',
      '--framer-text-color': 'var(--extracted-r6o4lv, rgb(255, 34, 68))',
    },
    children: 'Etwas ist schiefgelaufen',
  },),
},);
var __FramerMetadata__ = {
  'exports': {
    'v1': { 'type': 'variable', 'annotations': { 'framerContractVersion': '1', }, },
    'v0': { 'type': 'variable', 'annotations': { 'framerContractVersion': '1', }, },
    '__FramerMetadata__': { 'type': 'variable', },
  },
};

// /:https://framerusercontent.com/modules/EEAEhCiPkvVOhBJWGgxo/Hm5cyeJkSKnkPnNurkqk/n4oWo9hts.js
var valuesByLocaleId = { oGVL1hca4: n4oWo9hts_0_exports, };
function getLocalizedValue(key, locale,) {
  while (locale) {
    const values = valuesByLocaleId[locale.id];
    if (values) {
      const value = values[key];
      if (value) return value;
    }
    locale = locale.fallback;
  }
}

// /:https://framerusercontent.com/modules/sxBT743DxKtqC2ggapcO/loFON7KcpVrUKkGtJy3M/n4oWo9hts.js
var MotionDivWithFX = withFX(motion2.div,);
var enabledGestures = { SILD3stxQ: { hover: true, pressed: true, }, };
var cycleOrder = ['SILD3stxQ', 'nlcQPadhL', 'f0aybHD4i', 'i3mUK91BK', 'OQwL96ij3',];
var serializationHash = 'framer-H3gjT';
var variantClassNames = {
  f0aybHD4i: 'framer-v-43vcx2',
  i3mUK91BK: 'framer-v-1hb4dzq',
  nlcQPadhL: 'framer-v-ii06ag',
  OQwL96ij3: 'framer-v-1pbbgx0',
  SILD3stxQ: 'framer-v-1g0x00',
};
function addPropertyOverrides(overrides, ...variants) {
  const nextOverrides = {};
  variants === null || variants === void 0
    ? void 0
    : variants.forEach((variant,) => variant && Object.assign(nextOverrides, overrides[variant],));
  return nextOverrides;
}
var transition1 = { delay: 0, duration: 0.2, ease: [0.44, 0, 0.56, 1,], type: 'tween', };
var transition2 = { delay: 0, duration: 1, ease: [0, 0, 1, 1,], type: 'tween', };
var animation = { opacity: 1, rotate: 360, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0, };
var transformTemplate1 = (_, t,) => `translateX(-50%) ${t}`;
var Transition = ({ value, children, },) => {
  const config = React2.useContext(MotionConfigContext,);
  const transition = value !== null && value !== void 0 ? value : config.transition;
  const contextValue = React2.useMemo(() => ({ ...config, transition, }), [JSON.stringify(transition,),],);
  return /* @__PURE__ */ _jsx2(MotionConfigContext.Provider, { value: contextValue, children, },);
};
var Variants = motion2.create(React2.Fragment,);
var humanReadableVariantMap = {
  Default: 'SILD3stxQ',
  Disabled: 'f0aybHD4i',
  Error: 'OQwL96ij3',
  Loading: 'nlcQPadhL',
  Success: 'i3mUK91BK',
};
var getProps = ({ click, height, id, title, width, ...props },) => {
  var _ref, _humanReadableVariantMap_props_variant, _ref1;
  return {
    ...props,
    CTGyhEil8: click !== null && click !== void 0 ? click : props.CTGyhEil8,
    I5NiotwhT: (_ref = title !== null && title !== void 0 ? title : props.I5NiotwhT) !== null && _ref !== void 0 ? _ref : 'Continue',
    variant:
      (_ref1 =
            (_humanReadableVariantMap_props_variant = humanReadableVariantMap[props.variant]) !== null &&
              _humanReadableVariantMap_props_variant !== void 0
              ? _humanReadableVariantMap_props_variant
              : props.variant) !== null && _ref1 !== void 0
        ? _ref1
        : 'SILD3stxQ',
  };
};
var createLayoutDependency = (props, variants,) => {
  if (props.layoutDependency) return variants.join('-',) + props.layoutDependency;
  return variants.join('-',);
};
var Component = /* @__PURE__ */ React2.forwardRef(function (props, ref,) {
  const { activeLocale, setLocale, } = useLocaleInfo();
  const { style, className: className2, layoutId, variant, CTGyhEil8, I5NiotwhT, ...restProps } = getProps(props,);
  const {
    baseVariant,
    classNames,
    clearLoadingGesture,
    gestureHandlers,
    gestureVariant,
    isLoading,
    setGestureState,
    setVariant,
    variants,
  } = useVariantState({ cycleOrder, defaultVariant: 'SILD3stxQ', enabledGestures, variant, variantClassNames, },);
  const layoutDependency = createLayoutDependency(props, variants,);
  const { activeVariantCallback, delay, } = useActiveVariantCallback(baseVariant,);
  const onTaplleij4 = activeVariantCallback(async (...args) => {
    setGestureState({ isPressed: false, },);
    if (CTGyhEil8) {
      const res = await CTGyhEil8(...args,);
      if (res === false) return false;
    }
  },);
  const ref1 = React2.useRef(null,);
  const isDisplayed = () => {
    if (baseVariant === 'nlcQPadhL') return false;
    return true;
  };
  const isDisplayed1 = () => {
    if (baseVariant === 'nlcQPadhL') return true;
    return false;
  };
  const defaultLayoutId = React2.useId();
  const sharedStyleClassNames = [className,];
  const componentViewport = useComponentViewport();
  var _getLocalizedValue, _getLocalizedValue1;
  return /* @__PURE__ */ _jsx2(LayoutGroup, {
    id: layoutId !== null && layoutId !== void 0 ? layoutId : defaultLayoutId,
    children: /* @__PURE__ */ _jsx2(Variants, {
      animate: variants,
      initial: false,
      children: /* @__PURE__ */ _jsx2(Transition, {
        value: transition1,
        children: /* @__PURE__ */ _jsxs(motion2.button, {
          ...restProps,
          ...gestureHandlers,
          className: cx(serializationHash, ...sharedStyleClassNames, 'framer-1g0x00', className2, classNames,),
          'data-framer-name': 'Default',
          'data-highlight': true,
          'data-reset': 'button',
          layoutDependency,
          layoutId: 'SILD3stxQ',
          onTap: onTaplleij4,
          ref: ref !== null && ref !== void 0 ? ref : ref1,
          style: {
            backgroundColor: 'var(--token-c954bfde-6d74-4a5e-ab1c-228c5bed5c11, rgb(0, 119, 237))',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            opacity: 1,
            ...style,
          },
          variants: {
            'SILD3stxQ-hover': { backgroundColor: 'var(--token-5f0ba7d2-9b13-4ef7-b9be-d254e5e8f888, rgb(31, 143, 255))', },
            'SILD3stxQ-pressed': { backgroundColor: 'var(--token-39b5ea5d-db88-47fc-8e8c-b9a92ce11555, rgb(12, 65, 107))', },
            f0aybHD4i: { opacity: 0.5, },
            OQwL96ij3: { backgroundColor: 'rgba(255, 34, 68, 0.15)', },
          },
          ...addPropertyOverrides(
            {
              'SILD3stxQ-hover': { 'data-framer-name': void 0, },
              'SILD3stxQ-pressed': { 'data-framer-name': void 0, },
              f0aybHD4i: { 'data-framer-name': 'Disabled', },
              i3mUK91BK: { 'data-framer-name': 'Success', },
              nlcQPadhL: { 'data-framer-name': 'Loading', },
              OQwL96ij3: { 'data-framer-name': 'Error', },
            },
            baseVariant,
            gestureVariant,
          ),
          children: [
            isDisplayed() && /* @__PURE__ */ _jsx2(RichText, {
              __fromCanvasComponent: true,
              children: /* @__PURE__ */ _jsx2(React2.Fragment, {
                children: /* @__PURE__ */ _jsx2(motion2.p, {
                  className: 'framer-styles-preset-1scqhtn',
                  'data-styles-preset': 'f0HK30xKZ',
                  style: {
                    '--framer-text-color': 'var(--extracted-r6o4lv, var(--token-cd8b457a-cb7f-466b-9c65-6c2e9533e54f, rgb(255, 255, 255)))',
                  },
                  children: 'Continue',
                },),
              },),
              className: 'framer-pfrenx',
              fonts: ['Inter',],
              layoutDependency,
              layoutId: 'TfS5Q10mp',
              style: {
                '--extracted-r6o4lv': 'var(--token-cd8b457a-cb7f-466b-9c65-6c2e9533e54f, rgb(255, 255, 255))',
                '--framer-link-text-color': 'rgb(0, 153, 255)',
                '--framer-link-text-decoration': 'underline',
              },
              text: I5NiotwhT,
              variants: {
                i3mUK91BK: { '--extracted-r6o4lv': 'rgb(255, 255, 255)', },
                OQwL96ij3: { '--extracted-r6o4lv': 'rgb(255, 34, 68)', },
              },
              verticalAlignment: 'top',
              withExternalLayout: true,
              ...addPropertyOverrides(
                {
                  i3mUK91BK: {
                    children: (_getLocalizedValue = getLocalizedValue('v0', activeLocale,)) !== null && _getLocalizedValue !== void 0
                      ? _getLocalizedValue
                      : /* @__PURE__ */ _jsx2(React2.Fragment, {
                        children: /* @__PURE__ */ _jsx2(motion2.p, {
                          style: {
                            '--font-selector': 'Q1VTVE9NO0Jhc2UgTWVkaXVt',
                            '--framer-font-family': '"Base Medium", "Base Medium Placeholder", sans-serif',
                            '--framer-font-size': '14px',
                            '--framer-text-color': 'var(--extracted-r6o4lv, rgb(255, 255, 255))',
                          },
                          children: 'Thank you',
                        },),
                      },),
                    fonts: ['CUSTOM;Base Medium',],
                    text: void 0,
                  },
                  OQwL96ij3: {
                    children: (_getLocalizedValue1 = getLocalizedValue('v1', activeLocale,)) !== null && _getLocalizedValue1 !== void 0
                      ? _getLocalizedValue1
                      : /* @__PURE__ */ _jsx2(React2.Fragment, {
                        children: /* @__PURE__ */ _jsx2(motion2.p, {
                          style: {
                            '--font-selector': 'Q1VTVE9NO0Jhc2UgTWVkaXVt',
                            '--framer-font-family': '"Base Medium", "Base Medium Placeholder", sans-serif',
                            '--framer-font-size': '14px',
                            '--framer-text-color': 'var(--extracted-r6o4lv, rgb(255, 34, 68))',
                          },
                          children: 'Something went wrong',
                        },),
                      },),
                    fonts: ['CUSTOM;Base Medium',],
                    text: void 0,
                  },
                },
                baseVariant,
                gestureVariant,
              ),
            },),
            isDisplayed1() && /* @__PURE__ */ _jsx2(motion2.div, {
              className: 'framer-1yesuiy',
              'data-framer-name': 'Spinner',
              layoutDependency,
              layoutId: 'XhGbGDuh6',
              style: {
                mask: 'url(\'https://framerusercontent.com/images/pGiXYozQ3mE4cilNOItfe2L2fUA.svg\') alpha no-repeat center / cover add',
                WebkitMask:
                  'url(\'https://framerusercontent.com/images/pGiXYozQ3mE4cilNOItfe2L2fUA.svg\') alpha no-repeat center / cover add',
              },
              children: /* @__PURE__ */ _jsx2(MotionDivWithFX, {
                __framer__loop: animation,
                __framer__loopEffectEnabled: true,
                __framer__loopRepeatDelay: 0,
                __framer__loopRepeatType: 'loop',
                __framer__loopTransition: transition2,
                __perspectiveFX: false,
                __smartComponentFX: true,
                __targetOpacity: 1,
                className: 'framer-visu00',
                'data-framer-name': 'Conic',
                layoutDependency,
                layoutId: 'l5wdZidZn',
                style: {
                  background: 'conic-gradient(from 180deg at 50% 50%, #4cf 0deg, #4cf 360deg)',
                  backgroundColor: 'rgb(68, 204, 255)',
                  mask: 'none',
                  WebkitMask: 'none',
                },
                variants: {
                  nlcQPadhL: {
                    background:
                      'conic-gradient(from 0deg at 50% 50%, rgba(255, 255, 255, 0) 7.208614864864882deg, rgb(255, 255, 255) 342deg)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    mask:
                      'url(\'https://framerusercontent.com/images/pGiXYozQ3mE4cilNOItfe2L2fUA.svg\') alpha no-repeat center / cover add',
                    WebkitMask:
                      'url(\'https://framerusercontent.com/images/pGiXYozQ3mE4cilNOItfe2L2fUA.svg\') alpha no-repeat center / cover add',
                  },
                },
                children: /* @__PURE__ */ _jsx2(motion2.div, {
                  className: 'framer-k5hnpk',
                  'data-framer-name': 'Rounding',
                  layoutDependency,
                  layoutId: 'mczNSHCsG',
                  style: {
                    backgroundColor: 'rgb(255, 255, 255)',
                    borderBottomLeftRadius: 1,
                    borderBottomRightRadius: 1,
                    borderTopLeftRadius: 1,
                    borderTopRightRadius: 1,
                  },
                  transformTemplate: transformTemplate1,
                },),
              },),
            },),
          ],
        },),
      },),
    },),
  },);
},);
var css2 = [
  '@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }',
  '.framer-H3gjT.framer-1lixew0, .framer-H3gjT .framer-1lixew0 { display: block; }',
  '.framer-H3gjT.framer-1g0x00 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: center; overflow: visible; padding: 12px 18px 12px 18px; position: relative; width: min-content; }',
  '.framer-H3gjT .framer-pfrenx { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }',
  '.framer-H3gjT .framer-1yesuiy { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 20px); overflow: hidden; position: relative; width: 20px; }',
  '.framer-H3gjT .framer-visu00 { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }',
  '.framer-H3gjT .framer-k5hnpk { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 2px); left: 50%; overflow: visible; position: absolute; top: 0px; width: 2px; }',
  '@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-H3gjT.framer-1g0x00 { gap: 0px; } .framer-H3gjT.framer-1g0x00 > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-H3gjT.framer-1g0x00 > :first-child { margin-left: 0px; } .framer-H3gjT.framer-1g0x00 > :last-child { margin-right: 0px; } }',
  '.framer-H3gjT.framer-v-ii06ag .framer-visu00 { overflow: hidden; }',
  ...css,
];
var Framern4oWo9hts = withCSS(Component, css2, 'framer-H3gjT',);
var stdin_default = Framern4oWo9hts;
Framern4oWo9hts.displayName = 'Form';
Framern4oWo9hts.defaultProps = { height: 48, width: 118, };
addPropertyControls(Framern4oWo9hts, {
  variant: {
    options: ['SILD3stxQ', 'nlcQPadhL', 'f0aybHD4i', 'i3mUK91BK', 'OQwL96ij3',],
    optionTitles: ['Default', 'Loading', 'Disabled', 'Success', 'Error',],
    title: 'Variant',
    type: ControlType.Enum,
  },
  CTGyhEil8: { title: 'Click', type: ControlType.EventHandler, },
  I5NiotwhT: { defaultValue: 'Continue', displayTextArea: false, title: 'Title', type: ControlType.String, },
},);
addFonts(Framern4oWo9hts, [{
  explicitInter: true,
  fonts: [{
    family: 'Inter',
    source: 'framer',
    style: 'normal',
    unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
    url: 'https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2',
    weight: '400',
  }, {
    family: 'Inter',
    source: 'framer',
    style: 'normal',
    unicodeRange: 'U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
    url: 'https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2',
    weight: '400',
  }, {
    family: 'Inter',
    source: 'framer',
    style: 'normal',
    unicodeRange: 'U+1F00-1FFF',
    url: 'https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2',
    weight: '400',
  }, {
    family: 'Inter',
    source: 'framer',
    style: 'normal',
    unicodeRange: 'U+0370-03FF',
    url: 'https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2',
    weight: '400',
  }, {
    family: 'Inter',
    source: 'framer',
    style: 'normal',
    unicodeRange: 'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
    url: 'https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2',
    weight: '400',
  }, {
    family: 'Inter',
    source: 'framer',
    style: 'normal',
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
    url: 'https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2',
    weight: '400',
  }, {
    family: 'Inter',
    source: 'framer',
    style: 'normal',
    unicodeRange: 'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
    url: 'https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2',
    weight: '400',
  },],
}, ...getFontsFromSharedStyle(fonts,),], { supportsExplicitInterCodegen: true, },);
var __FramerMetadata__2 = {
  'exports': {
    'Props': { 'type': 'tsType', 'annotations': { 'framerContractVersion': '1', }, },
    'default': {
      'type': 'reactComponent',
      'name': 'Framern4oWo9hts',
      'slots': [],
      'annotations': {
        'framerIntrinsicHeight': '48',
        'framerImmutableVariables': 'true',
        'framerComponentViewportWidth': 'true',
        'framerVariables': '{"CTGyhEil8":"click","I5NiotwhT":"title"}',
        'framerDisplayContentsDiv': 'false',
        'framerContractVersion': '1',
        'framerIntrinsicWidth': '118',
        'framerCanvasComponentVariantDetails':
          '{"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"nlcQPadhL":{"layout":["auto","auto"]},"f0aybHD4i":{"layout":["auto","auto"]},"i3mUK91BK":{"layout":["auto","auto"]},"OQwL96ij3":{"layout":["auto","auto"]},"PSIbyySZY":{"layout":["auto","auto"]},"cECCBQEW6":{"layout":["auto","auto"]}}}',
      },
    },
    '__FramerMetadata__': { 'type': 'variable', },
  },
};

export { n4oWo9hts_exports, stdin_default, };
