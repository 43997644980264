import {
  CommunityCanvasTextFonts,
  DocModeExtension,
  EdgelessEditorBlockSpecs,
  FontConfigExtension,
  PageEditorBlockSpecs,
  RefNodeSlotsExtension,
} from '@blocksuite/blocks';
import { AffineEditorContainer } from '@blocksuite/presets';
import { Doc } from '@blocksuite/store';
import docModeService from './docModeService';
import { EmbedWorkbaseVideoBlockSpec } from './blocks/video/videoSpec';
import { WorkbaseQuickSearchExtension } from './quicksearch/quickSearchExtension';

export function initDefaultDocEditor(doc: Doc) {
  const editor = new AffineEditorContainer();

  const setEditorModeCallBack = editor.switchEditor.bind(editor);
  const getEditorModeCallback = () => editor.mode;

  const docModeExtension = DocModeExtension(
    docModeService(getEditorModeCallback, setEditorModeCallBack)
  );
  const refNodeSlotsExtension = RefNodeSlotsExtension();
  editor.pageSpecs = [
    ...PageEditorBlockSpecs,
    refNodeSlotsExtension,
    docModeExtension,
    WorkbaseQuickSearchExtension,
    FontConfigExtension(CommunityCanvasTextFonts),
    ...EmbedWorkbaseVideoBlockSpec,
  ];
  editor.edgelessSpecs = [
    ...EdgelessEditorBlockSpecs,
    refNodeSlotsExtension,
    docModeExtension,
    WorkbaseQuickSearchExtension,
    FontConfigExtension(CommunityCanvasTextFonts),
    ...EmbedWorkbaseVideoBlockSpec,
  ];

  editor.doc = doc;
  editor.mode = 'page';

  return editor;
}
