import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Button } from '@mui/material';
import {
  TrainingLessonStatus,
  useDeleteTrainingLessonMutation,
  useDuplicateTrainingLessonMutation,
  useTrainingLessonCanMarkAsDoneQuery,
  usePatchTrainingLessonMutation,
  useTrainingLessonQuery,
  useTrainingLessonsBySectionAndUserIdQuery,
  TrainingLesson,
} from 'src/store/api/trainings/trainingLessonsApi';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import { useTrainingSectionsByModuleIdQuery } from 'src/store/api/trainings/trainingSectionsApi';
import DraftModeMenu from 'src/core/DraftModeMenu';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import LessonFooter from './LessonFooter';
import FloatingActionWrapper from 'src/ui-components/floating-action-wrapper/FloatingActionWrapper';
import EditorVerifyE2EProvider from 'src/features/editor2/providers/EditorVerifyE2EProvider';
import Editor from 'src/features/editor2/Editor';

interface Props {
  hasEditAccess: boolean;
}

export default function Lesson({ hasEditAccess }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{
    trainingId: string;
    moduleId: string;
    lessonId: string;
    sectionId: string;
  }>();
  const { data, error, refetch } = useTrainingLessonQuery(params.lessonId as string);
  const [patchTrainingLesson] = usePatchTrainingLessonMutation();

  // const debouncedInvalidateTrainingEstimatedTime = useCallback(
  //   debounce(() => {
  //     if (params.sectionId && params.trainingId && params.lessonId) {
  //       dispatch(trainingLessonsApi.util.invalidateTags(['TrainingLesson']));
  //       dispatch(
  //         trainingLessonsApi.util.invalidateTags([
  //           { type: 'TrainingLessonsBySectionAndUserId', id: params.sectionId },
  //         ])
  //       );
  //       dispatch(
  //         trainingModulesApi.util.invalidateTags([
  //           'TrainingModule',
  //           'TrainingModulesByUserAndTrainingId',
  //         ])
  //       );
  //       dispatch(trainingsApi.util.invalidateTags(['Training', 'TrainingsByUserId']));
  //     }
  //   }, 10000),
  //   [dispatch, params.sectionId, params.trainingId, params.lessonId]
  // );

  const [duplicateTrainingLesson] = useDuplicateTrainingLessonMutation();
  const [deleteTrainingLesson] = useDeleteTrainingLessonMutation();
  const { data: canMarkAsDone } = useTrainingLessonCanMarkAsDoneQuery(
    {
      lessonId: params.lessonId as string,
      moduleId: params.moduleId as string,
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: sections } = useTrainingSectionsByModuleIdQuery(params.moduleId as string);
  const { data: lessons } = useTrainingLessonsBySectionAndUserIdQuery({
    sectionId: params.sectionId as string,
  });
  const isDraft =
    data?.status === 'draft' ||
    sections?.find(({ id }) => id === params.sectionId)?.status === 'draft';

  const showLessonFooter = data && !data?.done && !isDraft;

  if (error) {
    return (
      <Box sx={{ margin: 2 }}>
        <Alert
          severity="error"
          action={
            <Button onClick={refetch} size="small">
              {t('validationErrorMessages.labelErrorTryAgainButton')}
            </Button>
          }
        >
          {t('lessonPage.fetchLessonError')}
        </Alert>
      </Box>
    );
  }

  return (
    <Box position="relative" height="100%" display="flex" flexDirection="column">
      <FloatingActionWrapper>
        <DraftModeMenu
          dataCy="lesson"
          status={data?.status as TrainingLessonStatus}
          onStatusChange={(status) => {
            patchTrainingLesson({
              status,
              id: data?.id as string,
              trainingSectionId: data?.trainingSectionId as string,
              trainingId: params.trainingId as string,
              moduleId: params.moduleId as string,
            });
          }}
        />
        <DeleteDuplicateMenu
          dataCy="lesson"
          onDelete={() => {
            deleteTrainingLesson({
              id: params.lessonId as string,
              trainingSectionId: params.sectionId as string,
              trainingId: params.trainingId as string,
              moduleId: params.moduleId as string,
            }).then((resp: any) => {
              if (resp.error) {
                showNotification(t('notifications.deleteError'), 'error');
              } else {
                showNotification(t('notifications.deleteSuccess'));
                const currentLessonIndex: number = lessons?.findIndex(
                  (less) => less.id === params.lessonId
                ) as number;
                if (currentLessonIndex === -1) return;
                const updatedLessons = lessons?.filter(
                  (less) => less.id !== params.lessonId
                ) as TrainingLesson[];
                let redirectLessonId = null;

                if (currentLessonIndex > 0) {
                  redirectLessonId = updatedLessons[currentLessonIndex - 1]?.id;
                }
                if (!redirectLessonId && currentLessonIndex < updatedLessons.length) {
                  redirectLessonId = updatedLessons[currentLessonIndex]?.id;
                }
                if (redirectLessonId) {
                  navigate(
                    `/trainings/${params.trainingId}/${params.moduleId}/${params.sectionId}/${redirectLessonId}/edit`
                  );
                } else {
                  navigate(`/trainings/${params.trainingId}/${params.moduleId}/edit`);
                }
              }
            });
          }}
          onDuplicate={() => {
            duplicateTrainingLesson({
              lessonId: params.lessonId as string,
              trainingId: params.trainingId as string,
              moduleId: params.moduleId as string,
              trainingSectionId: params.sectionId as string,
            }).then((resp: any) => {
              if (resp.error) {
                showNotification(t('notifications.duplicateError'), 'error');
              } else {
                showNotification(t('notifications.duplicateSuccess'));
                navigate(
                  `/trainings/${params.trainingId}/${params.moduleId}/${params.sectionId}/${resp.data.id}/edit`
                );
              }
            });
          }}
          deleteDialogDescription={t('deleteLessonModal.content')}
        />
      </FloatingActionWrapper>
      <EditorVerifyE2EProvider name={`trainingLesson.${params.lessonId}`} readOnly={!hasEditAccess}>
        <Editor />
      </EditorVerifyE2EProvider>
      {showLessonFooter && data && (
        <LessonFooter
          lessonId={data.id}
          nextPublishedLesson={data.nextPublishedLesson}
          disabledFinishButton={!canMarkAsDone?.result}
        />
      )}
    </Box>
  );
}
