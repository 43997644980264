import { Dialog, DialogContent } from '@mui/material';
import ScreenCameraRecorder from 'src/features/screen-camera-recorder';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';

interface Props {
  onUploadFile: (files: File[]) => void;
  onModalClose: () => void;
  open: boolean;
}

export default function RecordingModal({ onUploadFile, onModalClose, open }: Props) {
  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(false);
  const confirm = useConfirm();

  const onRecordingFinish = useCallback(
    (blob: Blob) => {
      const f = new File([blob], 'recording.mp4', { type: 'video/mp4' });
      onUploadFile([f]);
      setIsRecording(false);
    },
    [onUploadFile]
  );

  const onStartRecording = useCallback(() => {
    setIsRecording(true);
  }, []);

  const handleCloseModal = () => {
    if (isRecording) {
      confirm({
        title: t('areYouSure'),
        description: t('editor.uploadMediaModal.removeVideo'),
      }).then(() => {
        setIsRecording(false);
        onModalClose();
      });
    } else {
      onModalClose();
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={handleCloseModal}
      data-cy="recording-modal-dialog"
    >
      <DialogContent>
        <ScreenCameraRecorder
          onRecordingFinish={onRecordingFinish}
          onRecordingStarted={onStartRecording}
        />
      </DialogContent>
    </Dialog>
  );
}
