import { useTranslation } from 'react-i18next';
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import { WorkbaseAddCircleIcon } from 'src/assets/icons/workbaseIcons';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import NewEmployeeDialog from 'src/features/company-users/view/new-employee/NewEmployeeDialog';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import useMenuData from './useMenuData';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';
const addButtonDataCy = 'sidebarAddButton';

export default function AddButton() {
  const { t } = useTranslation();
  const newEmployeeDialogShow = useBoolean();
  const hasAnyPermission = useHasAnyPermission();
  const menuData = useMenuData({ onAddNewEmployeeClick: newEmployeeDialogShow.onTrue });

  const popupState = usePopupState({ variant: 'popover' });
  if (
    !hasAnyPermission([
      PermissionCodes.MANAGE_EMPLOYEES,
      PermissionCodes.ADD_PAGES,
      PermissionCodes.ADD_EMBEDDED_APPS,
      PermissionCodes.ADD_TRAININGS,
      PermissionCodes.ADD_WIKIS,
    ])
  )
    return null;
  const menuProps = bindPopover(popupState);
  return (
    <Box mt="auto" width="100%" px={0.25}>
      {hasAnyPermission([PermissionCodes.MANAGE_EMPLOYEES]) && (
        <NewEmployeeDialog
          open={newEmployeeDialogShow.value}
          onClose={newEmployeeDialogShow.onFalse}
        />
      )}
      <ListItemButton
        selected={menuProps.open}
        data-cy={addButtonDataCy}
        {...bindTrigger(popupState)}
        dense
      >
        <ListItemIcon>
          <SvgIcon fontSize="small" color="primary">
            <WorkbaseAddCircleIcon />
          </SvgIcon>
        </ListItemIcon>

        <ListItemText
          primary={t('sidebar.tabTitleAddButton')}
          primaryTypographyProps={{ noWrap: true, fontWeight: 'medium' }}
        />
      </ListItemButton>
      <Menu
        {...menuProps}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {menuData.map(({ icon, label, onClick, key }) => (
          <MenuItem
            key={key}
            data-cy={`${addButtonDataCy}.${key}`}
            onClick={() => {
              onClick();
              menuProps.onClose();
            }}
          >
            <ListItemIcon>
              <SvgIcon component={icon} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
