import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';
import {
  WorkbaseMotivationIcon,
  WorkbaseEmployeeCardsIcon,
  WorkbaseSettingsIcon,
  WorkbaseCustomerCardsIcon,
} from 'src/assets/icons/workbaseIcons';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import useIsCustomerPortal from 'src/hooks/useIsCustomerPortal';
import { useAppSelector } from 'src/store';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import {
  RecommendationNotificationAdminPreferences,
  useSettingsQuery,
} from 'src/store/api/settingsApi';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';

function useActionButtonsData(onRecommendationIconClick: () => void) {
  const { t } = useTranslation();
  const location = useLocation();
  const { data: currentUserData } = useCurrentUserQuery();
  const [, setSearchParams] = useSearchParams();
  const isCustomerPortal = useIsCustomerPortal();
  const { data: settingsData } = useSettingsQuery(isCustomerPortal, {
    refetchOnMountOrArgChange: true,
  });
  const userType = useAppSelector((state) => state.auth.userType);
  const previewMode = useAppSelector((state) => state.userPortal.previewMode);
  const hasAnyPermission = useHasAnyPermission();
  const defaultIconsData = [];

  if (
    (
      settingsData?.recommendationNotificationPreferences as RecommendationNotificationAdminPreferences
    )?.isEmailEnabled ||
    (
      settingsData?.recommendationNotificationPreferences as RecommendationNotificationAdminPreferences
    )?.isWebhookEnabled
  ) {
    defaultIconsData.push({
      key: 'recommendation',
      icon: { icon: WorkbaseMotivationIcon },
      onClick: onRecommendationIconClick,
      isActive: false,
      tooltip: t(
        !isCustomerPortal
          ? 'header.employeeRecommendationIconTooltip'
          : 'header.customerRecommendationIconTooltip'
      ),
    });
  }

  if (
    isCustomerPortal
      ? userType === 'employee' &&
        !previewMode &&
        hasAnyPermission([PermissionCodes.MANAGE_CUSTOMERS])
      : currentUserData?.isOwner || hasAnyPermission([PermissionCodes.VIEW_EMPLOYEES])
  ) {
    defaultIconsData.push({
      key: !isCustomerPortal ? 'employee' : 'customer',
      icon: { icon: isCustomerPortal ? WorkbaseCustomerCardsIcon : WorkbaseEmployeeCardsIcon },
      onClick: () => setSearchParams({ 'company-user': '1' }),
      isActive: !!matchPath({ path: 'team', end: false }, location.pathname),
      tooltip: t(!isCustomerPortal ? 'header.employeeIconTooltip' : 'header.customersIconTooltip'),
    });
  }

  const settingsVisible = !isCustomerPortal
    ? hasAnyPermission([
        PermissionCodes.MANAGE_DEPARTMENTS,
        PermissionCodes.MANAGE_ALL_COMPANY_SETTINGS,
        PermissionCodes.MANAGE_BILLING,
      ])
    : userType === 'employee' && !previewMode;

  if (settingsVisible) {
    defaultIconsData.push({
      key: 'settings',
      icon: { icon: WorkbaseSettingsIcon },
      onClick: () =>
        setSearchParams({
          settings:
            currentUserData?.isOwner ||
            hasAnyPermission([
              PermissionCodes.MANAGE_DEPARTMENTS,
              PermissionCodes.MANAGE_ALL_COMPANY_SETTINGS,
            ])
              ? 'site-information'
              : 'employee-recommendations',
        }),
      isActive: !!matchPath({ path: 'settings', end: false }, location.pathname),
      tooltip: t('header.settingsIconTooltip'),
    });
  }

  return defaultIconsData;
}

export default useActionButtonsData;
